import FileSaver from 'file-saver';
import * as Tesseract from 'tesseract.js';


export const fileHelpers = {
  b64toBlob: (b64Data, contentType, sliceSize) => {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    let byteCharacters = atob(b64Data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: contentType});
  },
  hasExtensions: (filename, extensions) => {
    return (new RegExp('(' + extensions.join('|').replace(/\./g, '\\.') + ')$')).test(filename);
  },
  cutFileData: (img) => {
    let cutFile = null;
    if (img !== null) {
      let index = img.lastIndexOf(',');
      cutFile = index > 0 ? img.substring(index + 1) : img;
    }
    return cutFile;
  },
  getBase64: (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  }),
  getSupportedFormatsList: (formatsList) => {
    let formats = null;
    let pattern = /\.([0-9a-z]+)(?:[?#]|$)/i;
    if (formatsList) {
      formats = formatsList.split(',')
        .map(a => a.match(pattern))
        .map(f => f ? f.length === 2 ? f[1] : null : null)
        .filter(f => f)
        .map(f => f.toUpperCase())
        .join(', ');
    }
    return formats;
  },
  recognizeImageToText: (image, successCallback, errorCallback) => {
    Tesseract.recognize(image, "eng")
      .then((result) => successCallback && successCallback(result))
      .catch((err) => errorCallback && errorCallback(err));
  },
  saveFile: (data) => {
    const blob = fileHelpers.b64toBlob(data.file, data['content-type']);
    FileSaver.saveAs(blob, data.filename);
  },
}
