import styled from 'styled-components';
import {Row} from 'antd';
import Empty from '../../../Empty';
import {bordersStyles} from '../../../../utils/styles';

const StyledSpendingDetails = styled(Row)`
  width: 100%;
  justify-content: space-between;
  
  & > .ant-col {
    width: calc(50% - 12px);
    
    .ant-card-body {
      justify-content: center;
      padding: 24px;
      min-height: 245px;
    }
  }
  
  .ant-card-body {
    ${bordersStyles.default};
    background-color: ${props => props.theme.colors.neutral_1};
    padding: 8px 24px;
    align-items: center;
    display: flex;
  }
  
  .overview-pie-chart {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    
    .recharts-responsive-container {
      min-width: 168px !important;
    }
    
    .pie-chart-bar-legend {
      color: ${props => props.theme.colors.neutral_7};
      font-size: 12px;
      padding-left: 16px;
      padding-top: 0;
      width: calc(100% - 168px - 24px);
      
      .price {
        color: ${props => props.theme.colors.neutral_10};
        font-weight: 600;
      }
    }
    
    .pie-chart-total-value {
      color: ${props => props.theme.colors.neutral_11};
      font-size: 20px;
      font-weight: 700;
    }
    
    @media (max-width: 1400px) {
      justify-content: center;
      
      .pie-chart-bar-legend {
        width: 100%;
        padding-top: 20px;
      }
    }
  }
  
  .ant-spin-nested-loading,
  .ant-spin-container,
  .ant-card {
    height: 100%;
  }
  .ant-card-body {
    height: calc(100% - 56px);
  }
`;

const StyledSpendingDetailsEmpty = styled(Empty)`
  margin-top: 0;
  
  .ant-empty-image {
    height: unset;
  }
  
  h3 {
    font-size: 16px;
  }
  
  p {
    font-size: 11px;
    line-height: 19px;
    max-width: 290px;
    margin: auto;
  }
  
  .ant-empty-description {
    & > div {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
`;


export {
  StyledSpendingDetails,
  StyledSpendingDetailsEmpty
}