import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Avatar, Form, Input, Space} from 'antd';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {StyledSubscriptionInfoForm} from './StyledSubscriptionInfoForm';
import SubscriptionSelect from '../../SubscriptionsPage/SubscriptionSelect';
import {
  formHelpers,
  locationHelpers,
  objectHelpers,
  subscriptionsHelpers,
  textHelpers
} from '../../../../utils/helpers';
import {serviceStatsActions} from '../../../../state/actions';

const initialFormValues = {
  friendly_name: '',
  service: ''
}

const minServiceSearchSymbolsLength = 3;

const {Item} = Form;

const SubscriptionInfoForm = React.forwardRef(({
  getSuggestServices,
  onSubmit,
  subscription,
  ...rest
}, ref) => {
  const [form] = Form.useForm();
  const [t] = useTranslation('main');
  const [services, setServices] = useState({data: [], loading: false});

  const service = Form.useWatch('service', form);

  useEffect(() => {
    const serviceUrl = subscription?.service?.url;
    const serviceName = subscription?.service?.name;
    const service = serviceUrl || locationHelpers.isValidUrl(serviceName) ? serviceName : '';
    form.setFieldsValue({
      friendly_name: subscription?.friendly_name,
      service
    });
  }, [form, subscription]);

  const gServiceProps = (key, defaultValue = '') => subscription && subscription.service ? objectHelpers.getObjProp(subscription.service, key, defaultValue) : defaultValue;

  const vendorProps = useMemo(() => subscriptionsHelpers.getSubscriptionVendorProps({isLoadingServices: services.loading, t}), [services, t]);


  const logo = gServiceProps('logo');
  const name = subscription?.friendly_name || gServiceProps('name');
  const url = gServiceProps('url');

  const handleSubmit = (fields) => {
    onSubmit && onSubmit(
      objectHelpers.trimAllValues(fields),
      null,
      (err) => {
        const fields = formHelpers.getFormServerErrorFields(err);
        form.setFields(fields);
      }
    );
  }

  const handleOnSearchService = (value) => subscriptionsHelpers.handleSearchSubscriptionService({onSearch: getSuggestServices, services, setServices, value})

  const handleOnChangeService = (value, option) => {
    if (option) {
      const optionName = option?.name;
      form.setFieldValue('friendly_name', optionName);
    }
  }

  return (
    <StyledSubscriptionInfoForm
      {...rest}
      initialValues={initialFormValues}
      layout='vertical'
      form={form}
      onFinish={handleSubmit}
      requiredMark={false}
    >
      <Space
        align='center'
        size='middle'
      >
        <Avatar
          style={{fontSize: 28}}
          size={86}
          src={logo}
        >
          {textHelpers.getInitials(name || url)}
        </Avatar>
        <Item
          label={t('name')}
          name='friendly_name'
        >
          <Input
            maxLength={150}
            size='large'
          />
        </Item>
        <Item
          label='URL'
          name='service'
        >
          <SubscriptionSelect
            autocompleteProps={vendorProps.autocomplete}
            enableCustomServiceDetails={false}
            inputProps={vendorProps.input}
            minSearchSymbolsLength={minServiceSearchSymbolsLength}
            onChange={handleOnChangeService}
            onSearch={handleOnSearchService}
            optionPropName='domain'
            services={services.data}
            value={service}
          />
        </Item>
      </Space>
      <input
        className='d-none'
        onClick={() => form.submit()}
        ref={ref}
        type='submit'
      />
    </StyledSubscriptionInfoForm>
  );
});

SubscriptionInfoForm.propTypes = {
  onSubmit: PropTypes.func,
  subscription: PropTypes.shape({
    service: PropTypes.shape({
      category: PropTypes.string,
      logo: PropTypes.string,
      name: PropTypes.string
    }),
    status: PropTypes.number
  }),
}

const mapDispatchToProps = {
  getSuggestServices: serviceStatsActions.getSuggestServices,
}

export default connect(null, mapDispatchToProps, null, {forwardRef: true})(SubscriptionInfoForm);
