import React from 'react';
import PropTypes from 'prop-types';
import {Space} from 'antd';
import {
  StyledMenuSteps,
  StyledMenuStepsItem,
  StyledMenuStepsItemSpace,
  StyledMenuStepsItemDetailsSpace,
  StyledMenuStepsItemIcon,
  StyledMenuStepsItemLine
} from './StyledMenuSteps';
import {
  CheckmarkCheckedCircleIcon,
  CheckmarkCircleFilledIcon,
  CheckmarkCircleIcon,
  CheckmarkClockCircleIcon,
  CheckmarkCloseCircleIcon
} from '../../../../../icons';
import {elementHelpers} from '../../../../../utils/helpers';

const MenuSteps = ({
  activeNum,
  steps,
  ...rest
}) => {

  const getStepDetails = (stepNum, stepDetails) => {
    let error = stepDetails.error || false;
    let pending = stepDetails.pending || false;
    let className = 'inactive';
    const isActiveStep = stepNum === activeNum;
    let icon = <CheckmarkCircleIcon />;
    if (stepNum < activeNum) {
      className = 'finished';
      icon = <CheckmarkCheckedCircleIcon />;
    } else if (isActiveStep) {
      className = 'active';
    }
    if (error) {
      className = `${className} error`;
      icon = isActiveStep ? <CheckmarkCloseCircleIcon /> : <CheckmarkCircleFilledIcon />;
    }
    if (pending) {
      className = `${className} pending`;
      icon = isActiveStep ? <CheckmarkClockCircleIcon /> : <CheckmarkCircleIcon />;
    }
    return {className, icon};
  }

  return (
    <StyledMenuSteps {...rest}>
      {steps.map(({description, title, message, ...rest}, key) => {
        const {className, icon} = getStepDetails(key + 1, rest);
        const cName = elementHelpers.getClassName(className, rest.className);
        return (
          <StyledMenuStepsItem
            className={cName}
            key={key}
            {...rest}
          >
            <StyledMenuStepsItemSpace
              align='start'
              size='large'
            >
              <Space
                size={0}
                direction='vertical'
              >
                <StyledMenuStepsItemIcon>
                  {icon}
                </StyledMenuStepsItemIcon>
                <StyledMenuStepsItemLine />
              </Space>
              <StyledMenuStepsItemDetailsSpace direction='vertical'>
                {title && (
                  <span className='title'>
                    {title}
                  </span>
                )}
                {description && (
                  <div className='description'>
                    {description}
                  </div>
                )}
              </StyledMenuStepsItemDetailsSpace>
            </StyledMenuStepsItemSpace>
          </StyledMenuStepsItem>
        )
      })}
    </StyledMenuSteps>
  );
}

MenuSteps.propTypes = {
  activeNum: PropTypes.number,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.any,
      error: PropTypes.bool,
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      pending: PropTypes.bool,
      title: PropTypes.any
    })
  )
}

MenuSteps.defaultProps = {
  activeNum: 1,
  steps: []
}

export default MenuSteps;
