import styled from 'styled-components';
import {Space} from 'antd';
import {bordersStyles} from '../../../../../utils/styles';

const StyledSelectedServiceList = styled.form`
  display: flex;
  flex-direction: column;
`;

const StyledSelectedServiceListTable = styled.div`
  ${bordersStyles.light};
  display: flex;
  flex-direction: column;

  & > div {
    &:not(:last-of-type) {
      border-bottom: 1px solid ${props => props.theme.colors.neutral_3};
    }
  }
`;

const StyledSelectedServiceListColumn = styled.div`
  & > div {
    padding: 8px;
  }
`;

const StyledSelectedServiceListHeaderRow = styled.div`
  display: flex;
  align-content: center;
  color: ${props => props.theme.colors.neutral_7};
  background-color: ${props => props.theme.colors.neutral_2};
  font-weight: 500;

  & > div {
    padding: 16px;
    position: relative;

    &:nth-of-type(1) {
      width: 25%;
    }

    &:nth-of-type(2) {
      width: 30%;
    }

    &:nth-of-type(3),&:nth-of-type(4) {
      width: calc((100% - 50% - 48px) / 2);
    }

    &:nth-of-type(4) {
      width: calc((100% - 50% - 48px) / 2 + 48px);
    }

    &:not(:last-of-type) {
      &:before {
        display: inline-block;
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        height: 22px;
        width: 1px;
        background-color: ${props => props.theme.colors.neutral_3};
        transform: translateY(-50%);
      }
    }
  }
`;

const StyledSelectedServiceListInfoSpace = styled(Space)`
  color: ${props => props.theme.colors.neutral_7};
  font-size: 16px;
`;

export {
  StyledSelectedServiceListTable,
  StyledSelectedServiceListColumn,
  StyledSelectedServiceListHeaderRow,
  StyledSelectedServiceList,
  StyledSelectedServiceListInfoSpace
}
