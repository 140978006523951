import {request} from '../services/request';
import {backendEndpoints} from '../../api';
import {apiHelpers} from '../../utils/helpers';
import {cardConstants} from '../constants';

const errorHandlerProps = {disableAlert: true};
const hideKeylessErrorHandlerProps = {hideKeylessError: true};

const {getUrl} = apiHelpers;

const getCards = (query = null, success, error) => {
  return (dispatch) => {
    request.get({
      dispatch,
      url: backendEndpoints.GET_CARDS,
      query,
      successCallback: (data) => success && success(data),
      errorCallback: (data) => error && error(data)
    });
  }
}

const getCard = (id, success, error) => {
  return (dispatch) => {
    request.get({
      dispatch,
      url: getUrl(backendEndpoints.GET_CARD, {id}),
      successCallback: (data) => success && success(data),
      errorCallback: (data) => error && error(data),
      errorHandlerProps
    });
  }
}


const createCard = ({headers, data, successCallback, errorCallback}) => {
  return (dispatch) => {
    request.post({
      dispatch,
      headers,
      url: backendEndpoints.POST_CREATE_CARD,
      data,
      successCallback,
      errorCallback,
      errorHandlerProps
    });
  }
}

const deleteCard = ({headers, id, data, successCallback, errorCallback}) => {
  return (dispatch) => {
    request.delete({
      dispatch,
      data,
      headers,
      url: getUrl(backendEndpoints.DELETE_CARD, {id}),
      successCallback,
      errorCallback,
      errorHandlerProps: hideKeylessErrorHandlerProps
    });
  }
}

const pauseCard = ({headers, id, data, successCallback, errorCallback}) => {
  return (dispatch) => {
    request.put({
      dispatch,
      data,
      headers,
      url: getUrl(backendEndpoints.PUT_PAUSE_CARD, {id}),
      successCallback,
      errorCallback,
      errorHandlerProps: hideKeylessErrorHandlerProps
    });
  }
}

const updateCard = ({headers, id, data, successCallback, errorCallback}) => {
  return (dispatch) => {
    request.put({
      dispatch,
      data,
      headers,
      url: getUrl(backendEndpoints.PUT_UPDATE_CARD, {id}),
      successCallback,
      errorCallback,
      errorHandlerProps: hideKeylessErrorHandlerProps
    });
  }
}

const storeCards = (data) => {
  return (dispatch) => {
    dispatch({
      type: cardConstants.STORE_CARDS,
      data
    });
  }
}

export const cardActions = {
  createCard,
  deleteCard,
  getCard,
  getCards,
  pauseCard,
  updateCard,
  storeCards
};
