import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {
  StyledAddInvoiceModal,
  StyledAddInvoiceModalSpace,
  StyledAddInvoiceModalLineDescription,
  StyledAddInvoiceModalRadio,
  StyledAddInvoiceModalRadioGroupSpace,
  StyledAddInvoiceModalStickyContainer
} from './StyledAddInvoiceModal';
import {Button, Radio, Space} from 'antd';
import SpinSmall from '../../../SpinSmall';
import TransactionDetailsHeader from '../TransactionDetailsHeader';
import InvoiceUpload from '../InvoiceUpload';
import ImagePreviewTooltip from '../ImagePreviewTooltip';
import InvoiceAttachmentPreview from '../InvoiceAttachmentPreview';
import invoicePlaceholderImg from '../../../../static/images/pages/transactions/invoice-placeholder.png';
import {invoicesActions} from '../../../../state/actions';
import {invoiceStatusConstants} from '../../../../constants';
import {amountHelpers, dateHelpers, fileHelpers} from '../../../../utils/helpers';

const defaultOkButtonProps = {
  disabled: true,
  loading: false
};

const AddInvoiceModal = ({
  defaultAttachment,
  onOk,
  open,
  transaction,
  getInvoices,
  getInvoiceDetails,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'transactions']);
  const [okButtonProps, setOkButtonProps] = useState(defaultOkButtonProps);
  const [file, setFile] = useState(null);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(undefined);
  const [invoices, setInvoices] = useState({
    data: [],
    loading: false,
    loaded: false,
    pagination: null
  });
  const [invoicesPreviews, setInvoicesPreviews] = useState({loading: false, invoices: {}});
  const [previewProps, setPreviewProps] = useState({file: undefined, open: false});

  const tooltipContent = useMemo(() => {
    return previewProps.open && (
      <>
        {invoicesPreviews.loading && 'Loading...'}
        {previewProps.file && (
          <InvoiceAttachmentPreview
            attachment={previewProps.file}
            className={invoicesPreviews.loading && 'd-none'}
          />
        )}
      </>
    );
  }, [previewProps, invoicesPreviews]);

  useEffect(() => {
    if (open && !invoices.loaded) {
      loadInvoices();
    }
  }, [getInvoices, open]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadInvoices = () => {
    const query = {
      cursor: invoices.pagination?.cursor || undefined,
      per_page: 10,
      sort_order: 'desc',
      status_not_eq: invoiceStatusConstants.MATCHED
    };
    setInvoices({...invoices, loading: true});
    getInvoices(
      query,
      (response) => {
        const data = response.invoices || [];
        setInvoices({
          ...invoices,
          data: [...invoices.data, ...data],
          pagination: response.pagination,
          loaded: true,
          loading: false
        });
      },
      () => setInvoices({...invoices, loading: false})
    );
  }

  const enableOkButton = () => okButtonProps.disabled && setOkButtonProps({...okButtonProps, disabled: false});

  const disableOkButton = () => setOkButtonProps(defaultOkButtonProps);

  useEffect(() => {
    if (!open) {
      file && setFile(null);
      setUploadFiles([]);
      selectedInvoice && setSelectedInvoice(null);
      disableOkButton();
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  const trans = (key, props) => t(`transactions:modal.addInvoice.${key}`, props);

  const handleOnFileChange = async (files) => {
    if (files.length > 0) {
      const fileBase64 = await fileHelpers.getBase64(files[0].originFileObj);
      setFile({
        file: fileBase64,
        name: files[0].name
      });
      enableOkButton();
    } else {
      setFile(null);
      disableOkButton();
    }
    selectedInvoice && setSelectedInvoice(undefined);
    setUploadFiles(files);
  }

  const handleSubmitFile = () => {
    if (onOk && (file || selectedInvoice)) {
      setOkButtonProps({...okButtonProps, loading: true});
      onOk(
        {
          file,
          invoice: selectedInvoice
        },
        disableOkButton,
        () => setOkButtonProps({...okButtonProps, loading: false})
      );
    }
  }

  const handleRadioChange = (e) => {
    enableOkButton();
    setSelectedInvoice(e.target.value);
  }

  const handleDisplayPreview = (id) => {
    if (invoicesPreviews.loading) return;
    const previewFile = invoicesPreviews.invoices[id] || undefined;
    if (previewFile) {
      setPreviewProps({...previewProps, open: true, file: previewFile})
    } else {
      setInvoicesPreviews({...invoicesPreviews, loading: true});
      getInvoiceDetails(
        id,
        (invoice) => {
          const invoiceFile = {
            file: invoice.file_url,
            name: invoice.file_name
          };
          setPreviewProps({...previewProps, open: true, file: invoiceFile})
          setInvoicesPreviews({
            ...invoicesPreviews,
            loading: false,
            invoices: {
              ...invoicesPreviews.invoices,
              [id]: invoiceFile
            }
          })
        }
      );
    }
  }

  const cancelButtonProps = {
    style: {display: 'none'}
  };

  return (
    <StyledAddInvoiceModal
      cancelButtonProps={cancelButtonProps}
      okButtonProps={okButtonProps}
      okText={t('done')}
      onOk={handleSubmitFile}
      open={open}
      title={trans('title')}
      {...rest}
    >
      <StyledAddInvoiceModalSpace>
        <StyledAddInvoiceModalStickyContainer>
          <TransactionDetailsHeader
            transaction={transaction}
          />
          <StyledAddInvoiceModalLineDescription>
            {t('uploadNewOrSelectFromTheList')}
          </StyledAddInvoiceModalLineDescription>
        </StyledAddInvoiceModalStickyContainer>

        <InvoiceUpload
          defaultFileList={uploadFiles}
          onChange={handleOnFileChange}
        />
        <SpinSmall spinning={invoices.loading && invoices.pagination === null}>
          <Radio.Group
            onChange={handleRadioChange}
            value={selectedInvoice}
          >
            <StyledAddInvoiceModalRadioGroupSpace
              direction='vertical'
              size='small'
            >
              {invoices.data.map((invoice, index) => {
                const invoiceId = invoice.id;
                const vendor = invoice.vendor;
                const amount = invoice?.amount || 0;
                const createdDate = invoice?.created_at || undefined;
                const date = createdDate ? dateHelpers.getDateWithMonth(createdDate, 'DD MMM YYYY') : '';
                return (
                  <ImagePreviewTooltip
                    key={`image-preview-${index}`}
                    trigger='hover'
                    mouseEnterDelay={1}
                    mouseLeaveDelay={1}
                    title={tooltipContent}
                  >
                    <div
                      key={`radio-${index}`}
                      onMouseOver={() => handleDisplayPreview(invoiceId)}
                      onMouseLeave={() => setPreviewProps({...previewProps, open: false})}
                    >
                      <StyledAddInvoiceModalRadio
                        value={invoiceId}
                      >
                        <Space size='small'>
                          <img
                            alt='invoice placeholer'
                            className='placeholder'
                            src={invoicePlaceholderImg}
                          />
                          <Space direction='vertical' size={2}>
                            <span className='vendor'>
                              {vendor}
                            </span>
                            <Space size='small'>
                              <span className='date'>
                                {date}
                              </span>
                              <span className='price'>
                                {amountHelpers.getAmountWithCurrencyCode(amount)}
                              </span>
                            </Space>
                          </Space>
                        </Space>
                      </StyledAddInvoiceModalRadio>
                    </div>
                  </ImagePreviewTooltip>
                )
              })}
            </StyledAddInvoiceModalRadioGroupSpace>
          </Radio.Group>
        </SpinSmall>
        {invoices.pagination?.cursor && (
          <div className='d-flex justify-center'>
            <Button
              loading={invoices.loading}
              onClick={loadInvoices}
            >
              {t('loadMore')}
            </Button>
          </div>
        )}
      </StyledAddInvoiceModalSpace>
    </StyledAddInvoiceModal>
  );
}

AddInvoiceModal.propTypes = {
  defaultAttachment: PropTypes.shape({
    contentType: PropTypes.string,
    file: PropTypes.string,
    name: PropTypes.string
  }),
  transaction: PropTypes.object,
  onOk: PropTypes.func,
  open: PropTypes.bool.isRequired
}

const mapDispatchToProps = {
  getInvoices: invoicesActions.getInvoices,
  getInvoiceDetails: invoicesActions.getInvoice
}

export default connect(null, mapDispatchToProps)(AddInvoiceModal);
