import styled from 'styled-components';
import AntdModal from '../../../../../AntdModal';
import Card from '../../../../../Card';
import {typographyStyles} from '../../../../../../utils/styles';

const StyledCardDetailsModal = styled(AntdModal)`
  .ant-modal-footer {
    align-items: center;
    border-top: 1px solid ${props => props.theme.colors.neutral_4};
    display: flex;
    justify-content: space-between;
    padding-top: 16px;
  }

  .ant-space {
    width: 100%;
  }

  .card-image {
    border-radius: 16px;
    width: 342px;
    height: 218px;
  }

  .card-details {
    & > div:nth-of-type(2) {
      width: 100%;

      .ant-card-body {
        & > * {
          &:not(:last-of-type) {
            padding-bottom: 0;
          }
        }
      }
    }
  }
`;

const StyledCardDetailsTimer = styled.span`
  ${typographyStyles.body.regular};
  color: ${props => props.theme.colors.neutral_7};

  .time {
    ${typographyStyles.body.bold};
    color: ${props => props.theme.colors.primary_8};
    margin-left: 12px;
  }
`;

const StyledCardDetailsCard = styled(Card)`
  .ant-card-head {
    background-color: ${props => props.theme.colors.neutral_2};
  }
  .ant-card-body {
    padding: 0;
    & > div {
      min-height: 52px;
    }
  }
  min-height: 218px;
`;

const StyledCardDetailsModalImageContainer = styled.div`
  min-height: 218px;
  min-width: 342px;
`

export {
  StyledCardDetailsModal,
  StyledCardDetailsCard,
  StyledCardDetailsTimer,
  StyledCardDetailsModalImageContainer
}
