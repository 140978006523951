import styled from 'styled-components';

const StyledPageContainer = styled.div`
  display: block;
  height: 100vh;
  max-height: 100vh;
  overflow: auto;
  
  & > .balance-page-header {
    &:nth-of-type(1) {
      position: sticky;
      top: 0;
      z-index: 3;
    }
  }
`;

export {
  StyledPageContainer
}