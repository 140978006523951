import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {SwitchTransition} from 'react-transition-group';
import {useNavigate} from 'react-router-dom';
import {StyledSignUpPage, StyledSteps, StyledFormContent, StyledLink} from './StyledSignUpPage';
import {FadeTransition, PageContainer} from '../../components/pages/AuthPage/PageContainer';
import {StyledImageTransition} from '../../components/pages/AuthPage/PageContainer/StyledPageContainer';
import PhoneAuthForm from '../../components/pages/AuthPage/PhoneAuthForm';
import CodeAuthForm from '../../components/pages/AuthPage/CodeAuthForm';
import CreatePinForm from '../../components/pages/AuthPage/CreatePinForm';
import CompleteProfileForm from '../../components/pages/AuthPage/CompleteProfileForm';
import PageDocumentDetails from '../../components/PageDocumentDetails';
import {authActions, userActions} from '../../state/actions';
import {localStorageKeysConstants, COOKIE_POLICY_URL, PRIVACY_POLICY_URL, TERM_OF_USE_URL} from '../../constants';
import {firebaseEvents} from '../../snippets/firebase';
import useSearchParams from '../../hooks/useSearchParams';
import {ReactComponent as Step1SvgImage} from '../../static/images/pages/sign-up/step-1.svg';
import {ReactComponent as Step2SvgImage} from '../../static/images/pages/forgot-pin/step-2.svg';
import {ReactComponent as Step3SvgImage} from '../../static/images/pages/forgot-pin/step-3.svg';
import {ReactComponent as Step4SvgImage} from '../../static/images/pages/sign-up/step-4.svg';
import {systemHelpers} from '../../utils/helpers';

const {logEvent} = systemHelpers;

const defaultFormProps = {
  loading: false,
  disabled: false
};

const SignUpPage = ({
  phoneVerification,
  codeVerification,
  codeResend,
  createPassword,
  finishRegistration,
  getEmployeeProfile,
  getUserProfile,
  inviteToken
}) => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [isAlreadyUsedPhone, setIsAlreadyUsedPhone] = useState(false);
  const [data, setData] = useState({
    disableEmail: false,
    email: '',
    phoneNumber: null,
  });
  const [phoneAuthFormProps, setPhoneAuthFormProps] = useState(defaultFormProps);
  const [codeAuthFormProps, setCodeAuthFormProps] = useState({
    disabled: false,
    errors: null
  });
  const [pinFormProps, setPinFormProps] = useState(defaultFormProps);
  const [completeProfileFormProps, setCompleteProfileProps] = useState(defaultFormProps);

  const {params} = useSearchParams();

  const {phoneNumber} = data;

  const [trans] = useTranslation(['auth', 'main']);
  const t = (key) => trans(`singUp.${key}`);
  const mainT = (key) => trans(`main:${key}`);

  useEffect(() => localStorage.removeItem(localStorageKeysConstants.SESSION_ID), []);

  // check if sign up token is valid
  useEffect(() => {
    const signUpToken = params.get('key');
    let isAvailablePage = false;
    if (inviteToken) return; // break if the user was invited

    if (signUpToken) {
      try {
        let decodedToken = atob(signUpToken);
        if (decodedToken.includes('h1')) isAvailablePage = true;
      } catch (e) {
      }
    }
    // redirect to the main page
    if (!isAvailablePage) navigate('/');
  }, [params, inviteToken]); // eslint-disable-line react-hooks/exhaustive-deps

  const title = (step) => t(`stepTitle.${step}`);

  const description = (step) => t(`stepDescription.${step}`);

  const moveToNextStep = () => setStep(step + 1);

  const onBack = () => {
    setStep(step - 1);
    isAlreadyUsedPhone && setIsAlreadyUsedPhone(false);
  }

  const onPhoneAuth = (phoneData, successCallback, errorCallback) => {
    setPhoneAuthFormProps({...phoneAuthFormProps, loading: true, disabled: true});
    phoneVerification(
      phoneData,
      (resp) => {
        successCallback && successCallback(resp);
        setPhoneAuthFormProps(defaultFormProps);
        setData({...data, phoneNumber: phoneData.phone_number});
        moveToNextStep();
        logEvent(firebaseEvents.REGISTRATION_PHONE_NUMBER_ENTERED);
      },
      (resp) => {
        errorCallback && errorCallback(resp);
        setPhoneAuthFormProps(defaultFormProps);
      }
    );
  }

  const onCodeResend = (successCallback, errorCallback) => codeResend({phone_number: phoneNumber}, successCallback, errorCallback);

  const onCodeSuccess = ({code}) => {
    let requestData = {code, phone_number: data.phoneNumber};
    if (inviteToken) requestData = Object.assign({invitation_token: inviteToken}, requestData);
    setCodeAuthFormProps({...codeAuthFormProps, disabled: true});
    codeVerification(
      requestData,
      (response) => {
        const email = response.invitation_email;
        const userExist = response.exists;
        if (userExist) {
          setIsAlreadyUsedPhone(true);
        } else {
          if (email) setData({...data, email, disableEmail: true});
          moveToNextStep();
        }
        setCodeAuthFormProps({...codeAuthFormProps, disabled: false});
        logEvent(firebaseEvents.REGISTRATION_CODE_ENTERED);
      },
      (errors) => setCodeAuthFormProps({...codeAuthFormProps, disabled: false, errors})
    );
  }

  const onPinSubmit = ({password}, successCallback, errorCallback) => {
    setPinFormProps({...pinFormProps, disabled: true, loading: true});
    createPassword(
      { password, phone_number: phoneNumber },
      () => {
        logEvent(firebaseEvents.REGISTRATION_PASSWORD_CREATED);
        moveToNextStep();
        setPinFormProps(defaultFormProps);
        successCallback && successCallback();
      },
      () => {
        const errors = {message: trans('errors.The code is incorrect')};
        errorCallback && errorCallback(errors);
        setPinFormProps(defaultFormProps);
      }
    );
  }

  const onProfileSubmit = (data, successCallback, errorCallback) => {
    setCompleteProfileProps({...setCompleteProfileProps, disabled: true, loading: true});
    finishRegistration(
      {
        email: data.email,
        phone_number: phoneNumber,
        user_first_name: data.first_name,
        user_last_name: data.last_name
      },
      () => {
        logEvent(firebaseEvents.REGISTRATION_PERSONAL_INFORMATION_ENTERED);
        navigate('/');
        getEmployeeProfile();
        getUserProfile();
        successCallback && successCallback()
      },
      (errors) => {
        errorCallback && errorCallback(errors);
        setCompleteProfileProps(defaultFormProps);
      }
    );
  }

  const imageContent = ({
    1: <Step1SvgImage/>,
    2: <Step2SvgImage/>,
    3: <Step3SvgImage/>,
    4: <Step4SvgImage/>
  })[step] || null;

  const confirmText = (
    <>
      {t('bySigning')} <a href={PRIVACY_POLICY_URL} rel='noreferrer' target='_blank'>{t('privacyPolicy')}</a> {mainT('and')} <a href={COOKIE_POLICY_URL} rel='noreferrer' target='_blank'>{t('cookiePolicy')}</a> {mainT('and')} {t('accept')} <a href={TERM_OF_USE_URL} rel='noreferrer' target='_blank'>{t('termsOfService')}</a>
    </>
  );

  const formContent = ({
    1: (
      <>
        <h3>{title(step)}</h3>
        <p>{description(step)}</p>
        <PhoneAuthForm
          {...phoneAuthFormProps}
          buttonText={mainT('create')}
          confirmText={confirmText}
          enablePassword={false}
          enableConfirm={true}
          initialValues={{phone_number: phoneNumber}}
          onSubmit={onPhoneAuth}
        />
      </>
    ),
    2: (
      <>
        <h3>{title(step)}</h3>
        {isAlreadyUsedPhone
          ? (
            <>
              <p className='help-text'>{t('alreadyRegistered')}</p>
              <StyledLink to='/'>
                {mainT('login')}
              </StyledLink>
            </>
          ) : (
            <>
              <p>{description(step)} <span className='bold'>{phoneNumber}</span></p>
              <CodeAuthForm
                {...codeAuthFormProps}
                autofocus
                onSuccess={onCodeSuccess}
                onResend={onCodeResend}
                onBack={onBack}
              />
            </>
          )
        }
      </>
    ),
    3: (
      <>
        <h3>{title(step)}</h3>
        <p>{description(step)}</p>
        <CreatePinForm
          {...pinFormProps}
          buttonText={mainT('save')}
          onSubmit={onPinSubmit}
        />
      </>
    ),
    4: (
      <>
        <h3>{title(step)}</h3>
        <CompleteProfileForm
          {...completeProfileFormProps}
          initialValues={{email: data.email}}
          emailProps={{disabled: data.disableEmail}}
          buttonText={`${mainT('save')} & ${mainT('continue')}`}
          onSubmit={onProfileSubmit}
        />
      </>
    )
  })[step] || null;

  const transitionProps = {
    key: step,
    mountOnEnter: true,
    timeout: 250,
    unmountOnExit: true,
  }

  return (
    <StyledSignUpPage>
      <PageDocumentDetails title={mainT('pageTitles.signUp')} />
      <PageContainer
        imageContent={
          <StyledImageTransition>
            <SwitchTransition>
              <FadeTransition {...transitionProps}>
                <div className='image-content'>
                  {imageContent}
                </div>
              </FadeTransition>
            </SwitchTransition>
          </StyledImageTransition>
        }
        formContent={
          <StyledFormContent>
            <div className='form-content-header'>
              <StyledSteps quantity={4} activeNum={step} />
              <SwitchTransition>
                <FadeTransition {...transitionProps}>
                  <div className='form-content'>
                    {formContent}
                  </div>
                </FadeTransition>
              </SwitchTransition>
            </div>
          </StyledFormContent>
        }
      />
    </StyledSignUpPage>
  )
}

const mapStateToProps = state => {
  return {
    inviteToken: state.auth.companyInviteToken
  }
}

const mapDispatchToProps = {
  phoneVerification: authActions.phoneVerification,
  codeVerification: authActions.signUpPhoneCodeVerification,
  codeResend: authActions.signUpCodeResend,
  createPassword: authActions.createPassword,
  finishRegistration: authActions.finishRegistration,
  getEmployeeProfile: userActions.getEmployeeProfile,
  getUserProfile: userActions.getUserProfile
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPage);
