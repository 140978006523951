import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Upload as AntdUpload} from 'antd';
import PropTypes from 'prop-types';
import ImgCrop from 'antd-img-crop';
import {
  StyledUpload,
  StyledUploadChildren,
  StyledUploadChildrenContent,
  StyledUploadFilePreview
} from './StyledUpload';
import {ReactComponent as UploadSvgImage} from '../../../../../../static/images/pages/kyc/document-upload-back.svg';
import {CloseIcon} from '../../../../../../icons';
import {elementHelpers, fileHelpers} from '../../../../../../utils/helpers';

const Upload = React.forwardRef(({
  className,
  image,
  imageCrop,
  onChange,
  uploadProps,
  defaultFileList,
  ...rest
}, ref) => {
  const [t] = useTranslation('main');
  const [filePreviewList, setFilePreviewList] = useState([]);
  const [fileList, setFileList] = useState([]);

  const {enabled: enabledPreview, isImg, filename} = useMemo(() => {
    let enabled = false;
    let isImg = false;
    let filename;
    if (filePreviewList.length > 0 && fileList.length > 0) {
      enabled = true;
      filename = fileList[0].name;
      isImg = !fileHelpers.hasExtensions(filename, ['.doc', '.pdf'])
    }

    return {
      enabled,
      isImg,
      filename
    }
  }, [filePreviewList, fileList]);

  const elementClassName = useMemo(() => {
    let value = 'kyc-upload';
    if (className) value = `${value} ${className}`;
    return value;
  }, [className]);

  const handleChange = ({ fileList: newFileList }) => {
    handlePreview(newFileList).then();
    onChange && onChange(newFileList);
  }

  useEffect(() => {
    defaultFileList && handlePreview(defaultFileList).then();
  }, [defaultFileList]);

  const handlePreview = async (files) => {
    let previewList = [];
    if (files.length > 0) {
      let filePreview = typeof files[0] === 'string' ? files[0] : await fileHelpers.getBase64(files[0].originFileObj);
      previewList = [filePreview];
    }
    setFilePreviewList(previewList);
    setFileList(files);
  };

  const antdUploadProps = {
    ...uploadProps,
    className: elementHelpers.getClassName(enabledPreview && 'd-none', uploadProps?.className || ''),
    customRequest: () => null,
    maxCount: 1,
    onChange: imageCrop ? undefined : handleChange,
    showUploadList: false,
  }

  const onRemove = () => {
    setFilePreviewList([]);
    setFileList([]);
    onChange && onChange([]);
  }

  const uploadContent = (
    <AntdUpload
      {...antdUploadProps}
    >
      <StyledUploadChildren
        direction='vertical'
        size='middle'
      >
        {image || <UploadSvgImage />}
        <StyledUploadChildrenContent>
          <p>{t('dragAndDropFileOr')}</p>
          <Button type='link'>{t('browse')}</Button>
        </StyledUploadChildrenContent>
      </StyledUploadChildren>
    </AntdUpload>
  );

  return (
    <StyledUpload
      {...rest}
      className={elementClassName}
      ref={ref}
    >
      <StyledUploadFilePreview className={!enabledPreview && 'd-none'}>
        <Button
          danger
          onClick={onRemove}
          type='primary'
          shape='circle'
        >
          <CloseIcon />
        </Button>
        {isImg ? <img src={filePreviewList[0]} alt='file preview' /> : filename}
      </StyledUploadFilePreview>
      {imageCrop ? (
        <ImgCrop
          cropShape='round'
          onModalOk={(originFileObj) => handleChange({fileList: [{originFileObj}]})}
          quality={1}
        >
          {uploadContent}
        </ImgCrop>
      ) : uploadContent}
    </StyledUpload>
  );
});

Upload.propTypes = {
  defaultFileList: PropTypes.array,
  image: PropTypes.any,
  imageCrop: PropTypes.bool,
  uploadProps: PropTypes.object
}

Upload.defaultProps = {
  defaultFileList: [],
  imageCrop: false,
  uploadProps: {}
}

export default Upload;
