import React from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {Tooltip} from 'antd';
import {StyledEditButton} from './StyledEditButton';
import {EditLineIcon} from '../../icons';

const EditButton = ({
  children,
  size,
  tooltip,
  tooltipProps,
  type,
  ...rest
}) => {
  const [t] = useTranslation('main');

  const button = (
    <StyledEditButton
      icon={<EditLineIcon />}
      size={size}
      type={type}
      {...rest}
    >
      {children}
    </StyledEditButton>
  );

  if (tooltip) {
    return (
      <Tooltip
        title={t('edit')}
        {...tooltipProps}
      >
        {button}
      </Tooltip>
    )
  } else {
    return button;
  }
}

EditButton.propTypes = {
  size: PropTypes.oneOf(['small', 'middle', 'large']),
  tooltip: PropTypes.bool,
  tooltipProps: PropTypes.object,
  type: PropTypes.oneOf(['default', 'primary', 'dashed', 'link', 'text'])
}

EditButton.defaultProps = {
  size: 'small',
  tooltip: true,
  type: 'text',
}

export default EditButton;
