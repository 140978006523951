import {request} from '../services/request';
import {backendEndpoints} from '../../api';
import {apiHelpers} from '../../utils/helpers';

const errorHandlerProps = {disableAlert: true};
const hideKeylessErrorHandlerProps = {hideKeylessError: true};

const getEmployeesTotal = (success, error) => {
  return (dispatch, getState) => {
    request.get({
      dispatch,
      url: getUrl(backendEndpoints.GET_COMPANY_TEAM_EMPLOYEES_TOTALS, getState),
      successCallback: (data) => success && success(data),
      errorCallback: (data) => error && error(data),
      errorHandlerProps
    });
  }
}

const getEmployees = (query = null, success, error) => {
  return (dispatch, getState) => {
    request.get({
      dispatch,
      url: getUrl(backendEndpoints.GET_COMPANY_TEAM_EMPLOYEES_V2, getState),
      query,
      successCallback: (data) => success && success(data),
      errorCallback: (data) => error && error(data),
      errorHandlerProps
    });
  }
}

const getEmployee = (email, success, error) => {
  return (dispatch, getState) => {
    request.get({
      dispatch,
      url: getUrl(backendEndpoints.GET_COMPANY_TEAM_EMPLOYEE, getState, {email}),
      successCallback: (data) => success && success(data),
      errorCallback: (data) => error && error(data),
      errorHandlerProps
    });
  }
}

const getEmployeeSubscriptions = (email, query, success, error) => {
  return (dispatch, getState) => {
    request.get({
      dispatch,
      url: getUrl(backendEndpoints.GET_COMPANY_TEAM_EMPLOYEE_SUBSCRIPTIONS, getState, {email}),
      query,
      successCallback: (data) => success && success(data),
      errorCallback: (data) => error && error(data),
      errorHandlerProps
    });
  }
}

const createEmployee = (data, success, error) => {
  return (dispatch, getState) => {
    request.post({
      dispatch,
      url: getUrl(backendEndpoints.POST_COMPANY_TEAM_EMPLOYEE_CREATE, getState),
      data,
      successCallback: (data) => success && success(data),
      errorCallback: (resp) => error && error(resp),
      errorHandlerProps
    });
  }
}

const deleteEmployee = (email, success, error) => {
  return (dispatch, getState) => {
    request.delete({
      dispatch,
      url: getUrl(backendEndpoints.DELETE_COMPANY_TEAM_EMPLOYEE, getState, {email}),
      successCallback: (data) => success && success(data),
      errorCallback: (resp) => error && error(resp),
    });
  }
}

const updateEmployee = (email, data, success, error) => {
  return (dispatch, getState) => {
    request.put({
      dispatch,
      url: getUrl(backendEndpoints.PUT_COMPANY_TEAM_EMPLOYEE_UPDATE, getState, {email}),
      data,
      successCallback: (data) => success && success(data),
      errorCallback: (resp) => error && error(resp),
    });
  }
}

const updateEmployeeStatus = ({
  headers,
  email,
  data,
  successCallback,
  errorCallback
}) => {
  return (dispatch, getState) => {
    request.put({
      dispatch,
      headers,
      url: getUrl(backendEndpoints.PUT_COMPANY_TEAM_EMPLOYEE_UPDATE_STATUS, getState, {email}),
      data,
      successCallback,
      errorCallback,
      errorHandlerProps: hideKeylessErrorHandlerProps
    });
  }
}

const resendInvite = (email, data, success, error) => {
  return (dispatch, getState) => {
    request.post({
      dispatch,
      url: getUrl(backendEndpoints.POST_COMPANY_TEAM_EMPLOYEE_RESEND_INVITE, getState, {email}),
      data,
      successCallback: (data) => success && success(data),
      errorCallback: (resp) => error && error(resp)
    });
  }
}

const getUrl = (url, getState, params = {}) => {
  const {id} = getState().company;
  return apiHelpers.getUrl(url, {id, ...params});
}

export const teamActions = {
  getEmployeesTotal,
  getEmployees,
  getEmployee,
  getEmployeeSubscriptions,
  createEmployee,
  deleteEmployee,
  updateEmployee,
  updateEmployeeStatus,
  resendInvite
};
