import styled from 'styled-components';
import {bordersStyles} from '../../../../utils/styles';

const StyledHeaderDetails = styled.div`
  ${bordersStyles.light};
  align-items: self-start;
  background: ${props => props.theme.colors.neutral_1};
  display: flex;
  justify-content: space-between;
  padding: 24px;
  background: linear-gradient(0deg, ${props => props.theme.colors.neutral_1}, ${props => props.theme.colors.neutral_1}),
              linear-gradient(0deg, ${props => props.theme.colors.neutral_3}, ${props => props.theme.colors.neutral_3});
`;

export {
  StyledHeaderDetails
}
