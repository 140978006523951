import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {Avatar, Form, Input, Space} from 'antd';
import {
  StyledDeleteModal,
  StyledDeleteModalSpace,
  StyledDeleteModalLogoContainer,
  StyledDeleteModalDetailsSpace
} from './StyledDeleteModal';
import TrashIcon from '../../../../icons/TrashIcon';
import {objectHelpers, textHelpers} from '../../../../utils/helpers';

const gObjProp = objectHelpers.getObjProp;

const initialFormValues = {
  cancel_reason: ''
}

const DeleteModal = ({
  open,
  loading,
  data,
  onOk,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'subscriptions']);
  const [form] = Form.useForm();

  const trans = (key, options) => t(`subscriptions:modal.delete.${key}`, options);

  const {
    category,
    logo,
    name
  } = useMemo(() => {
    let name = '';
    let logo = undefined;
    let category = '';
    if (data && data.service) {
      const {service} = data;
      name = gObjProp(service, 'name') || gObjProp(service, 'url');
      logo = gObjProp(service, 'logo');
      category = gObjProp(service, 'category');
    }
    return {category, logo, name};
  }, [data]);

  const handleAfterClose = () => form.setFieldsValue({cancel_reason: ''});

  const handleOnFormFinish = (fields) => onOk && onOk(fields);

  return (
    <StyledDeleteModal
      open={open}
      okText={t('confirm')}
      loading={loading}
      cancelButtonProps={{size: 'large'}}
      okButtonProps={{loading, size: 'large', type: 'primary'}}
      width={400}
      title={trans('title')}
      {...rest}
      afterClose={handleAfterClose}
      onOk={() => form.submit()}
    >
      <StyledDeleteModalSpace
        align='center'
        direction='vertical'
        size='large'
      >
        <Space
          direction='vertical'
          size={12}
        >
          <StyledDeleteModalLogoContainer>
            <TrashIcon />
            <Avatar size={44} src={logo}>
              {textHelpers.getInitials(name)}
            </Avatar>
          </StyledDeleteModalLogoContainer>
          <StyledDeleteModalDetailsSpace size={0} direction='vertical'>
            <h3>{name}</h3>
            <p>{category}</p>
          </StyledDeleteModalDetailsSpace>
        </Space>
        <Space align='center' size='large' direction='vertical'>
          <p>{trans(`description`, {name})}</p>
          <Form
            initialValues={initialFormValues}
            disabled={loading}
            form={form}
            layout='vertical'
            onFinish={handleOnFormFinish}
            requiredMark={false}
          >
            <Form.Item
              name='cancel_reason'
            >
              <Input.TextArea
                autoSize={{ minRows: 3 }}
                placeholder={trans('inputPlaceholder')}
                size='large'
              />
            </Form.Item>
          </Form>
        </Space>
      </StyledDeleteModalSpace>
    </StyledDeleteModal>
  );
}

DeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  data: PropTypes.object,
}

DeleteModal.defaultProps = {
  loading: false,
}

export default DeleteModal;
