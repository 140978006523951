import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {Collapse} from 'react-collapse';
import {Alert, Form, Input, Select} from 'antd';
import {StyledFormSpace} from './StyledInviteUserModal';
import AntdModal from '../../../AntdModal';
import SpinSmall from '../../../SpinSmall';
import {formHelpers, UserRoleOptions} from '../../../../utils/helpers';

const {Item} = Form;

const defaultFormValues = {
  role: undefined,
  email: ''
};

const defaultError = {
  message: null,
  open: false
};

const InviteUserModal = ({
  open,
  handleOk,
  handleCancel,
  loading,
  onAddBudgetClick,
  ...rest
}) => {
  const [t] = useTranslation(['teams', 'main']);
  const [rolesOptions, ] = useState(UserRoleOptions(false));
  const [form] = Form.useForm();
  const [error, setError] = useState(defaultError);
  const email = Form.useWatch('email', {form});

  useEffect(() => {
    if (!open) {
      form.setFieldsValue(defaultFormValues);
      error && setError(defaultError);
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error.open) setError({...error, open: false})
  }, [email]); // eslint-disable-line react-hooks/exhaustive-deps

  const requiredFieldMessage = t('main:validation.fieldIsRequired');

  const trans = (key) => t(`modal.inviteUser.${key}`);

  const handleSubmit = (fields) => {
    if (handleOk) {
      handleOk(
        fields,
        null,
        (err) => {
          if (err.message) {
            setError({
              ...error,
              message: err.message,
              open: true
            });
          } else {
            const fields = formHelpers.getFormServerErrorFields(err);
            form.setFields(fields);
          }
        }
      )
    }
  }

  const rules = [
    {required: true, message: requiredFieldMessage},
  ];

  return (
    <AntdModal
      cancelButtonProps={{size: 'large'}}
      forceRender
      okButtonProps={{
        disabled: loading,
        size: 'large',
        type: 'primary',
      }}
      okText={trans('okText')}
      onCancel={handleCancel}
      onOk={() => form.submit()}
      open={open}
      title={trans('title')}
      {...rest}
    >
      <SpinSmall spinning={loading}>
        <Form
          initialValues={defaultFormValues}
          form={form}
          layout='vertical'
          onFinish={handleSubmit}
          requiredMark={false}
        >
          <StyledFormSpace size='middle'>
            <Item
              label={t('main:email')}
              name='email'
              rules={rules}
              required
            >
              <Input
                placeholder={trans('placeholder.email')}
                size='large'
                type='email'
              />
            </Item>
            <Item
              label={t('main:role')}
              name='role'
              rules={rules}
              required
            >
              <Select
                options={rolesOptions}
                size='large'
                placeholder={trans('placeholder.role')}
              />
            </Item>
          </StyledFormSpace>
        </Form>
        <Collapse isOpened={error.open}>
          <Alert message={error.message} type='error' showIcon />
        </Collapse>
      </SpinSmall>
    </AntdModal>
  );
}

InviteUserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func,
  handleOk: PropTypes.func,
  loading: PropTypes.bool,
}

InviteUserModal.defaultProps = {
  loading: false,
}

export default InviteUserModal;
