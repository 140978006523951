import styled from 'styled-components';
import {Empty} from 'antd';
import {typographyStyles} from '../../utils/styles';

const StyledEmpty = styled(Empty)`
  margin-top: 16.88vh;
  .ant-empty-image {
    height: 180px;
  }
`;

const StyledDescription = styled.div`
  max-width: 425px;
  margin: 16px auto 24px auto;
  text-align: center;

  h3 {
    ${typographyStyles.heading.h3};
  }

  p {
    ${typographyStyles.heading.h5_1};
    
    color: ${props => props.theme.colors.neutral_7};
    margin-bottom: 32px;
  }

  b, strong {
    color: ${props => props.theme.colors.neutral_10};
  }
`;

export {
  StyledEmpty,
  StyledDescription
};
