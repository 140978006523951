import styled from 'styled-components';
import {Space} from 'antd';
import {bordersStyles, typographyStyles} from '../../utils/styles';

const ICON_HEIGHT = '12px';

const StyledHistory = styled.div`
  position: relative;
`;

const StyledHistoryItem = styled.div`
  
`;

const StyledHistoryItemSpace = styled(Space)`
  width: 100%;
  position: relative;
  & > .ant-space-item {
    &:last-of-type {
      width: 100%;
    }
  }
`;

const StyledHistoryItemDetailsSpace = styled(Space)`
  ${typographyStyles.body.medium};
  padding-bottom: 8px;
  width: 100%;
  & > div {
    width: 100%;
  }
  .date {
    color: ${props => props.theme.colors.neutral_7};
  }
  .message {
    ${bordersStyles.default};
    background: ${props => props.theme.colors.neutral_1};
    padding: 24px;
  }
`;

const StyledHistoryItemIcon = styled.div`
  background: ${props => props.theme.colors.neutral_5};
  border-radius: 50%;
  height: ${ICON_HEIGHT};
  margin-top: calc(${ICON_HEIGHT} / 2);
  width: ${ICON_HEIGHT};
`;

const StyledHistoryItemLine = styled.div`
  background: ${props => props.theme.colors.neutral_5};
  height: calc(100% - (${ICON_HEIGHT} * 2));
  top: calc(${ICON_HEIGHT} * 2);
  left: calc((${ICON_HEIGHT} / 2) - 1px);
  width: 2px;
  position: absolute;
`;

export {
  StyledHistory,
  StyledHistoryItem,
  StyledHistoryItemSpace,
  StyledHistoryItemDetailsSpace,
  StyledHistoryItemIcon,
  StyledHistoryItemLine
};
