import React from 'react';
import {StyledPageContainer} from './StyledPageContainer';

const PageContainer = ({children, ...rest}) => {
  return (
    <StyledPageContainer
      {...rest}
      id='app-page-container'
    >
      {children}
    </StyledPageContainer>
  );
};

export default PageContainer;
