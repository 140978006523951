import {request} from '../services/request';
import {backendEndpoints} from '../../api';
import {apiHelpers} from '../../utils/helpers';

const {getUrl} = apiHelpers;

const errorHandlerProps = {disableAlert: true};

const getInvoices = (query = null, success, error) => {
  return (dispatch) => {
    request.get({
      dispatch,
      url: backendEndpoints.GET_INVOICES,
      query,
      successCallback: (data) => success && success(data),
      errorCallback: (data) => error && error(data),
      errorHandlerProps
    });
  }
}

const getInvoice = (id, success, error) => {
  return (dispatch) => {
    request.get({
      dispatch,
      url: getUrl(backendEndpoints.GET_INVOICE, {id}),
      successCallback: (data) => success && success(data),
      errorCallback: (data) => error && error(data),
      errorHandlerProps
    });
  }
}

const batchCreateInvoices = (data, success, error) => {
  return (dispatch) => {
    request.post({
      dispatch,
      url: backendEndpoints.POST_INVOICES_BATCH,
      data,
      successCallback: (data) => success && success(data),
      errorCallback: (data) => error && error(data)
    });
  }
}

const batchDeleteInvoices = (invoices, success, error) => {
  return (dispatch) => {
    request.delete({
      dispatch,
      url: backendEndpoints.DELETE_INVOICES_BATCH,
      successCallback: (data) => success && success(data),
      errorCallback: (data) => error && error(data),
      data: {invoices}
    });
  }
}

const getInvoicesTotal = (success, error) => {
  return (dispatch) => {
    request.get({
      dispatch,
      url: backendEndpoints.GET_INVOICES_STATS,
      successCallback: (data) => success && success(data),
      errorCallback: (data) => error && error(data)
    });
  }
}

const updateInvoice = (id, data, success, error) => {
  return (dispatch) => {
    request.put({
      dispatch,
      url: getUrl(backendEndpoints.PUT_INVOICE_UPDATE, {id}),
      data,
      successCallback: (data) => success && success(data),
      errorCallback: (data) => error && error(data),
      errorHandlerProps
    });
  }
}

const linkExpense = (id, data, success, error) => {
  return (dispatch) => {
    request.put({
      dispatch,
      url: getUrl(backendEndpoints.PUT_INVOICE_LINK_EXPENSE, {id}),
      data,
      successCallback: (data) => success && success(data),
      errorCallback: (data) => error && error(data),
      errorHandlerProps
    });
  }
}

const unlinkExpense = (data, successCallback, errorCallback) => {
  return (dispatch) => {
    request.put({
      dispatch,
      url: backendEndpoints.PUT_INVOICE_UNLINK_EXPENSE,
      data,
      successCallback,
      errorCallback,
      errorHandlerProps
    });
  }
}

const downloadInvoice = (id, success, error) => {
  return (dispatch) => {
    request.get({
      dispatch,
      url: getUrl(backendEndpoints.GET_INVOICE_ATTACHMENT, {id}),
      successCallback: (data) => success && success(data),
      errorCallback: (data) => error && error(data),
      errorHandlerProps
    });
  }
}

const downloadZipInvoices = (query, success, error) => {
  return (dispatch) => {
    request.get({
      dispatch,
      url: backendEndpoints.GET_DOWNLOAD_ZIP_INVOICES,
      query,
      successCallback: (data) => success && success(data),
      errorCallback: (err) => error && error(err)
    });
  }
}

export const invoicesActions = {
  getInvoice,
  getInvoices,
  getInvoicesTotal,
  batchCreateInvoices,
  batchDeleteInvoices,
  downloadInvoice,
  downloadZipInvoices,
  linkExpense,
  unlinkExpense,
  updateInvoice,
};
