import styled from 'styled-components';
import PageContainer from '../../components/PageContainer';

const StyledInvoicesPageContainer = styled(PageContainer)`
  & > {
    .ant-spin-nested-loading {
      &:last-of-type {
        min-height: calc(100vh - 70px - 66px);
      }
    }
  }
`;

export {
  StyledInvoicesPageContainer
}