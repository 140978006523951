import moment from 'moment';

export const dateHelpers = {
  getCurrentDate: () => {
    const date = moment(new Date());
    const year = parseInt(date.format('YYYY'));
    const month = parseInt(date.format('M'));
    const day = parseInt(date.format('D'));
    return { date, year, month, day };
  },
  getDate: (date, format) => moment(date, 'YYYY-MM-DD').format(format),
  getDateFromISO: (date, format) => moment(date, 'YYYY-MM-DDTHH:mm:ssZ').format(format),
  getDateWithMonth: (date, format = 'DD MMM YYYY') => moment(date, 'YYYY-MM-DD').format(format),
  getDateWithGMT: (date) => {
    let newDate = new Date(date);
    newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
    return newDate;
  },
  getDateWithGMTFormat: (date, format) => dateHelpers.getDate(dateHelpers.getDateWithGMT(date), format),
  getTimestampDate: (date, format) => {
    date = String(date).split('.')[0];
    if (date.length === 10) date = date * 1000;
    return moment(date).format(format);
  },
  getTimestampDateObject: (date) => {
    date = String(date).split('.')[0];
    if (date.length === 10) date = date * 1000;
    return moment(date).toDate();
  },
  getMomentUnixTimestamp: (date) => date.unix(),
  getSecondsTime: (seconds) => {
    const date = new Date(0);
    date.setSeconds(seconds);
    return moment(date).format('mm:ss');
  },
}
