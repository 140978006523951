import React from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {
  StyledUpload,
  DefaultUploadChildren
} from './StyledUpload';
import {ReactComponent as UploadIconSvgImage} from '../../static/images/icons/upload-icon.svg';
import {fileHelpers} from '../../utils/helpers';

const Upload = ({
  accept,
  children,
  image,
  ...rest
}) => {
  const [t] = useTranslation('main');

  const formats = fileHelpers.getSupportedFormatsList(accept);

  return (
    <StyledUpload
      {...rest}
    >
      {children || (
        <DefaultUploadChildren>
          {image || <UploadIconSvgImage />}
          <p>{t('dragAndDropOrClick')}</p>
          {accept && <span>{t('supportedFormats')}: {formats}</span>}
        </DefaultUploadChildren>
      )}
    </StyledUpload>
  );
}

Upload.propTypes = {
  accept: PropTypes.string,
  image: PropTypes.any
}

Upload.defaultProps = {
  accept: '.jpeg,.jpg,.png'
}

export default Upload;
