import React, {useMemo, useState} from 'react';
import {Form, Space} from 'antd';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {
  StyledLegalPersonDocuments,
  StyledLegalPersonDocumentsSpace,
  StyledLegalPersonDocumentsInformation,
  StyledLegalPersonDocumentsImageCard,
  StyledLegalPersonDocumentsUpload
} from './StyledLegalPersonDocuments';
import SpinSmall from '../../../../../SpinSmall';
import {StyledKYCModalContentStepTitle} from '../../../../KYCModal/StyledKYCModal';
import DocumentUploadRules from '../../stepsComponents/DocumentUploadRules';
import docExampleImage from '../../../../../../static/images/pages/kyc/document-example.png';
import {kycHelpers} from '../../../../../../utils/helpers';

const {Item} = Form;

const initialFormValues = {
  document: undefined
}

const acceptFileTypes = kycHelpers.getAcceptFileTypes();

const LegalPersonDocuments = React.forwardRef(({
  onChange,
  onSubmit,
  ...rest
}, ref) => {
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation(['main', 'quickStart']);
  const [form] = Form.useForm();
  const [document, setDocument] = useState(null);

  const rule = (key) => t(`quickStart:kyc.documentUploadRules.${key}`);

  const documentUploadRules = [
    {label: rule('issuingDateNotOlderThan3Months'), variant: 'success'},
    {label: rule('originalFullSizedUnedited'), variant: 'success'},
    {label: rule('notCutBlurryOrReflective'), variant: 'error'},
    {label: rule('notEditedOrExpiredDocument'), variant: 'error'}
  ];

  const {
    description,
    placeholder,
    validationRequiredRules,
    title
  } = useMemo(() => kycHelpers.getKYCFormDetails({t, formKey: 'lpDocuments'}), [t]);

  const onFinish = () => {
    if (onSubmit) {
      setLoading(true);
      onSubmit(
        document,
        () => setLoading(false),
        (response) => {
          const errors = kycHelpers.getFileFormErrors({name: 'document', response, t});
          setLoading(false);
          form.setFields(errors);
        }
      );
    }
  }

  const handleOnChange = ({name, files}) => {
    const value = files.length > 0 ? files[0] : undefined;
    setDocument(value);
    form.setFieldValue(name, value);
    onChange && onChange(value);
  }

  return (
    <StyledLegalPersonDocuments {...rest}>
      <SpinSmall spinning={loading}>
        <StyledLegalPersonDocumentsSpace
          direction='horizontal'
          size={32}
        >
          <StyledLegalPersonDocumentsInformation
            direction='vertical'
            size='middle'
          >
            <StyledKYCModalContentStepTitle>
              {title}
            </StyledKYCModalContentStepTitle>
            <p className='description'>
              {description}
            </p>
            <Space
              direction='vertical'
              size='large'
              style={{width: '100%'}}
            >
              <DocumentUploadRules rules={documentUploadRules} />
              <StyledLegalPersonDocumentsImageCard title={placeholder('image')}>
                <img src={docExampleImage} alt='document example' />
              </StyledLegalPersonDocumentsImageCard>
            </Space>
          </StyledLegalPersonDocumentsInformation>
          <Form
            initialValues={initialFormValues}
            form={form}
            layout='vertical'
            onFinish={onFinish}
            ref={ref}
            requiredMark={false}
          >
            <Item
              name='document'
              rules={validationRequiredRules}
            >
              <StyledLegalPersonDocumentsUpload
                onChange={(files) => handleOnChange({name: 'document', files})}
                uploadProps={{accept: acceptFileTypes}}
              />
            </Item>
          </Form>
        </StyledLegalPersonDocumentsSpace>
      </SpinSmall>
    </StyledLegalPersonDocuments>
  );
});

LegalPersonDocuments.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func
}

export default LegalPersonDocuments;
