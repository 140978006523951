import styled from 'styled-components';
import {Space} from 'antd';
import {typographyStyles} from '../../utils/styles';

const StyledEditableAvatar = styled.div`
  display: flex;
  flex-direction: column;
  .editable-avatar-error-message {
    ${typographyStyles.body.regular};
    color: ${props => props.theme.colors.functional_danger} !important;
    display: block;
    margin-top: 8px;
  }
`;

const StyledActionsSpace = styled(Space)`
  .ant-btn {
    align-items: center;

    height: 22px;
    display: flex;
    padding: 2px;

    svg {
      margin-left: 4px;

      path {
        fill: rgba(0, 0, 0, 0.25);
        transition: .2s ease;
      }
    }

    &:not(:disabled):not(.ant-btn-disabled) {
      color: ${props => props.theme.colors.neutral_8};

      svg {
        path {
          fill: ${props => props.theme.colors.neutral_8};
        }
      }

      &:hover,&:active,&:focus {
        color: ${props => props.theme.colors.primary_6};

        svg {
          path {
            fill: ${props => props.theme.colors.primary_6};
          }
        }
      }
    }
  }
`;

export {
  StyledEditableAvatar,
  StyledActionsSpace
};
