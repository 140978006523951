import React from 'react';
import {Avatar, Space} from 'antd';
import PropTypes from 'prop-types';
import {
  StyledUserDetailsEmail,
  StyledUserDetailsName
} from './StyledUserDetails';
import {objectHelpers, textHelpers} from '../../utils/helpers';

const gObjProp = objectHelpers.getObjProp;

const UserDetails = ({
  email,
  user,
  ...rest
}) => {

  const userEmail = gObjProp(user, 'email');
  const name = gObjProp(user, 'full_name');
  const src = gObjProp(user, 'logo') || undefined;
  const initials = textHelpers.getInitials(name || userEmail);

  return (
    <Space
      size='small'
      {...rest}
    >
      <Avatar src={src}>
        {initials}
      </Avatar>
      <Space
        size={0}
        direction='vertical'
      >
        {name && <StyledUserDetailsName>{name}</StyledUserDetailsName>}
        {email && <StyledUserDetailsEmail>{userEmail}</StyledUserDetailsEmail>}
      </Space>
    </Space>
  );
}

UserDetails.propTypes = {
  email: PropTypes.bool,
  user: PropTypes.shape({
    email: PropTypes.string,
    full_name: PropTypes.string,
    logo: PropTypes.string,
  })
}

UserDetails.defaultProps = {
  email: true
}

export default UserDetails;
