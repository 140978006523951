import styled from 'styled-components';
import {Space} from 'antd';
import {typographyStyles} from "../../../../../../utils/styles";

const StyledHistoryDetails = styled(Space)`
  & > .ant-space-item {
    width: 100%;
    
    button {
      margin: auto;
    }
  }
`;

const StyledHistoryButtonContainer = styled.div`
  min-height: 40px;
  text-align: center;
`;

const StyledHistoryMessage = styled(Space)`
  ${typographyStyles.body.regular};
`;

export {
  StyledHistoryDetails,
  StyledHistoryButtonContainer,
  StyledHistoryMessage
}
