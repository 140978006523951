import styled, {css} from 'styled-components';
import {Button} from 'antd';

const editButtonStyles = css`
  align-items: center;
  display: flex;
  justify-content: center;
  
  svg {
    height: 24px;
    width: 24px;
    path {
      fill: ${props => props.theme.colors.neutral_10};
      transition: .2s ease;
    }
  }
  
  
  &:not(:disabled):not(.ant-btn-disabled):hover {
    &:hover {
      svg {
        path {
          fill: ${props => props.theme.colors.primary_8};
        }
      }
    }
  }
`;

const StyledEditButton = styled(Button)`
  ${editButtonStyles};
`;

export {
  editButtonStyles,
  StyledEditButton
};
