import styled from 'styled-components';
import Card from '../../../../Card';
import CopyField from '../../../../CopyField';
import {bordersStyles} from '../../../../../utils/styles';

const StyledInvoiceRetrieval = styled(Card)`
  .ant-card-body {
    & > p {
      color: ${props => props.theme.colors.neutral_8};
    }
  }
  .ant-card-head {
    padding: 0 24px;
    flex-direction: unset;
  }
`;

const StyledCopyField = styled(CopyField)`
  ${bordersStyles.default};
`;

export {
  StyledInvoiceRetrieval,
  StyledCopyField
};
