import styled from 'styled-components';
import {typographyStyles} from '../../utils/styles';

const StyledHeaderInformation = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    ${typographyStyles.heading.h4};
    margin-bottom: 2px;
  }

  p {
    ${typographyStyles.body.regular};
    color: ${props => props.theme.colors.neutral_7};
    margin-bottom: 12px;
  }
  
  a {
    align-items: center;
    display: flex;
    justify-content: center;
    
    svg {
      width: 12px;
      height: 12px;
      path {
        fill: ${props => props.theme.colors.neutral_6};
        transition: .2s ease;
      }
    }
    
    &:hover,&:active,&:focus {
      svg {
        path {
          fill: ${props => props.theme.colors.primary_5};
        }
      }
    }
  }
`;

export {
  StyledHeaderInformation
};
