import styled from 'styled-components';
import {typographyStyles} from '../../../../utils/styles';

const getBackgroundColor = (props) => {
  const {variant} = props;
  const {colors} = props.theme;
  const actionColors = {
    'default': colors.status_grey,
    'error': colors.functional_danger,
    'success': colors.status_green,
  }
  return actionColors[variant] || 'unset';
}


const StyledCounter = styled.div`
  ${typographyStyles.body.regular};
  
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => getBackgroundColor(props)};
  border: none;
  border-radius: ${props => props.theme.border_radius.standard};
  color: ${props => props.theme.colors.neutral_10};
  min-width: 35px;
  padding: 4px 8px;
`;


export {
  StyledCounter,
}