import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {StyledTableContainer} from './StyledUsersTable';
import Usage from '../../../Usage';
import Table from '../../../../../Table';
import UserDetails from '../../../../../UserDetails';
import {dateHelpers} from '../../../../../../utils/helpers';

const UsersTable = ({data, onOrderChange, ...rest}) => {
  const [t] = useTranslation(['main', 'subscriptions']);

  const trans = (key) => t(`subscriptions:${key}`);

  const handleTableChange = (pagination, filters, sorter) => {
    const {columnKey, order} = sorter;
    onOrderChange && onOrderChange(columnKey, order ? order === 'ascend' ? 'asc' : 'desc' : undefined);
  }

  const columns = [
    {
      key: 'user_id',
      dataIndex: 'user_id',
      title: t('user'),
      render: (_, record) => {
        const user = {
          full_name: record.full_name || record.email,
          logo: record.logo
        };
        return (
          <UserDetails user={user} />
        )
      }
    },
    {
      key: 'role',
      dataIndex: 'role',
      title: t('role'),
    },
    {
      key: 'usage',
      dataIndex: 'usage',
      title: trans('usage'),
      render: (usage) => <Usage variant={usage ? usage.toLowerCase() : undefined} />
    },
    {
      key: 'last_login',
      dataIndex: 'last_login',
      title: trans('lastLogIn'),
      render: (date) => dateHelpers.getDateWithGMTFormat(date, 'D MMM YYYY'),
      sorter: true
    }
  ];

  return (
    <StyledTableContainer {...rest}>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        onChange={handleTableChange}
        rowKey='user_id'
      />
    </StyledTableContainer>
  );
}

UsersTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string.isRequired,
      full_name: PropTypes.string,
      last_login: PropTypes.string,
      logo: PropTypes.string,
      usage: PropTypes.string.isRequired
    })
  ),
  onOrderChange: PropTypes.func
}

UsersTable.defaultProps = {
  data: []
}

export default UsersTable;

