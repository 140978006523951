import {css} from 'styled-components';

export const bordersStyles = {
  default: css`
    border: 1px solid ${props => props.theme.colors.neutral_4};
    border-radius: ${props => props.theme.border_radius.standard};
  `,
  light: css`
    border: 1px solid ${props => props.theme.colors.neutral_3};
    border-radius: ${props => props.theme.border_radius.standard};
  `,
}