import React from 'react';
import PropTypes from 'prop-types';
import {StyledDetailsTable} from './StyledDetailsTable';
import {elementHelpers} from '../../../../utils/helpers';

const DetailsTable = ({
  data,
  ...rest
}) => {

  return (
    <StyledDetailsTable {...rest}>
      <tbody>
        {data.map(({className, direction, key, label, value, ...rest}) => {
          const cName = elementHelpers.getClassName(direction ? direction : 'horizontal', className);
          return (
            <tr className={cName} key={key}  {...rest}>
              <td>{label}</td>
              <td>
                {value}
              </td>
            </tr>
          )
        })}
      </tbody>
    </StyledDetailsTable>
  );
}

DetailsTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      direction: PropTypes.oneOf(['horizontal', 'vertical']),
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.any
    })
  )
}

DetailsTable.defaultProps = {
  data: []
}

export default DetailsTable;
