import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import KYCModalScreen from '../../../KYCModalScreen';
import UserInformationForm from '../../substeps/UserInformationForm';
import {cardsHelpers, kycHelpers, objectHelpers} from '../../../../../../utils/helpers';
import {alertActions, bankingActions, transactionActions} from '../../../../../../state/actions';
import {bankingUserTypesConstants} from '../../../../../../constants';

const {LEGAL_PERSON, EMPLOYEE} = bankingUserTypesConstants;

const YourInformation = ({
  dispatch,

  createWallet,
  createUser,
  createTaxResidence,
  getCompanyState,
  getUsers,
  storeWireDetails,

  prepopulatedFormFields,
  onFinish,
  ...rest
}) => {
  const formRef = useRef(null);
  const [lpUser, setLpUser] = useState(null);
  const [t] = useTranslation(['main', 'subscriptions']);

  useEffect(() => {
    getUsers(
      {user_type: LEGAL_PERSON},
      (users) => users.length > 0 && setLpUser(users[0])
    );
  }, [getUsers]);

  const getLpUserProp = (key) => objectHelpers.getObjProp(lpUser, key);

  const handleSubmit = (fields, successCallback, errorCallback) => {
    const data = {
      ...fields,
      address1: getLpUserProp('address1'),
      city: getLpUserProp('city'),
      country: getLpUserProp('country'),
      postcode: getLpUserProp('postcode'),
      birthday: fields.birthday.format('YYYY-MM-DD'),
      user_type: EMPLOYEE
    }

    const successFunc = (user) => {
      createTaxResidence(
        {
          ...kycHelpers.getTaxResidenceFormData(data),
          user_id: user.id
        },
        () => {
          getCompanyState();
          createWallet(
            {user_id: lpUser.id},
            (wallet) => {
              storeWireDetails(wallet);
              successCallback && successCallback();
              onFinish && onFinish();
            },
            () => {
              alertActions.error('subscriptions:errors.walletCreationError');
              errorCallback && errorCallback();
            }
          );
        },
        (response) => {
          const formErrors = kycHelpers.getTaxNumberFormErrors(response);
          errorCallback && errorCallback(formErrors, response);
        }
      );
    }

    const errorFunc = (response) => {
      let formErrors = cardsHelpers.getBankingResponseErrorFields(response, t);
      errorCallback && errorCallback(formErrors);
    }

    createUser(
      data,
      successFunc,
      errorFunc
    );
  }

  const onOk = () => formRef.current.submit();

  return (
    <KYCModalScreen
      enabledCancel={false}
      onOk={onOk}
      {...rest}
    >
      <UserInformationForm
        className='column-form-content'
        defaultFormValues={prepopulatedFormFields}
        onSubmit={handleSubmit}
        ref={formRef}
      />
    </KYCModalScreen>
  );
}

YourInformation.propTypes = {
  prepopulatedFormFields: PropTypes.object,
  onFinish: PropTypes.func
}

const mapDispatchToProps = {
  createWallet: bankingActions.createWallet,
  createUser: bankingActions.createUser,
  createTaxResidence: bankingActions.createTaxResidence,
  getCompanyState: bankingActions.getCompanyState,
  getUsers: bankingActions.getCardsUsers,
  storeWireDetails: transactionActions.storeWireDetails
}

export default connect(null, mapDispatchToProps)(YourInformation);
