import styled from 'styled-components';
import {Button, Space} from 'antd';
import Card from '../../../../Card';
import {bordersStyles, typographyStyles} from '../../../../../utils/styles';

const StyledBillingDetailsInformation = styled(Space)`
  width: 100%;
  & > div {
    & > div {
      width: 100%;
    }
  }
`;

const StyledBillingDetailsInformationHeader = styled(Space)`
  ${bordersStyles.default};
  ${typographyStyles.body.regular};
  background-color: #fff;
  padding: 16px 24px;
  .billing-payment-title {
    color: ${props => props.theme.colors.neutral_6};
  }
  .billing-payment-value {
    color: ${props => props.theme.colors.neutral_10};
    display: inline-block;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 4px;
  }
  .billing-payment-due-date {
    color: ${props => props.theme.colors.neutral_8};
  }
`;

const StyledBillingDetailsInformationCashback = styled(Card)`
  .ant-card-body {
    padding: 24px 0;
    & > p {
      border-bottom: 1px solid ${props => props.theme.colors.neutral_4};
      color: ${props => props.theme.colors.neutral_8};
      line-height: 22px;
      font-size: 14px;
      padding: 0 24px 24px 24px;
    }
  }
`;

const StyledBillingDetailsInformationCashbackPrices = styled.div`
  padding: 0 24px;
`;

const StyledBillingDetailsInformationCashbackPricesItem = styled.div`
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  &:last-of-type {
    padding-bottom: 0;
  }
  
  .cashback-title {
    color: ${props => props.theme.colors.neutral_6};
  }
  .cashback-value {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    color: ${props => props.theme.colors.neutral_10};
  }
`;

const StyledBillingDetailsInformationCashbackPricesButton = styled(Button)`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.primary_6};
  border-color: ${props => props.theme.colors.primary_5};
  
  svg {
    margin-left: 8px;
    path {
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      
      &:nth-of-type(1),
      &:nth-of-type(3),
      &:nth-of-type(4),
      &:nth-of-type(5) {
        fill: ${props => props.theme.colors.primary_4};
      }
      &:nth-of-type(2) {
        fill: ${props => props.theme.colors.primary_3};
      }
      &:nth-of-type(6) {
        fill: #fff;
      }
    }
  }
  
  &:hover:not(:disabled),
  &:active:not(:disabled),
  &:focus:not(:disabled) {
    path {
      &:nth-of-type(1),
      &:nth-of-type(3),
      &:nth-of-type(4),
      &:nth-of-type(5) {
        fill: ${props => props.theme.colors.primary_5};
      }
      &:nth-of-type(2) {
        fill: ${props => props.theme.colors.primary_4};
      }
      &:nth-of-type(6) {
        fill: #fff;
      }
    }
  }
  
  &:disabled {
    svg {
      path {
        &:nth-of-type(1),
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5) {
          fill: ${props => props.theme.colors.neutral_5};
        }
        &:nth-of-type(2) {
          fill: transparent;
        }
      }
    }
  }
`;


export {
  StyledBillingDetailsInformation,
  StyledBillingDetailsInformationHeader,
  StyledBillingDetailsInformationCashback,
  StyledBillingDetailsInformationCashbackPrices,
  StyledBillingDetailsInformationCashbackPricesItem,
  StyledBillingDetailsInformationCashbackPricesButton
};
