import styled from 'styled-components';

const StyledTeamPageContainer = styled.div`
  display: flex;
  padding: 24px;
  justify-content: center;
  overflow-x: hidden;
  
  & > div {
    width: 100%;
  }
`;

export {
  StyledTeamPageContainer
}