import React  from 'react';
import {useTranslation} from 'react-i18next';
import {
  StyledCardBanner,
  StyledCardBannerDescription,
  StyledCardBannerTitle
} from './StyledCardBanner';
import background from '../../../../static/images/pages/cards/card-banner-device.svg';
import {elementHelpers} from '../../../../utils/helpers';


const CardBanner = ({
  className,
  ...rest
}) => {
  const [trans] = useTranslation(['main', 'cards']);
  const elementClassName = elementHelpers.getClassName('card-banner', className);
  const t = (key) => trans(`cards:cardBanner.${key}`);

  return (
    <StyledCardBanner
      {...rest}
      background={background}
      className={elementClassName}
    >
      <StyledCardBannerTitle>
        {t('title')}
        <br/>
        {trans('to')} <span className='fw-500'>Apple Pay</span> {trans('or')} <span className='fw-500'>Google Wallet</span>
      </StyledCardBannerTitle>
      <StyledCardBannerDescription>
        {t('description1')}
        <br/>
        {t('description2')}
      </StyledCardBannerDescription>
    </StyledCardBanner>
  );
}

export default CardBanner;
