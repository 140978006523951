import styled from 'styled-components';
import {Card, Space} from 'antd';
import {typographyStyles} from '../../../../utils/styles';

const getBadgeColor = (props) => {
  const {action} = props;
  const {colors} = props.theme;
  const actionColors = {
    'activateAccount': colors.functional_warning,
    'bankingInfo': colors.functional_warning,
    'continue': colors.primary_6,
    'error': colors.functional_danger
  }
  return actionColors[action] || props.neutral_4;
}

const StyledCompleteKycCard = styled(Card)`
  .ant-card-head {
    ${typographyStyles.heading.h5};
    color: ${props => props.theme.colors.neutral_10};
  }
  .ant-card-body {
    ${typographyStyles.body.regular};
    color: ${props => props.theme.colors.neutral_8};
  }
`;

const StyledCompleteKycCardTitleSpace = styled(Space)`
  width: 100%;
  justify-content: space-between;
`;

const StyledCompleteKycCardBadge = styled.span`
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: ${props => getBadgeColor(props)};
  border-radius: 50%;
`;

export {
  StyledCompleteKycCard,
  StyledCompleteKycCardTitleSpace,
  StyledCompleteKycCardBadge
}