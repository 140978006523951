import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import { Button, theme} from 'antd';
import {userActions} from '../../state/actions';
import {notificationTypesConstants} from '../../constants';
import {
  StyledNotifications,
  StyledNotificationEmpty,
  StyledNotificationsList
} from './StyledNotifications';
import Notification from './Notification';
import defaultAvatarLogo from '../../static/images/avatar-withless-logo.svg';
import routes from '../../routes/routes.json';
import {objectHelpers} from '../../utils/helpers';

const { useToken } = theme;

const getParamId = (params) => {
  const list = params.split('=');
  return list[list.length - 1];
}

const getNotificationInformation = (notificationItem) => {
  const {message, type, timestamp, id, is_read: isRead} = notificationItem;
  const actionParams = notificationItem.action_url_params;
  let notification = {timestamp, message, type, isRead};
  let actionLink;
  switch (type) {
    case notificationTypesConstants.ADD_USERS:
      actionLink = `${routes.team}?add-user=1`;
      break;
    case notificationTypesConstants.MANAGE_EXPENSES:
    case notificationTypesConstants.EXPENSE_REJECTED:
    case notificationTypesConstants.EXPENSE_PAID:
    case notificationTypesConstants.EXPENSE_SCHEDULED_PAID:
      actionLink = `${routes.expensesList}${actionParams}`;
      break;
    case notificationTypesConstants.EXPENSE_NEW_NOTE:
      actionLink = `${routes.expensesList}?id=${actionParams}`
      break;
    case notificationTypesConstants.MANAGER_REPORTS:
      actionLink = `${routes.reportsList}${actionParams}`;
      break;
    case notificationTypesConstants.REPORT_CLOSED:
      actionLink = `${routes.reportsList}/${actionParams}`;
      break;
    case notificationTypesConstants.SUBSCRIPTION_CARD_CREATE:
    case notificationTypesConstants.SUBSCRIPTION_CARD_BLOCKED:
    case notificationTypesConstants.SUBSCRIPTION_CARD_UNBLOCKED:
      actionLink = `${routes.subscriptionsList}/${getParamId(actionParams)}`;
      break;
    default:
      break;
  }

  notification = Object.assign({
    actionLink,
    avatar: {src: defaultAvatarLogo},
    id
  }, notification);

  return notification;
}

const Notifications = ({
  notifications,
  getNotificationList,
  readNotifications,
  readAllNotifications,
  ...rest
}) => {
  const [t] = useTranslation('main');
  const [data, setData] = useState([]);
  const [isEnabledLoadMore, setIsEnabledLoadMore] = useState(false);
  const navigate = useNavigate();
  const notificationListRef = useRef(null);

  const { token } = useToken();

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    boxShadow: token.boxShadowSecondary,
  };

  useEffect(() => {
    const {notifications: notificationsList, pagination} = notifications;
    const hasNextPage = objectHelpers.getObjProp(pagination, 'next_page', false);
    const data = notificationsList
      .map(n => getNotificationInformation(n))
      .sort((x, y) => y.timestamp - x.timestamp);
    setData(data);
    setIsEnabledLoadMore(hasNextPage);
  }, [notifications]);

  const handleReadAll = () => readAllNotifications();

  const handleOnNotificationClick = ({id, isRead, actionLink}) => {
    if (id && !isRead) readNotifications([id], () => getNotificationList()); // mark notification as read
    if (actionLink) navigate(actionLink);
  }

  const handleOnScroll = () => {
    if (notificationListRef.current && isEnabledLoadMore) {
      const { scrollTop, scrollHeight, clientHeight } = notificationListRef.current;
      if (scrollTop + clientHeight === scrollHeight) getNotificationList(true);
    }
  }

  return (
    <StyledNotifications
      style={contentStyle}
      {...rest}
    >
      <header className='d-flex align-items-center justify-space-between'>
        {t('notifications')}
        <Button
          onClick={handleReadAll}
          type='text'
        >
          {t('markAllAsRead')}
        </Button>
      </header>
      <StyledNotificationsList
        onScroll={handleOnScroll}
        ref={notificationListRef}
      >
        {data.length === 0 && (
          <StyledNotificationEmpty
            description={false}
          />
        )}
        {data.map((item, i) => (
          <Notification
            {...item}
            data-test='notification'
            key={i}
            onClick={() => handleOnNotificationClick(item)}
          />
        ))}
      </StyledNotificationsList>
    </StyledNotifications>
  );
}

const mapStateToProps = state => {
  const {notifications} = state.user;

  return {
    notifications
  }
}

const mapDispatchToProps = {
  getNotificationList: userActions.getNotificationList,
  readNotifications: userActions.readNotifications,
  readAllNotifications: userActions.readAllNotifications
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
