import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Button, Checkbox, Dropdown, Input, Space} from 'antd';
import {DownOutlined} from '@ant-design/icons';
import Paragraph from 'antd/es/typography/Paragraph';
import {
  StyledTagSelect,
  StyledMenu,
  StyledMenuItem,
  DropdownContent,
  DropdownContainer,
  SearchContainer,
  StyledTag,
  TagsList
} from './StyledTagSelect';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import './TagSelect.css';
import {objectHelpers} from '../../utils/helpers';

const defaultInputProps = {
  allowClear: true
}

const TagSelect = ({
  defaultSelected,
  emptyText,
  isEnableAdd,
  inputProps,
  options,
  onAdd,
  onChange,
  selected,
  ...rest
}) => {
  const [t] = useTranslation('main');
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [searchedOptions, setSearchedOptions] = useState(options);
  const [searchedValue, setSearchedValue] = useState('');
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    if (!objectHelpers.arraysIsEqual(selectedValues, selected)) setSelectedValues(selected);
  }, [selected]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSearchedOptions(options);
    if (searchedValue) setSearchedValue('');
  }, [options]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isOpenDropdown && isUpdated && onChange) {
      setIsUpdated(false);
      onChange && onChange(selectedValues);
    }
  }, [isOpenDropdown]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOpenDropdown = () => !isOpenDropdown && setIsOpenDropdown(true);

  const handleMenuClick = ({key, domEvent}) => {
    let values = selectedValues.includes(key) ? selectedValues.filter(i => i !== key) : [...selectedValues, key];
    setSelectedValues(values);
    setIsUpdated(true);
    domEvent.preventDefault();
    domEvent.stopPropagation();
  }

  const onClickOutside = (e) => {
    const parentSelector = '.ant-dropdown';
    if (!e.target.closest(parentSelector)) setIsOpenDropdown(false);
  }

  const getTagLabel = (value) => {
    let option = options.find(o => o.value === value);
    return option?.value || value;
  }

  const onSearch = (e) => {
    let {value} = e.target;
    setSearchedValue(value);

    value = value.toLowerCase();
    setSearchedOptions(options.filter(o => o.label.toLowerCase().indexOf(value) >= 0));
  }

  const handleRemoveTag = (value, key) => {
    const values = selectedValues.filter((s, index) => index !== key && s !== value);
    setSelectedValues(values);
    onChange && onChange(values);
  }

  const handleAdd = () => {
    if (searchedValue) {
      const values = [...selectedValues, searchedValue];
      setSelectedValues(values);
      setSearchedValue('');
      setSearchedOptions([...options, {label: searchedValue, value: searchedValue}]);
      onChange && onChange(values);
      onAdd && onAdd(searchedValue);
    }
  }

  const searchedOptionsLength = searchedOptions.length;

  const isEnableAdding = searchedValue !== '' && searchedOptionsLength === 0;

  const searchComponent = (
    <Input
      allowClear={true}
      size='large'
      placeholder={t('search')}
      onChange={onSearch}
      value={searchedValue}
    />
  );

  return (
    <StyledTagSelect
      {...rest}
    >
      <DropdownContainer
        ref={useOnClickOutside(onClickOutside, isOpenDropdown, null, true)}
      >
        <Dropdown
          overlayClassName='tag-select-overlay'
          dropdownRender={() => (
            <DropdownContent>
              <SearchContainer className='search'>
                {isEnableAdd ? (
                  <Space.Compact>
                    {searchComponent}
                    <Button
                      disabled={!isEnableAdding}
                      onClick={() => isEnableAdding && handleAdd()}
                      size='large'
                    >
                      {t('add')}
                    </Button>
                  </Space.Compact>
                ) : searchComponent}
              </SearchContainer>
              <div className='dropdown-searched-content'>
                {searchedOptionsLength > 0 && (
                  <StyledMenu
                    multiple={true}
                    onClick={handleMenuClick}
                    selectedKeys={selectedValues}
                  >
                    {searchedOptions.map(item => {
                      const {label, value} = item;
                      return (
                        <StyledMenuItem
                          key={value}
                        >
                          <Checkbox checked={selectedValues.includes(value)}>
                            {label}
                          </Checkbox>
                        </StyledMenuItem>
                      );
                    })}
                  </StyledMenu>
                )}

                {searchedOptionsLength === 0 && searchedOptionsLength !== options.length && (
                  <Paragraph>
                    {emptyText}
                  </Paragraph>
                )}
              </div>
            </DropdownContent>
          )}
          open={isOpenDropdown}
          placement='bottomLeft'
        >
          <Input
            {...defaultInputProps}
            {...inputProps}
            onClick={handleOpenDropdown}
            suffix={(
              <DownOutlined
                onClick={handleOpenDropdown}
                style={{ color: 'rgba(0,0,0,.45)', width: 12, height: 12 }}
              />
            )}
          />
        </Dropdown>
      </DropdownContainer>

      <TagsList>
        {selectedValues.map((s, key) => (
          <StyledTag
            key={key}
            onClose={() => handleRemoveTag(s, key)}
            closable
          >
            {getTagLabel(s)}
          </StyledTag>
        ))}
      </TagsList>
    </StyledTagSelect>
  );
};

TagSelect.propTypes = {
  defaultSelected: PropTypes.array,
  emptyText: PropTypes.string,
  inputProps: PropTypes.object,
  isEnableAdd: PropTypes.bool,
  onAdd: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  selected: PropTypes.array,
};

TagSelect.defaultProps = {
  defaultSelected: [],
  emptyText: 'No results found',
  isEnableAdd: false,
  options: [],
  selected: []
}

export default TagSelect;
