import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input} from 'antd';
import {useTranslation} from 'react-i18next';
import {StyledCompleteProfileForm, StyledButton} from './StyledCompleteProfileForm';
import {formHelpers, objectHelpers} from '../../../../utils/helpers';
import {EmailRegexp, NameRegexp} from '../../../../utils/regexp';

const {Item} = Form;

const initialFormValues = {
  first_name: '',
  email: '',
  last_name: '',
}

const CompleteProfileForm = ({
  buttonText,
  disabled,
  loading,
  initialValues,
  emailProps,
  onSubmit,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'auth']);
  const [form] = Form.useForm();

  const mainValidationT = (key, options) => t(`validation.${key}`, options);

  const handleOnSubmit = (fields) => {
    onSubmit && onSubmit(
      objectHelpers.trimAllValues(fields),
      null,
      (err) => {
        if (err.hasOwnProperty('message')) {
          form.setFields([{name: 'email', errors: [err.message]}]);
        } else if (err.hasOwnProperty('errors')) {
          const fields = formHelpers.getFormServerErrorFields(err);
          form.setFields(fields);
        }
      }
    );
  }

  const handleSubmitForm = () => form.submit();

  const handleKeyUp = (e) => e.key.toLowerCase() === 'enter' && handleSubmitForm();

  const getRequiredRules = (key) => {
    return [
      {required: true, message: mainValidationT('pleaseEnterYour', {name: key.toLowerCase()})}
    ]
  }

  const getNameRules = (key) => {
    return [
      ...getRequiredRules(key),
      {pattern: NameRegexp, message: mainValidationT('enterValidName')}
    ]
  }

  return (
    <StyledCompleteProfileForm
      initialValues={initialValues}
      onKeyUp={handleKeyUp}
      {...rest}
      form={form}
      onFinish={handleOnSubmit}
    >
      <Item
        name='first_name'
        rules={getNameRules(t('firstName'))}
        required
      >
        <Input
          disabled={disabled}
          maxLength={50}
          placeholder={t('firstName')}
          size='large'
        />
      </Item>
      <Item
        name='last_name'
        rules={getNameRules(t('lastName'))}
        required
      >
        <Input
          disabled={disabled}
          maxLength={50}
          placeholder={t('lastName')}
          size='large'
        />
      </Item>
      <Item
        name='email'
        rules={[
          ...getRequiredRules(t('email')),
          {pattern: EmailRegexp, message: mainValidationT('enterValidEmail')}
        ]}
        required
      >
        <Input
          {...emailProps}
          disabled={emailProps.disabled || disabled}
          type='email'
          placeholder={t('email')}
          size='large'
        />
      </Item>

      <StyledButton
        loading={loading}
        disabled={loading ? false : disabled}
        onClick={handleSubmitForm}
        size='large'
        type='primary'
      >
        {buttonText}
      </StyledButton>

    </StyledCompleteProfileForm>
  );
}

CompleteProfileForm.propTypes = {
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
  emailProps: PropTypes.object,
  initialValues: PropTypes.shape({
    first_name: PropTypes.string,
    email: PropTypes.string,
    last_name: PropTypes.string
  }),
  loading: PropTypes.bool
}

CompleteProfileForm.defaultProps = {
  disabled: false,
  loading: false,
  initialValues: initialFormValues
}

export default CompleteProfileForm;
