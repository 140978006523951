import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Avatar, Button, Space} from 'antd';
import {EditFilled, PlusOutlined} from '@ant-design/icons';
import moment from 'moment';
import {StyledInvoiceDetails, StyledCard, StyledInvoiceDetailsHeaderSpace, StyledTag,
  StyledAttachmentPreview} from './StyledInvoiceDetails';
import {StyledInvoiceDetailsDownloadButton} from '../../../TransactionsPage/InvoiceDetails/StyledInvoiceDetails';
import ImagePreviewTooltip from '../../../TransactionsPage/ImagePreviewTooltip';
import InvoiceAttachmentPreview from '../../../TransactionsPage/InvoiceAttachmentPreview';
import InvoiceDetailsForm from '../InvoiceDetailsForm';
import {DownloadIcon} from '../../../../../icons';
import {invoiceStatusConstants, invoiceMatchTypeConstants} from '../../../../../constants';
import {amountHelpers, objectHelpers, textHelpers} from '../../../../../utils/helpers';

const {MATCHED} = invoiceStatusConstants;
const {AUTOMATED, MANUAL} = invoiceMatchTypeConstants;

const InvoiceDetails = ({
  invoice,
  onAdd,
  onDownload,
  onEdit,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'invoices']);

  const gObjProp = (key, defaultValue) => objectHelpers.getObjProp(invoice, key, defaultValue);

  const amount = gObjProp('amount');
  const createdDate = gObjProp('created_at');
  const currencyCode = gObjProp('currency');
  const matchType = gObjProp('match_type');
  const status = gObjProp('status');
  const vendor = gObjProp('vendor');
  const vendorInitials = textHelpers.getInitials(vendor);
  const price = amountHelpers.getAmountWithCurrencyCode(amount, undefined, currencyCode);

  const matched = status === MATCHED;

  const statusT = (key) => t(`invoices:statuses.${key}`);

  const getStatusTag = () => {
    let label = 'unmatched';
    if (status === MATCHED) {
      if (matchType === AUTOMATED) {
        label = 'automatically';
      } else if (matchType === MANUAL) {
        label = 'manually';
      }
    }
    return label;
  }

  const transactionExtra = matched && (
    <Button
      icon={<EditFilled />}
      onClick={onAdd}
      size='small'
      type='text'
    />
  );

  const statusTag = getStatusTag();

  const attachment = {
    file: gObjProp('file_url'),
    name: gObjProp('file_name')
  }

  const invoiceAttachment = (
    <InvoiceAttachmentPreview
      attachment={attachment}
    />
  );

  const downloadButton = (
    <StyledInvoiceDetailsDownloadButton
      onClick={(e) => onDownload && onDownload(e)}
      type='text'
    >
      <DownloadIcon /> {t('download')}
    </StyledInvoiceDetailsDownloadButton>
  );

  return (
    <StyledInvoiceDetails
      size='middle'
      direction='vertical'
      {...rest}
    >
      <StyledInvoiceDetailsHeaderSpace
        direction='vertical'
        size='middle'
      >
        <div className='d-flex justify-space-between align-center'>
          <StyledTag className={statusTag}>
            {statusT(statusTag)}
          </StyledTag>
          {createdDate && (
            <span className='date'>
              {moment(createdDate).format('DD MMM YYYY, HH:mm')}
            </span>
          )}
        </div>
        {matched && (
          <div className='d-flex justify-space-between align-center vendor-details'>
            <Space>
              <Avatar>
                {vendorInitials}
              </Avatar>
              {vendor}
            </Space>
            <span className='price'>
              {price}
            </span>
          </div>
        )}
        <StyledCard
          className={matched ? '' : 'body-center'}
          extra={downloadButton}
          title={t('invoice')}
          type='inner'
        >
          <ImagePreviewTooltip
            overlayClassName='image-preview-tooltip-overlay single'
            trigger='hover'
            title={invoiceAttachment}
          >
            <StyledAttachmentPreview>
              {invoiceAttachment}
            </StyledAttachmentPreview>
          </ImagePreviewTooltip>
        </StyledCard>
      </StyledInvoiceDetailsHeaderSpace>
      <StyledCard
        className={matched ? '' : 'body-center'}
        extra={transactionExtra}
        title={t('transaction')}
        type='inner'
      >
        {matched ? (
          <Space>
            <Avatar size='large'>
              {vendorInitials}
            </Avatar>
            <Space
              align='start'
              direction='vertical'
              size={2}
            >
              {vendor}
              <b>{price}</b>
            </Space>
          </Space>
        ) : (
          <Button
            icon={<PlusOutlined />}
            onClick={onAdd}
            type='text'
          >
            {t('add')}
          </Button>
        )}
      </StyledCard>
      <InvoiceDetailsForm
        onSubmit={onEdit}
        invoice={invoice}
      />
    </StyledInvoiceDetails>
  );
}

InvoiceDetails.propTypes = {
  invoice: PropTypes.shape({
    adding_date: PropTypes.string
  }),
  onAdd: PropTypes.func,
  onDownload: PropTypes.func,
  onEdit: PropTypes.func
}

export default InvoiceDetails;
