import styled from 'styled-components';
import {Space} from 'antd';

const DETAILS_PANEL_HEIGHT = 'calc(100vh - 66px - 24px * 2)';
const DETAILS_PANEL_WIDTH = '472px';

const StyledCardPageContainer = styled.div`
  overflow-x: hidden;
  padding: 24px 0 24px 24px;
`;

const StyledCardPageContainerSpace = styled(Space)`
  width: 100%;
  & > .ant-space-item {
    &:nth-of-type(1) {
      width: calc(100%);
    } 
    &:nth-of-type(2) {
      min-width: ${DETAILS_PANEL_WIDTH};
      width: ${DETAILS_PANEL_WIDTH};
    }
    & > div > .ant-card {
      height: calc(100vh - 66px - 48px);
      
      .ant-card-body {
        overflow: auto;
        max-height: calc(100% - 55px);
      }
      
      .ant-card-head {
        position: sticky;
        top: 0;
        z-index: 4;
      }
    }
  }
`;

const StyledCardPageTransactionContainer = styled.div`
  position: relative;
  
  & > div {
    position: absolute;
    right: 24px;
    
    & > .ant-card {
      height: ${DETAILS_PANEL_HEIGHT};
      
      .ant-card-body {
        overflow: hidden auto;
        max-height: calc(100% - 55px);
      }
    }
  }
  
  .transaction-details-panel-content {
    height: ${DETAILS_PANEL_HEIGHT};
    margin-left: 0;
    
    & > div {
      &:nth-of-type(2) {
        max-height: calc(100vh - (66px + 82px + 28px));
      }
    }
  }
  
`

export {
  StyledCardPageContainer,
  StyledCardPageContainerSpace,
  StyledCardPageTransactionContainer
};
