import React, {useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate} from 'react-router-dom';
import {SwitchTransition} from 'react-transition-group';
import {Button} from 'antd';
import {PageContainer, FadeTransition} from '../../components/pages/AuthPage/PageContainer';
import CodeAuthForm from '../../components/pages/AuthPage/CodeAuthForm';
import PhoneAuthForm from '../../components/pages/AuthPage/PhoneAuthForm';
import CreatePinForm from '../../components/pages/AuthPage/CreatePinForm';
import {StyledForgotPinPage, StyledPhoneAuthSpace, StyledSuccessSpace} from './StyledForgotPinPage';
import PageDocumentDetails from '../../components/PageDocumentDetails';
import {authActions} from '../../state/actions';
import routes from '../../routes/routes.json';
import {ReactComponent as Step1SvgImage} from '../../static/images/pages/forgot-pin/step-1.svg';
import {ReactComponent as Step2SvgImage} from '../../static/images/pages/forgot-pin/step-2.svg';
import {ReactComponent as Step3SvgImage} from '../../static/images/pages/forgot-pin/step-3.svg';
import {ReactComponent as SuccessSvgImage} from '../../static/images/success.svg';
import {systemHelpers} from '../../utils/helpers';
import {firebaseEvents} from '../../snippets/firebase';

const {logEvent} = systemHelpers;

const defaultFormProps = {
  loading: false,
  disabled: false
};

const ForgotPinPage = ({
  resetPassword,
  confirmPasswordResetting,
}) => {
  const navigate = useNavigate();
  const [trans] = useTranslation(['auth', 'main']);
  const [step, setStep] = useState(1);
  const [resettingData, setResettingData] = useState({
    phone_number: null,
    password: null,
    code: null
  });
  const [phoneAuthFormProps, setPhoneAuthFormProps] = useState(defaultFormProps);
  const [codeAuthFormProps, setCodeAuthFormProps] = useState({
    errors: null
  });
  const [pinFormProps, setPinFormProps] = useState(defaultFormProps);

  const t = (key) => trans(`forgotPin.${key}`);

  const mainT = (key, options) => trans(`main:${key}`, options);

  const increaseStep = () => setStep(step + 1);

  const getErrorMessage = (message) => ({message});

  const onPhoneAuthSuccess = (data, successCallback, errorCallback, isResend = false) => {
    setPhoneAuthFormProps({...phoneAuthFormProps, loading: true, disabled: true});
    resetPassword(
      data,
      (resp) => {
        successCallback && successCallback(resp);
        setPhoneAuthFormProps(defaultFormProps);
        if (!isResend) {
          setResettingData({
            ...resettingData,
            ...data
          });
          increaseStep();
          logEvent(firebaseEvents.FORGOT_PASSWORD_PHONE_NUMBER_ENTERED);
        }
      },
      () => {
        errorCallback && errorCallback(getErrorMessage('Sorry, we did not recognise this number'));
        setPhoneAuthFormProps(defaultFormProps);
      }
    );
  }

  const onCodeAuthSuccess = (data) => {
    // submit form is user already set a password
    if (codeAuthFormProps.errors) {
      submitResetPassword({...resettingData, code: data.code});
    } else {
      setResettingData({
        ...resettingData,
        code: data.code
      });
      increaseStep();
      logEvent(firebaseEvents.FORGOT_PASSWORD_CODE_ENTERED);
    }
  }

  const onCodeAuthBack = () => {
    setStep(step - 1);
    if (codeAuthFormProps.errors) setCodeAuthFormProps({...codeAuthFormProps, errors: null});
  }

  const handleSubmitCreatePin = ({password}) => {
    const data = {...resettingData, password};
    setResettingData(data);
    submitResetPassword(data);
  }

  const submitResetPassword = (data) => {
    setCodeAuthFormProps({...codeAuthFormProps, disabled: true});
    setPinFormProps({...pinFormProps, disabled: true, loading: true});
    confirmPasswordResetting(
      data,
      () => {
        setStep(4);
        setPinFormProps(defaultFormProps);
        logEvent(firebaseEvents.FORGOT_PASSWORD_PASSWORD_CREATED);
      },
      () => {
        const isCodeAuthStep = step === 2;
        const errors = getErrorMessage('The code is incorrect')
        setCodeAuthFormProps({...codeAuthFormProps, errors});
        setPinFormProps(defaultFormProps);
        !isCodeAuthStep && setStep(2);
      }
    )
  }

  const handleBackToLogin = () => navigate(routes.login);

  const onCodeAuthResend = () => onPhoneAuthSuccess({phone_number: resettingData.phone_number}, null, null, true);

  const stepImage = ({
    1: <Step1SvgImage />,
    2: <Step2SvgImage />,
    3: <Step3SvgImage />
  })[step] || <Step3SvgImage />;

  const formContent = ({
    1: (
      <>
        <h3>{t('forgotPin')}</h3>
        <p>{t('enterYourPhone')}</p>
        <StyledPhoneAuthSpace
          direction='vertical'
          size='large'
        >
          <PhoneAuthForm
            {...phoneAuthFormProps}
            buttonText={mainT('next')}
            initialValues={{phone_number: resettingData.phone_number}}
            onSubmit={onPhoneAuthSuccess}
            enablePassword={false}
          />
          <Button
            onClick={handleBackToLogin}
            size='large'
            type='text'
          >
            {mainT('backTo', {direction: mainT('login')})}
          </Button>
        </StyledPhoneAuthSpace>
      </>
    ),
    2: (
      <>
        <h3>{t('forgotPin')}</h3>
        <p>
          {t('enterThe6DigitCode')} <span className='bold'>{resettingData.phone_number}</span>
        </p>
        <CodeAuthForm
          {...codeAuthFormProps}
          autofocus
          onResend={onCodeAuthResend}
          onSuccess={onCodeAuthSuccess}
          onBack={onCodeAuthBack}
        />
      </>
    ),
    3: (
      <>
        <h3>{t('newPin')}</h3>
        <p>{t('enterYourNewPin')}</p>
        <CreatePinForm
          {...pinFormProps}
          buttonText={mainT('save')}
          onSubmit={handleSubmitCreatePin}
        />
      </>
    ),
    4: (
      <StyledSuccessSpace
        align='center'
        direction='vertical'
        size='middle'
      >
        <SuccessSvgImage />
        <h3>{mainT('success')}</h3>
        <p>{t('yourPinChanged')}</p>
        <Link to={routes.login}>
          <Button
            size='large'
            type='primary'
          >
            {mainT('backTo', {direction: mainT('signIn')})}
          </Button>
        </Link>
      </StyledSuccessSpace>
    )
  })[step] || null;

  const transitionProps = {
    key: step,
    mountOnEnter: true,
    timeout: 250,
    unmountOnExit: true,
  }

  return (
    <StyledForgotPinPage>
      <PageDocumentDetails title={mainT('pageTitles.forgotPin')} />
      <PageContainer
        imageContent={
          <SwitchTransition>
            <FadeTransition {...transitionProps}>
              {stepImage}
            </FadeTransition>
          </SwitchTransition>
        }
        formContent={
          <SwitchTransition>
            <FadeTransition {...transitionProps}>
              <div className='form-content'>
                {formContent}
              </div>
            </FadeTransition>
          </SwitchTransition>
        }
      />
    </StyledForgotPinPage>
  );
}

const mapDispatchToProps = {
  resetPassword: authActions.resetPassword,
  confirmPasswordResetting: authActions.confirmPasswordResetting,
}

export default connect(null, mapDispatchToProps)(ForgotPinPage);
