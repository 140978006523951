import styled from 'styled-components';
import {typographyStyles} from '../../../../utils/styles';

const StyledSubscriptionStatusMark = styled.div`
  ${typographyStyles.body.regular};
  align-items: center;
  color: ${props => props.theme.colors.neutral_10};
  display: flex;
  line-height: unset;
  
  & > *:first-of-type {
    margin-right: 6px;
  }
`;

export default StyledSubscriptionStatusMark;
