import React from 'react';
import TextLoader from '../../components/ContentLoaders/TextLoader';
import TableSettingsButton from '../../components/pages/SubscriptionsPage/TableSettingsButton';
import {objectHelpers} from './object.helpers';


export const elementHelpers = {
  getClassName: (cName, className) => {
    if (className) cName += ` ${className}`;
    return cName;
  },
  getElementOffsetTop: (elementId, parentsCount = 0) => {
    let element = document.querySelector(elementId);
    let offsetTop = element?.offsetTop || 0;
    if (parentsCount && parentsCount > 0) {
      for (let i = 0; i < parentsCount; i++) {
        if (element && element.offsetParent) {
          element = element.offsetParent;
          offsetTop += element.offsetTop;
        }
      }
    }
    return offsetTop;
  },
  getAvailableTableColumns: ({
    alwaysAvailableColumnKeys = [],
    columns = [],
    selectedColumns = []
  }) => {
    const columnKeys = [...selectedColumns, ...alwaysAvailableColumnKeys];
    return columns.filter(column => columnKeys.includes(column?.dataIndex));
  },
  getSettingsRightSideContent: ({
    alwaysAvailableColumnKeys = [],
    columns = [],
    onChange,
    selectedKeys,
    rightSideContent,
  }) => {
    columns = columns.filter(column => !alwaysAvailableColumnKeys.includes(column.dataIndex));
    return (
      <TableSettingsButton
        defaultSelectedKeys={selectedKeys}
        options={columns.map(c => ({value: c.dataIndex, label: c.title}))}
        onChange={onChange}
      >
        {rightSideContent}
      </TableSettingsButton>
    )
  },
  getVisibleClassName: (className = undefined, isVisible = false) => {
    let cName = 'form-step-container';
    if (className) cName += ` ${className}`;
    if (!isVisible) cName += ' form-d-none';
    return cName;
  },
  showContentWithLoader: (isEnable, content, loader = <TextLoader />) => isEnable ? content : loader,
  stopPropagation: (event) => event && event.stopPropagation(),
  updateMenuItems: ({location, items, selectedKeys, setSelectedKeys}) => {
    let item = items.find(el => el.path === location.pathname);
    let updatedSelectedKeys = item ? [item.key] : [];
    if (!objectHelpers.arraysIsEqual(updatedSelectedKeys, selectedKeys)) setSelectedKeys(updatedSelectedKeys);
  },
}
