import styled from 'styled-components';
import Spin from '../components/Spin';

const NAV_EXPAND_WIDTH = '236px';
const CONTAINER_EXPAND_WIDTH = `calc(100% - ${NAV_EXPAND_WIDTH})`;

const StyledApp = styled.div`
`;

const StyledNavContainer = styled.div`
  overflow-x: hidden;
  width: ${NAV_EXPAND_WIDTH};
  z-index: 4;
`;

const StyledContentContainer = styled.div`
  background-color: ${props => props.theme.colors.additional_bg_2};
  width: ${CONTAINER_EXPAND_WIDTH};
`;


const StyledSpin = styled(Spin)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  max-height: unset !important;
  
  .ant-spin-dot {
    margin: 0;
    position: relative;
    transform: translate(-50%, -50%);
    width: 36px;
    height: 36px;
    
    img {
      height: 36px;
      width: 36px;
    }
  }
  
  &.white {
    background-color: #fff;
  }
`;

export {
  StyledApp,
  StyledContentContainer,
  StyledNavContainer,
  StyledSpin
}