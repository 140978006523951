import React, {useMemo} from 'react';
import {Col} from 'antd';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  StyledSpendingDetails,
  StyledSpendingDetailsEmpty
} from './StyledSpendingDetails';
import {StyledOverviewPageCard} from '../../../../pages/OverviewPage/StyledOverviewPage';
import Counter from '../Counter';
import SpinSmall from '../../../SpinSmall';
import PieChart from '../../../Charts/PieChart';
import VerticalBarChart from '../../../Charts/VerticalBarChart';
import {colorsStyles} from '../../../../utils/styles';
import {ReactComponent as EmptyCategoriesImg} from '../../../../static/images/pages/overview/empty-categories.svg';
import {ReactComponent as EmptyCostCenterImg} from '../../../../static/images/pages/overview/empty-cost-center.svg';
import routes from '../../../../routes/routes.json';
import {amountHelpers} from '../../../../utils/helpers';

const getAmountWithCurrencyCode = (value, showFractionalPart) => amountHelpers.getAmountWithCurrencyCode(value, undefined, undefined, true, showFractionalPart);

const subscriptionsListRoute = routes.subscriptionsList;

const SpendingDetails = ({
  categories,
  costCenter,
  loading,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'subscriptions']);
  const navigate = useNavigate();

  const isEmptyCategories = useMemo(() => !Boolean(categories.length), [categories]);

  const isEmptyCostCenter = useMemo(() => !Boolean(costCenter.length), [costCenter]);

  const costCenterExtra = useMemo(() => {
    let extra;
    let dataLength = costCenter.filter(d => d.actual > d.expected).length;
    if (Boolean(dataLength)) {
      extra = (
        <Counter
          variant='error'
          value={`+${dataLength}`}
        />
      );
    }
    return extra;
  }, [costCenter]);

  const spinProps = useMemo(() => ({
    spinning: loading
  }), [loading]);

  const getPlaceholderDescription = (key) => t(`subscriptions:overview.${key}.placeholder.description`);

  const getPlaceholderTitle = (key) => t(`subscriptions:overview.${key}.placeholder.title`);

  const handleOnCostCenterClick = (e, item) => navigate(`${subscriptionsListRoute}#authorized?tags=${item?.name}`);

  return (
    <StyledSpendingDetails
      type='flex'
      {...rest}
    >
      <Col>
        <SpinSmall {...spinProps}>
          <StyledOverviewPageCard
            bordered={false}
            title={t('categories')}
          >
            {!loading && (
              <>
                {isEmptyCategories && (
                  <StyledSpendingDetailsEmpty
                    description={getPlaceholderDescription('categories')}
                    image={<EmptyCategoriesImg />}
                    title={getPlaceholderTitle('categories')}
                  />
                )}
                {!isEmptyCategories && (
                  <PieChart
                    barWidth={20}
                    className='overview-pie-chart'
                    cornerRadius={3}
                    data={categories}
                    chartHeight={168}
                    outerRadius={84}
                    pieProps={{paddingAngle: 2}}
                    renderTotal={getAmountWithCurrencyCode}
                    renderValue={getAmountWithCurrencyCode}
                    isEnableLegend={true}
                    isEnableTotal={true}
                    totalTitle={t('total')}
                  />
                )}
              </>
            )}
          </StyledOverviewPageCard>
        </SpinSmall>
      </Col>
      <Col>
        <SpinSmall {...spinProps}>
          <StyledOverviewPageCard
            bordered={false}
            className={`cost-center-chart-card ${!loading && !isEmptyCostCenter ? 'no-padding' : ''}`}
            extra={costCenterExtra}
            title={t('costCenter')}
          >
            {!loading && (
              <>
                {isEmptyCostCenter && (
                  <StyledSpendingDetailsEmpty
                    description={getPlaceholderDescription('costCenter')}
                    image={<EmptyCostCenterImg />}
                    title={getPlaceholderTitle('costCenter')}
                  />
                )}
                {!isEmptyCostCenter && (
                  <VerticalBarChart
                    barBackgroundColor={colorsStyles.primary_3}
                    barColor='linear-gradient(90deg, #40B0D5 0%, #005F7F 100%)'
                    barRadius={3}
                    className='cost-center-chart'
                    data={costCenter}
                    onItemClick={handleOnCostCenterClick}
                    renderValue={getAmountWithCurrencyCode}
                  />
                )}
              </>
            )}
          </StyledOverviewPageCard>
        </SpinSmall>
      </Col>
    </StyledSpendingDetails>
  );
}

SpendingDetails.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number
    })
  ),
  costCenter: PropTypes.arrayOf(
    PropTypes.shape({
      actual: PropTypes.number,
      expected: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  loading: PropTypes.bool
}

SpendingDetails.defaultProps = {
  categories: [],
  costCenter: [],
  loading: false
}
export default SpendingDetails;
