import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Avatar, Button, Space, Tooltip} from 'antd';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {InfoCircleOutlined} from '@ant-design/icons';
import {
  StyledExpandedSubscriptionRowEmailsTooltipContent,
  StyledExpandedSubscriptionRowHeader,
  StyledExpandedSubscriptionRowTitle,
  StyledExpandedSubscriptionRowSpace,
  StyledExpandedSubscriptionRowTag
} from './StyledExpandedSubscriptionRow';
import SubscriptionEmailsModal from '../../tabComponents/SubscriptionEmailsModal';
import {serviceStatsActions, subscriptionActions} from '../../../../../state/actions';
import './ExpandedSusbscriptionRow.css';
import {
  elementHelpers,
  objectHelpers,
  subscriptionsHelpers,
  textHelpers
} from '../../../../../utils/helpers';

const {stopPropagation} = elementHelpers;

const defaultDataObject = {loading: true, data: []};

const ExpandedSubscriptionRow = ({
  dispatch,
  subscription,
  getRecommendedServices,
  getSubscriptionEmails,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'subscriptions']);
  const [subscriptionEmails, setSubscriptionEmails] = useState(defaultDataObject);
  const [recommendedServices, setRecommendedServices] = useState(defaultDataObject);
  const [emailModalProps, setEmailModalProps] = useState({open: false, emails: []});

  const trans = (key, options) => t(`subscriptions:${key}`, options);

  const emptyListMessage = t('emptyList');

  useEffect(() => {
    subscriptionsHelpers.handleLoadSubscriptionEmails({subscription, subscriptionEmails, setSubscriptionEmails, getSubscriptionEmails});
    handleLoadServices(subscription);
  }, [subscription]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleLoadServices = (subscription) => {
    const serviceName = subscriptionsHelpers.getServiceName(subscription);
    if (!recommendedServices.hasOwnProperty(serviceName)) {
      setRecommendedServices({...recommendedServices, loading: true});

      const successHandle = (data) => {
        setRecommendedServices({
          ...recommendedServices,
          loading: false, data
        });
      }
      const errorHandle = () => successHandle([]);

      getRecommendedServices(
        serviceName,
        successHandle,
        errorHandle
      );
    }
  }

  const getData = (dataObject) => ({
    data: dataObject?.data || [],
    loading: dataObject?.loading || false
  });

  const handleSeeMore = (e, emails) => {
    stopPropagation(e);
    setEmailModalProps({...emailModalProps, emails, open: true});
  }

  const handleCloseEmailModal = () => setEmailModalProps({...emailModalProps, open: false});

  const getUsersListValue = () => {
    const {data, loading} = getData(subscriptionEmails);
    const emailsCount = data.length;
    const displayedEmailsCount = 3;
    const enabledSeeMoreButton = emailsCount > displayedEmailsCount;
    const displayedEmails = enabledSeeMoreButton ? data.slice(0, displayedEmailsCount) : data;
    const moreEmailsCount = emailsCount - displayedEmailsCount;

    return (loading ? `${t('Loading')}...` : (
      <StyledExpandedSubscriptionRowEmailsTooltipContent size={10} direction='vertical'>
        {emailsCount ? (
          <>
            {displayedEmails.map(email => <span key={email} className='expanded-subscription-row-dropdown-email'>{email}</span>)}
            {enabledSeeMoreButton && (
              <Button
                type='link'
                onClick={(e) => handleSeeMore(e, data)}
              >
                {t('seeMoreCount', {count: moreEmailsCount})}
              </Button>
            )}
          </>
        ) : emptyListMessage}
      </StyledExpandedSubscriptionRowEmailsTooltipContent>
    ));
  }

  const getServicesListValue = () => {
    const {data, loading} = getData(recommendedServices);
    const count = data.length;

    return (loading ? `${t('Loading')}...` : (
      <Space size='small' direction='vertical'>
        {count ? (
          <>
            {data.map((service, key) => {
              const serviceName = service.name || service.url;
              return (
                <Space size='small' key={key}>
                  <Avatar size={32} src={service.logo || undefined}>
                    {textHelpers.getInitials(serviceName)}
                  </Avatar>
                  {serviceName}
                </Space>
              )
            })}
          </>
        ) : emptyListMessage}
      </Space>
    ));
  }

  const getServiceProp = (record, key, defaultValue) => objectHelpers.getObjProp(record?.service || undefined, key) || defaultValue;

  const category = getServiceProp(subscription, 'category', '-');
  const description = getServiceProp(subscription, 'description', '-');
  const tags = getServiceProp(subscription, 'tags', []);
  const usersList = getUsersListValue();
  const servicesList = getServicesListValue();

  return (
    <StyledExpandedSubscriptionRowSpace direction='vertical' {...rest}>
      <StyledExpandedSubscriptionRowHeader>
        <Space direction='vertical' size={4}>
          <StyledExpandedSubscriptionRowTitle>
            {t('description')}
          </StyledExpandedSubscriptionRowTitle>
          {description}
        </Space>
        <Space direction='vertical' size={4}>
          <StyledExpandedSubscriptionRowTitle>
            {t('category')}
          </StyledExpandedSubscriptionRowTitle>
          {category}
        </Space>
        <Space direction='vertical' size={4}>
          <StyledExpandedSubscriptionRowTitle>
            {t('users')}
          </StyledExpandedSubscriptionRowTitle>
          {usersList}
        </Space>
        <Space direction='vertical' size={4}>
          <StyledExpandedSubscriptionRowTitle size='small'>
            {trans('overlappingApps')}
            <Tooltip title={trans('tooltips.overlappingApps')}>
              <InfoCircleOutlined size={14} />
            </Tooltip>
          </StyledExpandedSubscriptionRowTitle>
          {servicesList}
        </Space>
      </StyledExpandedSubscriptionRowHeader>
      <Space
        className='features'
        direction='vertical'
        size={4}
      >
        <StyledExpandedSubscriptionRowTitle>
          {t('features')}
        </StyledExpandedSubscriptionRowTitle>
        <Space size='small'>
          {tags.length ? tags.map((tag, key) => (
            <StyledExpandedSubscriptionRowTag key={key}>
              {tag}
            </StyledExpandedSubscriptionRowTag>
          )) : emptyListMessage}
        </Space>
      </Space>

      <SubscriptionEmailsModal
        {...emailModalProps}
        handleClose={handleCloseEmailModal}
        subscription={subscription}
      />
    </StyledExpandedSubscriptionRowSpace>
  );
}

ExpandedSubscriptionRow.propTypes = {
  subscription: PropTypes.object,
}

const mapDispatchToProps = {
  getRecommendedServices: serviceStatsActions.getRecommendedServices,
  getSubscriptionEmails: subscriptionActions.getSubscriptionEmails,
}

export default connect(null, mapDispatchToProps)(ExpandedSubscriptionRow);
