import React, {useMemo} from 'react';
import {Button, Form, Input, Select} from 'antd';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {StyledSupportForm} from './StyledSupportForm';
import {formHelpers} from '../../../../utils/helpers';

const {Item} = Form;

const initialFormValues = {
  description: '',
  subject: '',
  type: undefined
}

const SupportForm = ({
  onSubmit,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'support']);
  const [form] = Form.useForm();

  const trans = (key) => t(`support:${key}`);

  const supportOptions = useMemo(() => {
    const options = ['Behaviour towards customers', 'Information/advice', 'Quality of the offer', 'Pricing',
      'Failure or poor execution of a transaction', 'Delays in processing a transaction', 'Malfunctions',
      'Dispute a transaction for failed authorization (including fraud, loss or theft of payment means)',
      'Account incidents', 'Account closure', 'Change of bank or transfer request', 'Basic banking service',
      'General product feedback'
    ];
    return options.map(value => {
      return {
        value,
        label: trans(`supportForm.supportOptions.${value}`)
      }
    })
  }, [t]); // eslint-disable-line react-hooks/exhaustive-deps

  const label = (key) => trans(`supportForm.labels.${key}`);

  const placeholder = (key) => trans(`supportForm.placeholders.${key}`);

  const lengthFieldMessage = (value, type = 'min') => t(`validation.${type}LengthValidation`, {value});

  const requiredRules = [{required: true, message: t('validation.fieldIsRequired')}];

  const getRequiredRulesLength = (max) => ([
    ...requiredRules,
    {max, message: lengthFieldMessage(max, 'max')}
  ]);

  const handleSubmit = (fields) => {
    if (onSubmit) {
      onSubmit(
        fields,
        () => {
          form.setFieldsValue(initialFormValues)
        },
        (err) => {
          if (err.hasOwnProperty('errors')) {
            const fields = formHelpers.getFormServerErrorFields(err);
            form.setFields(fields);
          }
        }
      )
    }
  }

  return (
    <StyledSupportForm
      {...rest}
      initialValues={initialFormValues}
      form={form}
      layout='vertical'
      onFinish={handleSubmit}
    >
      <Item
        label={label('reason')}
        name='type'
        rules={requiredRules}
        required
      >
        <Select
          options={supportOptions}
          placeholder={placeholder('reason')}
          size='large'
        />
      </Item>
      <Item
        label={label('subject')}
        name='subject'
        rules={getRequiredRulesLength(150)}
        required
      >
        <Input
          maxLength={150}
          placeholder={placeholder('subject')}
          size='large'
        />
      </Item>
      <Item
        label={label('description')}
        name='description'
        rules={getRequiredRulesLength(500)}
        required
      >
        <Input.TextArea
          rows={6}
          maxLength={500}
          placeholder={placeholder('description')}
          size='large'
        />
      </Item>
      <Item>
        <Button
          size='large'
          htmlType='submit'
          type='primary'
        >
          {t('send')}
        </Button>
      </Item>
    </StyledSupportForm>
  );
}

SupportForm.propTypes = {
  onSubmit: PropTypes.func
}

export default SupportForm;
