import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {AutoComplete, Input} from 'antd';
import {StyledSubscriptionServiceSelect} from './StyledSubscriptionServiceSelect';
import {subscriptionsHelpers} from '../../../../../utils/helpers';
import useDebounce from '../../../../../hooks/useDebounce';

const getServices = (services) => services.map(s => s.url || s.name).sort();

const SubscriptionServiceSelect = ({
  autocompleteProps,
  inputProps,
  defaultValue,
  delay,
  minSearchSymbolsLength,
  onChange,
  onSearch,
  services,
  value,
  ...rest
}) => {
  const [serviceOptions, setServiceOptions] = useState(getServices(services));
  const [fieldValue, setFieldValue] = useState(defaultValue);
  const [counterChange, setCounterChange] = useState(0);
  const [searchedValue, setSearchedValue] = useState('');

  useEffect(() => value !== undefined && setFieldValue(value), [value]);

  useDebounce(
    () => {
      if (counterChange > 0 && onSearch && (searchedValue === '' || searchedValue.length >= minSearchSymbolsLength)) onSearch(searchedValue);
      if (counterChange === 0) setCounterChange(1);
    },
    delay,
    [searchedValue]
  );

  useEffect(() => {
    const options = getServices(subscriptionsHelpers.getServicesOptionsList(services, searchedValue));
    setServiceOptions(options);
  }, [services]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnSearch = (service) => {
    const options = subscriptionsHelpers.getServicesOptionsList(services, service);
    setServiceOptions(getServices(options));
  }

  const handleUpdateValue = (value) => {
    setFieldValue(value);
    onChange && onChange(value);
  }

  return (
    <StyledSubscriptionServiceSelect {...rest}>
      <AutoComplete
        {...autocompleteProps}
        onSelect={handleUpdateValue}
        onSearch={handleOnSearch}
        options={serviceOptions.map(s =>({value: s, label: s}))}
      >
        <Input
          {...inputProps}
          value={fieldValue}
          onChange={(e) => setSearchedValue(e.target.value)}
        />
      </AutoComplete>
    </StyledSubscriptionServiceSelect>
  );
}

SubscriptionServiceSelect.propTypes = {
  defaultValue: PropTypes.string,
  delay: PropTypes.number,
  minSearchSymbolsLength: PropTypes.number,
  value: PropTypes.string,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      logo: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string
    })
  )
}

SubscriptionServiceSelect.defaultProps = {
  defaultValue: '',
  delay: 1000,
  minSearchSymbolsLength: 3,
  services: []
}

export default SubscriptionServiceSelect;

