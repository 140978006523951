import React from 'react';
import PropTypes from 'prop-types';
import {Avatar, Space} from 'antd';
import ArrowIcon from '../../SubscriptionsPage/ArrowIcon';
import {
  StyledSubscriptionTooltipSpendingDetails,
  StyledSubscriptionTooltipSpendingDetailsTitle,
  StyledSubscriptionTooltipSpendingDetailsServicesList
} from './StyledSubscriptionTooltipSpendingDetails';
import {amountHelpers, objectHelpers, textHelpers} from '../../../../utils/helpers';

const gObjProp = objectHelpers.getObjProp;


const SubscriptionTooltipSpendingDetails = ({
  emptyText,
  total,
  services,
  name,
  type
}) => {
  const totalValue = amountHelpers.getAmountWithCurrencyCode(total);

  return (
    <StyledSubscriptionTooltipSpendingDetails>
      <StyledSubscriptionTooltipSpendingDetailsTitle className={type}>
        <span>{name}</span><span>{totalValue}</span>
      </StyledSubscriptionTooltipSpendingDetailsTitle>
      <StyledSubscriptionTooltipSpendingDetailsServicesList>
        {services.map((service, key) => {
          let logo, name;
          let className = '';
          const direction = gObjProp(service, 'direction');
          const value = gObjProp(service, 'value');
          const serviceDetails = gObjProp(service, 'service');
          if (typeof serviceDetails === 'object') {
            logo = gObjProp(serviceDetails, 'logo');
            name = gObjProp(serviceDetails, 'name');
          } else {
            name = serviceDetails;
          }
          if (direction && direction === 'up') className = 'up-direction';

          return (
            <li key={`forecast-${key}`} className={className}>
              <Space size={4}>
                <Avatar
                  size='small'
                  src={logo || undefined}
                >
                  {textHelpers.getInitials(name)}
                </Avatar>
                {name}
              </Space>
              <div className='tooltip-price-value d-flex align-items-center'>
                {direction && (
                  <span className='direction d-flex align-items-center justify-end'>
                    <ArrowIcon direction={direction} arrowSize='xsmall' />
                  </span>
                )}
                <span className='price'>
                  {amountHelpers.getAmountWithCurrencyCode(value)}
                </span>
              </div>
            </li>
          )
        })}
        {(services.length === 0 && emptyText) && emptyText}
      </StyledSubscriptionTooltipSpendingDetailsServicesList>

    </StyledSubscriptionTooltipSpendingDetails>
  );
}

SubscriptionTooltipSpendingDetails.propTypes = {
  emptyText: PropTypes.string,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      direction: PropTypes.oneOf(['up', 'down']),
      logo: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.number
    })
  ),
  name: PropTypes.string.isRequired,
  total: PropTypes.number,
  type: PropTypes.oneOf(['dashed', 'solid'])
}

SubscriptionTooltipSpendingDetails.defaultProps = {
  services: [],
  total: 0,
  type: 'solid'
}

export default SubscriptionTooltipSpendingDetails;
