export const colorHelpers = {
  generateRandomColor: () => '#' + (Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0'),
  getColorByIndex: (index) => {
    return {
      0: '#52C41A',
      1: '#1890FF',
      2: '#722ED1',
      3: '#FF7875',
      4: '#03AB93',
      5: '#EABD3B',
    }[index] || colorHelpers.generateRandomColor();
  },
  hex2rgba: (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  },
}
