
export const textHelpers = {
  cutString: (string, maxLengthName = 15) =>
    string ? string.length <= maxLengthName ? string : `${string.substring(0, maxLengthName)}...` : '',
  findStringDifference: (str1, str2) => str2[[...str1].findIndex((el, index) => el !== str2[index])],
  getCapitalized: (word) => word ? `${word.charAt(0).toUpperCase()}${word.slice(1)}` : word,
  getInitials: (string, useOneInitialLetter = false) => {
    if (string === null || string === undefined) {
      return '';
    }
    let names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase(),
      namesLength = names.length;

    if (namesLength > 1) {
      initials += names[namesLength - 1].substring(0, 1).toUpperCase();
    } else if (namesLength === 1 && !useOneInitialLetter) {
      initials += string.slice(1, string.length - 1).substring(0, 1).toUpperCase()
    }
    return initials;
  },
  reverse: (str) => str.split('').reverse().join(''),
  separateString: (str, separateSymbol) => {
    str = textHelpers.reverse(str);
    str = str.match(/.{1,3}/g).join(' ');
    str = textHelpers.reverse(str);
    if (separateSymbol !== ' ') {
      str = str.replaceAll(' ', separateSymbol);
    }
    return str;
  },
  getTextDigits: (text) => text.replace(/\D/g,''),
}
