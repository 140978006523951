import React, {useMemo} from 'react';
import {Avatar, Space} from 'antd';
import PropTypes from 'prop-types';
import {
  StyledUpcomingSpending,
  StyledUpcomingSpendingItem,
  StyledUpcomingSpendingSpendItem
} from './StyledUpcomingSpending';
import {amountHelpers, textHelpers} from '../../../../utils/helpers';

const getAmountWithCurrencyCode = (value, showFractionalPart) => amountHelpers.getAmountWithCurrencyCode(value, undefined, undefined, true, showFractionalPart);

const UpcomingSpending = ({data, ...rest}) => {

  const formattedUpcoming = useMemo(() => {
    const datesList = [...new Set(data.map(d => d.date))].sort((a, b) => a - b);
    return datesList.map(date => ({
      date,
      spending: data.filter(d => d.date === date)
    }));
  }, [data]);

  return (
    <StyledUpcomingSpending
      {...rest}
      size={9}
      direction='vertical'
    >
      {formattedUpcoming.map((item) => {
        const date = item.date;
        const spending = item.spending || [];
        return (
          <StyledUpcomingSpendingItem
            direction='vertical'
            key={date}
            size={9}
          >
            <span className='date'>{date}</span>
            {spending.map((spending, key) => {
              const {logo, name} = spending.service;
              const {actual} = spending;
              return (
                <StyledUpcomingSpendingSpendItem key={`spending-${key}`}>
                  <Space size='small' align='center'>
                    <Avatar src={logo}>
                      {textHelpers.getInitials(name)}
                    </Avatar>
                    {name}
                  </Space>
                  <span className='price danger'>
                    {getAmountWithCurrencyCode(actual)}
                  </span>
                </StyledUpcomingSpendingSpendItem>
              )
            })}
          </StyledUpcomingSpendingItem>
        )
      })}
    </StyledUpcomingSpending>
  );
}

UpcomingSpending.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      actual: PropTypes.number,
      budget: PropTypes.number,
      date: PropTypes.string,
      service: PropTypes.shape({
        logo: PropTypes.string,
        name: PropTypes.string
      })
    })
  )
}

UpcomingSpending.defaultProps = {
  data: []
}

export default UpcomingSpending;
