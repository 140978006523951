import styled from 'styled-components';
import {bordersStyles, typographyStyles} from '../../../../../../utils/styles';

const StyledTotalCounter = styled.div`
  ${bordersStyles.default};
  background-color: ${props => props.theme.colors.neutral_1};
  padding: 16px 24px;
  display: flex;
  align-items: self-start;
  justify-content: space-between;

`;

const TotalDescription = styled.div`
  .title {
    ${typographyStyles.body.regular};
    color: ${props => props.theme.colors.neutral_6};
  }

  .value {
    ${typographyStyles.heading.h4};
    color: ${props => props.theme.colors.neutral_10};
  }

  .description {
    ${typographyStyles.body.regular};
    color: ${props => props.theme.colors.neutral_8};
    margin-top: 8px;
  }

  .trend {
    &.success {
      color: ${props => props.theme.colors.functional_success};
    }
    &.error {
      color: ${props => props.theme.colors.functional_danger};
    }
  }
`;

const Extra = styled.div`
  position: relative;
`;

export {StyledTotalCounter, Extra, TotalDescription};
