import styled from 'styled-components';
import {typographyStyles} from '../../utils/styles';

const StyledUserDetailsEmail = styled.span`
  ${typographyStyles.footnote.description};
  color: ${props => props.theme.colors.neutral_7};
`;

const StyledUserDetailsName = styled.span`
  ${typographyStyles.body.regular};
  color: ${props => props.theme.colors.neutral_10};
`;

export {
  StyledUserDetailsEmail,
  StyledUserDetailsName
};
