import styled from 'styled-components';

const PADDING = '24px';

const StyledSubscriptionPage = styled.div`
  padding: ${PADDING};
  min-height: calc(100vh - 64px - (${PADDING} * 2));
`;

export {
  StyledSubscriptionPage
}
