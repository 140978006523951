import styled from 'styled-components';
import {Space} from 'antd';
import {typographyStyles} from '../../../../utils/styles';

const StyledVendorDetails = styled(Space)`
  .vendor-name {
    ${typographyStyles.body.regular};
    color: ${props => props.theme.colors.neutral_10};
  }
  .vendor-category {
    ${typographyStyles.footnote.description};
    color: ${props => props.theme.colors.neutral_7};
  }
`;

const StyledVendorDetailsLink = styled.a`
  align-items: center;
  display: flex;
  justify-content: center;
  
  svg {
    width: 10px;
    height: 10px;
    path {
      fill: ${props => props.theme.colors.neutral_6};
      transition: .2s ease;
    }
  }
  
  &:hover,&:active,&:focus {
    svg {
      path {
        fill: ${props => props.theme.colors.primary_5};
      }
    }
  }
`;

export {
  StyledVendorDetails,
  StyledVendorDetailsLink
}
