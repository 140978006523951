import {createTheme} from '@mui/material';
import {colorsStyles} from '../utils/styles';

const standardBorderRadius = 4;

const styles = {
  border_radius: {
    standard: `${standardBorderRadius}px`,
  },
  colors: {
    ...colorsStyles,
    secondary: colorsStyles.primary_6,
  }
};


const styledTheme = Object.assign(
  {},
  styles
);

export const antdTheme = {
  token: {
    borderRadius: standardBorderRadius,
    colorPrimary: colorsStyles.primary_6,
    colorPrimaryActive: colorsStyles.primary_7,
    colorPrimaryHover: colorsStyles.primary_8
  }
}

export const materialTheme = createTheme({
  palette: {
    primary: {
      main: styledTheme.colors.secondary
    }
  }
});

export default styledTheme;
