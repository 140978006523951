import styled from 'styled-components';
import {Space} from 'antd';
import Tabs from '../../components/Tabs';

const CHILDREN_WIDTH = 'calc(61.25% + 12px)';

const StyledSettingPageContainer = styled.div`
  padding: 24px;
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    &:before {
      content: none;
    }
  }
  .ant-tabs-tabpane {
    .ant-card {
      margin-top: 0;
    }
  }
  
  .ant-tabs-content-holder {
    background-color: unset;
    border: unset;
    min-height: unset;
    padding: unset;
  }
`;

const StyledTabComponentContainer = styled(Space)`
  width: 100%;
  & > div {
    width: 100%;
    & > div {
      width: 100%;
    }
    &:nth-of-type(1) {
      min-width: ${CHILDREN_WIDTH};
      width: ${CHILDREN_WIDTH};
    }
  }
`;

export {
  StyledSettingPageContainer,
  StyledTabs,
  StyledTabComponentContainer
};
