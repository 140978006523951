import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Image, Upload as AntdUpload} from 'antd';
import {Document, Page, pdfjs} from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import PropTypes from 'prop-types';
import {
  StyledInvoiceUpload,
  StyledInvoiceUploadChildren,
  StyledInvoiceUploadChildrenContent,
  StyledInvoiceUploadFilePreview
} from './StyledInvoiceUpload';
import {ReactComponent as UploadIconSvgImage} from '../../../../static/images/icons/upload-icon.svg';
import {CloseIcon} from '../../../../icons';
import {elementHelpers, fileHelpers} from '../../../../utils/helpers';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const InvoiceUpload = React.forwardRef(({
  image,
  onChange,
  uploadProps,
  defaultFileList,
  ...rest
}, ref) => {
  const [t] = useTranslation('main');
  const [filePreviewList, setFilePreviewList] = useState([]);
  const [fileList, setFileList] = useState([]);

  const {enabled: enabledPreview, filename, fileType} = useMemo(() => {
    let enabled = false;
    let fileType = '';
    let filename;
    if (filePreviewList.length > 0 && fileList.length > 0) {
      enabled = true;
      fileType = fileList[0].type;
      filename = fileList[0].name;
    }

    return {
      enabled,
      filename,
      fileType
    }
  }, [filePreviewList, fileList]);

  const handleChange = ({ fileList: newFileList }) => {
    handlePreview(newFileList).then();
    onChange && onChange(newFileList);
  }

  useEffect(() => {
    defaultFileList && handlePreview(defaultFileList).then();
  }, [defaultFileList]);

  const handlePreview = async (files) => {
    let previewList = [];
    if (files.length > 0) {
      let filePreview = typeof files[0] === 'string' ? files[0] : await fileHelpers.getBase64(files[0].originFileObj);
      previewList = [filePreview];
    }
    setFilePreviewList(previewList);
    setFileList(files);
  };

  const antdUploadProps = {
    accept: '.jpeg,.jpg,.png,.pdf',
    ...uploadProps,
    className: elementHelpers.getClassName(enabledPreview && 'd-none', uploadProps?.className || ''),
    customRequest: () => null,
    maxCount: 1,
    onChange: handleChange,
    showUploadList: false,
  }

  const onRemove = () => {
    setFilePreviewList([]);
    setFileList([]);
    onChange && onChange([]);
  }

  const getFilePreview = () => {
    let filePreview = filename;
    const file = fileList.length > 0 ? fileList[0].originFileObj : undefined;
    if (fileType === 'application/pdf') {
      return (
        <Document
          file={file}
        >
          <Page
            pageNumber={1}
            renderTextLayer={false}
          />
        </Document>
      )
    } else if (fileType.startsWith('image')) {
      filePreview = (
        <Image
          preview={false}
          src={filePreviewList[0]}
        />
      );
    }
    return filePreview;
  }

  const formats = fileHelpers.getSupportedFormatsList(antdUploadProps.accept);

  const filePreview = getFilePreview();

  return (
    <StyledInvoiceUpload
      {...rest}
      ref={ref}
    >
      <StyledInvoiceUploadFilePreview className={!enabledPreview && 'd-none'}>
        <Button
          danger
          onClick={onRemove}
          type='primary'
          shape='circle'
        >
          <CloseIcon />
        </Button>
        {filePreview}
      </StyledInvoiceUploadFilePreview>
      <AntdUpload
        {...antdUploadProps}
      >
        <StyledInvoiceUploadChildren
          direction='vertical'
          size='small'
        >
          {image ? <img src={image} alt='upload'/> : <UploadIconSvgImage/>}
          <StyledInvoiceUploadChildrenContent>
            <div className='flex-center-center'>
              <p>{t('dragAndDropFileOr')}</p>&nbsp;
              <Button type='link'>{t('browse')}</Button>
            </div>
            <span>{t('supportedFormats')}: {formats}</span>
          </StyledInvoiceUploadChildrenContent>
        </StyledInvoiceUploadChildren>
      </AntdUpload>
    </StyledInvoiceUpload>
  );
});

InvoiceUpload.propTypes = {
  defaultFileList: PropTypes.array,
  image: PropTypes.any,
  uploadProps: PropTypes.object
}

InvoiceUpload.defaultProps = {
  defaultFileList: [],
  uploadProps: {}
}

export default InvoiceUpload;
