import { useState, useEffect } from 'react';
import {objectHelpers} from '../utils/helpers';

export default function useContentLoaded (data) {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!objectHelpers.isEmptyObject(data)) setIsLoaded(true);
  }, [data]);

  return isLoaded;
}

