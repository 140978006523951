import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {Avatar, Space, Tag} from 'antd';
import {
  StyledTransactionDetailsHeader,
  StyledTransactionDetailsHeaderItem
} from './StyledTransactionDetailsHeader';
import {ExternalLinkIcon} from '../../../../icons';
import {
  amountHelpers,
  dateHelpers,
  objectHelpers,
  systemHelpers,
  textHelpers,
  transactionsHelpers
} from '../../../../utils/helpers';
import routes from '../../../../routes/routes.json';
import {firebaseEvents} from '../../../../snippets/firebase';

const gObjProp = objectHelpers.getObjProp;

const TransactionDetailsHeader = ({
  availableSubscriptionLink,
  subscriptionLink,
  subscription,
  transaction,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'transactions']);

  const getTransactionProp = (propName, defaultValue) => gObjProp(transaction, propName, defaultValue);
  const getSubscriptionProp = (propName, defaultValue) => gObjProp(subscription, propName, defaultValue);

  const amount = amountHelpers.getAmountWithCurrencyCode(getTransactionProp('amount'));
  const isIncoming = getTransactionProp('is_incoming', false);
  const date = dateHelpers.getDateFromISO(getTransactionProp('created_date'), 'DD MMM YYYY, HH:mm');
  const source = transactionsHelpers.getTransactionSource(transaction);
  const subscriptionId = getSubscriptionProp('id');
  const isReturnedTransaction = transactionsHelpers.isReturnedTransaction(transaction);
  const paymentStatus = getTransactionProp('payment_status');
  const status = transactionsHelpers.getTransactionStatus(paymentStatus);

  const getTitle = () => {
    const service = getSubscriptionProp('service');
    const serviceLogo = gObjProp(service, 'logo');
    const serviceName = gObjProp(service, 'name') || gObjProp(service, 'url');
    const initials = textHelpers.getInitials(serviceName || source);

    return (
      <Space size={subscriptionId ? 4 : 'small'}>
        <Avatar src={serviceLogo} size={36}>
          {initials}
        </Avatar>
        {(availableSubscriptionLink && subscriptionId) ? (
          <Link
            onClick={() => systemHelpers.logEvent(firebaseEvents.TRANSACTIONS_NAVIGATE_TO_SUBSCRIPTION_PAGE_FROM_TRANSACTION_DETAILS)}
            to={`${routes.subscriptionsList}/${subscriptionId}`}
          >
            <Space size={12}>
              <span>{source}</span>
              <ExternalLinkIcon />
            </Space>
          </Link>
        ) : source}
      </Space>
    );
  }

  const title = getTitle();

  const tagStyle = {
    backgroundColor: status.bgColor || 'unset',
    borderColor: status.color,
    color: status.color
  };

  return (
    <StyledTransactionDetailsHeader {...rest}>
      <StyledTransactionDetailsHeaderItem>
        {paymentStatus && (
          <Tag style={tagStyle}>
            {t(status.key)}
          </Tag>
        )}
        <span className='date'>
          {date}
        </span>
      </StyledTransactionDetailsHeaderItem>
      <StyledTransactionDetailsHeaderItem>
        {title}
        <span className={`price ${isReturnedTransaction ? 'returned' : ''}`}>
          {!isIncoming && '-'}{amount}
        </span>
      </StyledTransactionDetailsHeaderItem>
    </StyledTransactionDetailsHeader>
  );
}

TransactionDetailsHeader.propTypes = {
  availableSubscriptionLink: PropTypes.bool,
  subscription: PropTypes.object,
  transaction: PropTypes.object
}

TransactionDetailsHeader.defaultProps = {
  availableSubscriptionLink: true
}

export default TransactionDetailsHeader;
