import React, {useState, useRef, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Avatar, Button, Space, Upload} from 'antd';
import {UserOutlined} from '@ant-design/icons';
import {UnmountClosed} from 'react-collapse';
import {useTranslation} from 'react-i18next';
import ImgCrop from 'antd-img-crop';
import {
  StyledEditableAvatar,
  StyledActionsSpace
} from './StyledEditableAvatar';
import {CloseIcon, EditIcon} from '../../icons';
import {fileHelpers} from '../../utils/helpers';

const defaultFile = {file: undefined, name: undefined};

const EditableAvatar = ({
  accept,
  avatarProps,
  defaultValue,
  disabled,
  enabledActions,
  initials,
  onChange,
  onError,
  showError,
  ...rest
}) => {
  const [t] = useTranslation('main');
  const [file, setFile] = useState({...defaultFile, file: defaultValue});
  const [errorMessage, setErrorMessage] = useState(undefined);

  const uploadRef = useRef();
  const icon = initials ? undefined : <UserOutlined />;

  useEffect(() => setErrorMessage(undefined), [file]);

  const isOpenedError = useMemo(() => showError && Boolean(errorMessage), [errorMessage, showError]);

  const handleOnFileChange = async (file) => {
    if (typeof file === 'string') {
      setErrorMessage(file);
      onError && onError(file);
      return;
    }
    if (typeof file === 'object' && !disabled) {
      const fileBase64 = await fileHelpers.getBase64(file);
      const fileObj = {
        file: fileBase64,
        name: file.name
      };
      setFile(fileObj);
      onChange && onChange(fileObj);
    }
  }

  const handleOnRemove = () => {
    if (!disabled) {
      setFile(defaultFile);
      onChange && onChange(defaultFile);
    }
  }

  const beforeUpload = (file) => {
    const fileFormat = `.${file.type.split('/')[1]}`;
    if (accept.includes(fileFormat) || accept.includes(file.type)) {
      return file;
    } else {
      const acceptedFormats = accept.split(',').map(i => i.trim()).join(', ');
      return `File format ${fileFormat} not supported, allowed formats: ${acceptedFormats}.`;
    }
  }

  return (
    <StyledEditableAvatar {...rest}>
      <Space
        size='middle'
        {...rest}
      >
        <Avatar
          icon={icon}
          size={86}
          style={{fontSize: 28}}
          src={file.file}
          {...avatarProps}
        >
          {initials}
        </Avatar>
        {enabledActions && (
          <StyledActionsSpace
            direction='vertical'
          >
            <ImgCrop
              cropShape='round'
              onModalOk={handleOnFileChange}
              quality={1}
            >
              <Upload
                accept={accept}
                beforeUpload={beforeUpload}
                ref={uploadRef}
                customRequest={() => null}
                showUploadList={false}
              >
                <Button
                  disabled={disabled}
                  type='link'
                >
                  {t('edit')} <EditIcon />
                </Button>
              </Upload>
            </ImgCrop>
            {file.file && (
              <Button
                disabled={disabled}
                onClick={handleOnRemove}
                type='link'
              >
                {t('remove')} <CloseIcon />
              </Button>
            )}
          </StyledActionsSpace>
        )}
      </Space>
      <UnmountClosed isOpened={isOpenedError}>
        <span className='editable-avatar-error-message'>
          {errorMessage}
        </span>
      </UnmountClosed>
    </StyledEditableAvatar>
  );
}

EditableAvatar.propTypes = {
  accept: PropTypes.string,
  avatarProps: PropTypes.object,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  enabledActions: PropTypes.bool,
  initials: PropTypes.string,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  showError: PropTypes.bool
}

EditableAvatar.defaultProps = {
  accept: '.jpeg,.png,.jpg',
  disabled: false,
  enabledActions: true,
  showError: true
}

export default EditableAvatar;
