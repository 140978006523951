import ls from 'localstorage-slim';
import {
  localStorageKeysConstants,
  SCASessionTypesConstants,
  SCAActionsConstants,
} from '../../constants';

const {FIVE_MINUTES_SESSION, LONG_SESSION, PER_OPERATION_SESSION} = SCASessionTypesConstants;
const {SCA_HEADER_TYPE, SCA_SESSION_KEY} = localStorageKeysConstants;

const getObjProp = (data, key, defaultValue) => data ? data[key] || defaultValue : defaultValue;

const SCA_OPERATION_LS_PREFIX = 'sca-operation';

export const scaHelpers = {
  getOperationType: (operationName) => {
    const actionTypes = {
      // long session
      [SCAActionsConstants.OVERVIEW_CHARTS_AND_TABLES_LOADING_ACTION]: LONG_SESSION,
      [SCAActionsConstants.BALANCE_LOADING]: LONG_SESSION,
      [SCAActionsConstants.TRANSACTIONS_WITHIN_90_DAYS_LOADING_ACTION]: LONG_SESSION,
      // 5 minutes session
      [SCAActionsConstants.ADD_FUNDS_LOADING]: FIVE_MINUTES_SESSION,
      [SCAActionsConstants.TRANSACTIONS_OLDER_90_DAYS_LOADING_ACTION]: LONG_SESSION,
      [SCAActionsConstants.WALLET_DETAILS_LOADING_ACTION]: FIVE_MINUTES_SESSION,
      [SCAActionsConstants.TRANSACTIONS_DOWNLOAD_PDF_AND_CSV_ACTION]: FIVE_MINUTES_SESSION,
      [SCAActionsConstants.SUBSCRIPTION_CREATION_WITH_CARD_ACTION]: FIVE_MINUTES_SESSION,
      [SCAActionsConstants.SUBSCRIPTION_BULK_CREATION_WITH_CARD_ACTION]: FIVE_MINUTES_SESSION,
      [SCAActionsConstants.SUBSCRIPTION_CARD_RECREATE_ACTION]: FIVE_MINUTES_SESSION,
      // per operation session
      [SCAActionsConstants.SUBSCRIPTION_CARD_CREATION_ACTION]: PER_OPERATION_SESSION,
      [SCAActionsConstants.SUBSCRIPTION_CHANGE_CARD_ACTION]: PER_OPERATION_SESSION,
      [SCAActionsConstants.SUBSCRIPTION_CHANGE_PAYMENT_METHOD_ACTION]: PER_OPERATION_SESSION,
      [SCAActionsConstants.SUBSCRIPTION_CHANGE_STATUS_ACTION]: PER_OPERATION_SESSION,
      [SCAActionsConstants.DISPLAY_CARD_DETAILS_ACTION]: PER_OPERATION_SESSION,
      [SCAActionsConstants.CARD_EDITION_ACTION]: PER_OPERATION_SESSION,
      [SCAActionsConstants.LOCK_CARD_ACTION]: PER_OPERATION_SESSION,
      [SCAActionsConstants.UNLOCK_CARD_ACTION]: PER_OPERATION_SESSION,
      [SCAActionsConstants.DISABLE_USER_WITH_TREEZOR_ACCOUNT_ACTION]: PER_OPERATION_SESSION,
      [SCAActionsConstants.ENABLE_USER_WITH_TREEZOR_ACCOUNT_ACTION]: PER_OPERATION_SESSION,
    }
    return actionTypes[operationName] || PER_OPERATION_SESSION;
  },
  clearStoredData: () => {
    Object.values(SCAActionsConstants).forEach(key => ls.remove(`${SCA_OPERATION_LS_PREFIX}-${key}`))
  },
  storeOperationToken: ({headerType, operationName, sessionKey}) => {
    const ttls = {
      [PER_OPERATION_SESSION]: 2,
      [FIVE_MINUTES_SESSION]: 360
    };
    const operationType = scaHelpers.getOperationType(operationName);
    const ttl = ttls[operationType] || null;

    ls.set(
      `${SCA_OPERATION_LS_PREFIX}-${operationName}`,
      {
        [SCA_HEADER_TYPE]: headerType,
        [SCA_SESSION_KEY]: sessionKey
      },
      { encrypt: true, ttl }
    );
  },
  getOperationToken: (operationName) => ls.get(`${SCA_OPERATION_LS_PREFIX}-${operationName}`, { decrypt: true }),
  getAuthHeaders: (operationName, defaultHeaders) => {
    if (defaultHeaders) return defaultHeaders;
    let token = scaHelpers.getOperationToken(operationName) || '';
    let headers = {
      'sca-header-type': getObjProp(token, SCA_HEADER_TYPE) || 'keyless'
    }
    if (token) {
      headers = {
        ...headers,
        'sca-session-key': getObjProp(token, SCA_SESSION_KEY, '')
      }
    }
    return headers;
  },
  SCAResponseCallback: ({response, scaCallback, errorCallback}) => {
    const statusCode = getObjProp(response.response, 'status') || 408;
    let detail;
    try {
      detail = getObjProp(response.response.data, 'detail');
    } catch {}
    if ((statusCode === 406 && detail === 'Invalid SMS code. Please try again.') || statusCode === 408) {
      scaCallback && scaCallback({isEnrolled: true, open: true});
    } else if (statusCode === 406) {
      scaCallback && scaCallback({isEnrolled: false, open: true});
    } else {
      errorCallback && errorCallback(response);
    }
  }
}
