import styled from 'styled-components';
import {Dropdown, InputNumber, Switch} from 'antd';
import {editButtonStyles} from '../../../../../EditButton/StyledEditButton';
import Card from '../../../../../Card';

const StyledPaymentDetails = styled.div`
  .ant-alert {
    align-items: baseline;
  }
`;


const StyledPaymentDetailsCard = styled(Card)`
  
  .ant-card-body {
    & > .ant-spin-nested-loading {
      & > .ant-spin-blur {
        &::after {
          width: calc(100% + 32px);
          left: -16px;
          top: -16px;
        }
      }
    }
  }
  
  .card-details {
    margin-bottom: 24px;
  }
  
  .ant-alert + .card-details {
    margin-top: 16px;
  }

  .ant-card-actions {
    justify-content: end;
    padding: 16px 24px;

    li {
      margin: 0;
      width: unset !important;
      &:not(:last-child) {
        border-inline-end: none;
        margin-right: 16px;
      }
    }
  }
`;


const StyledPaymentDetailsDropdownButton = styled(Dropdown.Button)`
  ${editButtonStyles};
`;

const StyledPaymentDetailsInput = styled(InputNumber)`
  width: 100%;
  
  input.ant-input-number-input {
    font-size: 16px;
    line-height: 1.5;
  }
  
  .ant-input-number-group-addon {
    background-color: transparent;
    svg {
      width: 16px;

      path {
        fill: ${props => props.theme.colors.neutral_6};
      }
    }
  }
`;

const StyledPaymentDetailsSwitch = styled(Switch)`
  &.ant-switch-checked {
    background: ${props => props.theme.colors.primary_6};
    &:hover:not(.ant-switch-disabled) {
      background: ${props => props.theme.colors.primary_5};
    }
  }
`;


export {
  StyledPaymentDetails,
  StyledPaymentDetailsCard,
  StyledPaymentDetailsDropdownButton,
  StyledPaymentDetailsInput,
  StyledPaymentDetailsSwitch
}
