import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Avatar, Button, Space} from 'antd';
import {Grow} from '@mui/material';
import {
  StyledTransferListItem,
  StyledSearchResults,
  StyledCardOption,
  StyledEmptyGrow,
  StyledListTransitionGroup,
  StyledSearch
} from './StyledTransferListItem';
import {objectHelpers, textHelpers} from '../../../../../utils/helpers';


const getServiceDetails = (service) => {
  const getServiceProp = (key) => objectHelpers.getObjProp(service, key);
  const url = getServiceProp('url');
  return {
    category: getServiceProp('category'),
    description: getServiceProp('description'),
    name: getServiceProp('name') || url,
    logo: getServiceProp('logo_url'),
    url
  }
}

const TransferListItem = ({
  bulkButtonText,
  className,
  emptyState,
  icon,
  inputPlaceholder,
  isEnabledAddNew,
  isEnabledBulkButton,
  onBulkButtonClick,
  onItemClick,
  onSearch,
  items,
  title,
  variant,
  ...rest
}) => {
  const [searchedItems, setSearchedItems] = useState(items);
  const [searchedText, setSearchedText] = useState('');

  useEffect(() => {
    setSearchedItems(items);
  }, [items]); // eslint-disable-line react-hooks/exhaustive-deps

  const getSearchedItems = (items, searchedText) => {
    return items.filter(service => {
      const {name, url} = service;
      searchedText = searchedText.toLowerCase();
      return name ? name.toLowerCase().includes(searchedText) : url ? url.toLowerCase().includes(searchedText) : false;
    })
  }

  const handleSearch = (value) => {
    if (variant === 'external' && onSearch) {
      onSearch && onSearch(value);
    } else if (variant === 'internal') {
      setSearchedItems(getSearchedItems(items, value));
    }
    setSearchedText(value);
  }

  const onChange = (value) => {
    if (searchedItems.length === 0 || searchedItems.find(s => s.name === value || s.domain === value) === undefined) {
      const service = {domain: value, name: value, logo: null};
      setSearchedItems([
        service,
        ...searchedItems
      ]);
    }
  }

  const handleOnItemClick = (service) => {
    onItemClick && onItemClick(service);
    // remove service from list
    const serviceUrl = service.url;
    let serviceIndex = items.findIndex(i => i.url === serviceUrl);
    if (serviceIndex < 0) setSearchedItems(searchedItems.filter(i => i.url !== serviceUrl));
  }

  const extra = (
    <Button
      onClick={() => onBulkButtonClick && onBulkButtonClick()}
      type='text'
    >
      {bulkButtonText}
    </Button>
  );

  return (
    <StyledTransferListItem
      extra={extra}
      title={title}
      {...rest}
    >
      <Space size='middle' direction='vertical'>
        <StyledSearch
          autocompleteProps={{
            autoClearSearchValue: true,
            notFoundContent: emptyState,
          }}
          inputProps={{
            allowClear: true,
            placeholder: inputPlaceholder,
            size: 'large'
          }}
          onSearch={handleSearch}
          onChange={onChange}
          services={items}
        />
        <div className='p-relative'>
          <StyledSearchResults>
            <Grow in={searchedItems.length > 0}>
              <div>
                <StyledListTransitionGroup className={searchedItems.length <= 1 && 'single-option'}>
                  {searchedItems.map((service, key) => {
                    const {category, description, logo, name} = getServiceDetails(service);
                    return (
                      <Grow
                        key={`list-item-${key}`}
                      >
                        <StyledCardOption
                          extra={icon && (
                            <span
                              className='icon pointer'
                              onClick={() => handleOnItemClick(service)}
                            >
                              {icon}
                            </span>
                          )}
                          className='service-option'
                          title={(
                            <Space>
                              <Avatar src={logo || undefined}>
                                {textHelpers.getInitials(name)}
                              </Avatar>
                              {name}
                            </Space>
                          )}
                        >
                          {category || description}
                        </StyledCardOption>
                      </Grow>
                    )
                  })}
                </StyledListTransitionGroup>
              </div>
            </Grow>
          </StyledSearchResults>

          <StyledEmptyGrow
            in={Boolean(searchedText && searchedItems.length === 0 && emptyState)}
          >
            <div>{emptyState}</div>
          </StyledEmptyGrow>
        </div>
      </Space>
    </StyledTransferListItem>
  );
}

TransferListItem.propTypes = {
  bulkButtonText: PropTypes.string,
  emptyState: PropTypes.any,
  icon: PropTypes.any,
  inputPlaceholder: PropTypes.string,
  isEnabledAddNew: PropTypes.bool,
  isEnabledBulkButton: PropTypes.bool,
  onBulkButtonClick: PropTypes.func,
  onItemClick: PropTypes.func,
  onSearch: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      logo: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string.isRequired,
    })
  ),
  title: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['internal', 'external'])
}

TransferListItem.defaultProps = {
  bulkButtonText: 'Select all',
  inputPlaceholder: 'e.g. Figma',
  isEnabledAddNew: false,
  isEnabledBulkButton: false,
  items: [],
  title: 'Example title',
  variant: 'internal'
}

export default TransferListItem;

