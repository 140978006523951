import styled from 'styled-components';
import {bordersStyles} from '../../../../../../utils/styles';
import Usage from '../../../Usage';

const StyledTransactionHeaderDetails = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: 24px;
  & > div {
    width: calc((100% - 48px) / 3);
  }
`;

const StyledTransactionHeaderDetailsCombinedCounter = styled.div`
  ${bordersStyles.default};
  align-items: start;
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.theme.colors.neutral_1};
  padding: 16px 24px;
  
  & > * {
    border: none;
    padding: 0;
  }
`;

const StyledTransactionHeaderDetailsUsage = styled(Usage)`
  & > span {
    width: 13px;
    
    &:not(:last-of-type) {
      margin-right: 4px;
    }
  }
`;

export {
  StyledTransactionHeaderDetails,
  StyledTransactionHeaderDetailsCombinedCounter,
  StyledTransactionHeaderDetailsUsage,
};
