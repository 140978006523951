import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Avatar} from 'antd';
import {
  StyledCompanyInviteModal,
  StyledCompanyInviteModalButton,
  StyledCompanyInviteModalSpace,
  StyledCompanyInviteModalHeader
} from './StyledCompanyInviteModal';
import SpinSmall from '../../SpinSmall';
import logo from '../../../static/images/withless-icon-logo-white.svg';
import {ReactComponent as ErrorSvgImage} from '../../../static/images/error.svg';
import {authActions, userActions} from '../../../state/actions';
import {objectHelpers, textHelpers} from '../../../utils/helpers';

const gObjProp = objectHelpers.getObjProp;

const CompanyInviteModal = ({
  open,
  handleClose,
  user,
  companyInviteFinish,
  getCompanies,
  companyInviteToken,
  companies,
  invitationDetails
}) => {
  const [trans] = useTranslation(['quickStart', 'main', 'auth']);
  const [invalidInvitation, setInvalidInvitation] = useState({expired: false, alreadyOnboarded: false});
  const [isFinished, setIsFinished] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [error, setError] = useState(false);

  const t = (key, options) => trans(`invite.${key}`, options);

  const tMain = (key, options) => trans(`main:${key}`, options);

  useEffect(() => {
    if (isFinished || (companies.length === 0 && user.has_any_company)) return;
    if (invitationDetails && !objectHelpers.isEmptyObject(invitationDetails)) {
      const alreadyOnboarded = Boolean(companies.find(c => c.company_id === invitationDetails.company_id));
      if (alreadyOnboarded) {
        setInvalidInvitation({
          ...invalidInvitation,
          alreadyOnboarded
        });
        error && setError(undefined);
        setContentLoading(false);
      } else if (open || !contentLoading) {
        completeInvitation();
      }
    } else {
      setInvalidInvitation({
        ...invalidInvitation,
        expired: true
      });
    }
  }, [companies, invitationDetails, isFinished, open]); // eslint-disable-line react-hooks/exhaustive-deps

  const {isErrorState, errorContent} = useMemo(() => {
    const isErrorState = Boolean(invalidInvitation.expired || error);
    const t = (key, options) => trans(`invite.${key}`, options);
    let description = t('invitationExpiredDescription');
    let title = t('invitationExpired');
    if (error) {
      description = error;
      title = t('invitationError');
    }
    return {
      isErrorState,
      errorContent: {description, title}
    }
  }, [invalidInvitation, error, trans]);

  const onClose = () => handleClose && handleClose();

  const getUserDetails = () => {
    if (user) {
      const email = gObjProp(user, 'email');
      let fullName = gObjProp(user, 'full_name');
      let firstName = '';
      let lastName = '';

      if (fullName) {
        fullName = fullName.split(' ');
        firstName = fullName[0];
        if (fullName.length > 1) lastName = fullName[1];
      }

      return {
        user_first_name: firstName,
        user_last_name: lastName,
        email
      }
    }
    return undefined;
  }

  const completeInvitation = () => {
    const userDetails = getUserDetails();
    const data = {
      ...objectHelpers.trimAllValues(userDetails),
      invitation_token: companyInviteToken
    };
    setContentLoading(true);
    companyInviteFinish(
      data,
      () => {
        getCompanies();
        setContentLoading(false);
        setIsFinished(true);
      },
      (err) => {
        setError(err.message);
        setContentLoading(false);
      }
    );
  }

  const getInvDetails = (key) => invitationDetails ? gObjProp(invitationDetails, key, '') : '';

  const alreadyOnboarded = (invalidInvitation?.alreadyOnboarded || false) && user?.has_any_company;

  const companyName = getInvDetails('company_name');

  return (
    <StyledCompanyInviteModal
      centered={true}
      closeIcon={isErrorState}
      onCancel={isErrorState ? onClose : undefined}
      header={isErrorState}
      footer={null}
      open={open}
      width={408}
    >
      <SpinSmall spinning={contentLoading}>
        {isErrorState ? (
          <StyledCompanyInviteModalSpace
            direction='vertical'
            size='large'
          >
            <ErrorSvgImage />
            <StyledCompanyInviteModalSpace
              direction='vertical'
              size='middle'
            >
              <h1>{errorContent.title}</h1>
              <p>{errorContent.description}</p>
            </StyledCompanyInviteModalSpace>
          </StyledCompanyInviteModalSpace>
        ) : (
          <StyledCompanyInviteModalSpace
            direction='vertical'
            size='large'
          >
            <StyledCompanyInviteModalHeader>
              <Avatar src={getInvDetails('company_logo')} size={180}>
                {textHelpers.getInitials(companyName)}
              </Avatar>
              <Avatar src={logo} size={64} />
            </StyledCompanyInviteModalHeader>
            <StyledCompanyInviteModalSpace
              direction='vertical'
              size='middle'
            >
              <h1>{t(alreadyOnboarded ? 'alreadyOnboarded' : 'welcomeToWithLess')}</h1>
              <p>
                {t(alreadyOnboarded ? 'welcomeBack1' : 'congratulations1', {user_name: gObjProp(user, 'full_name')})}
                <br/>
                {t(alreadyOnboarded ? 'welcomeBack2' : 'congratulations2')} <b>{companyName}</b>.
              </p>
            </StyledCompanyInviteModalSpace>
            <StyledCompanyInviteModalButton
              onClick={onClose}
              type='primary'
              size='large'
            >
              {tMain('close')}
            </StyledCompanyInviteModalButton>
          </StyledCompanyInviteModalSpace>
        )}
      </SpinSmall>
    </StyledCompanyInviteModal>
  );
}

CompanyInviteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  const {user, companies} = state.user;
  const {companyInviteToken, invitationDetails} = state.auth;
  return {
    companies,
    companyInviteToken,
    invitationDetails,
    user
  }
}

const mapDispatchToProps = {
  companyInviteFinish: authActions.companyInviteFinish,
  getCompanies: userActions.getCompanies
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInviteModal);
