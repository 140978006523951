import styled from 'styled-components';
import {Tabs} from 'antd';

const StyledSubscriptionTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 0;
    .ant-tabs-tab {
      border-color: ${props => props.theme.colors.neutral_3};
      font-weight: 500;
      padding: 9px 32px;

      &.ant-tabs-tab-active {
        background-color: ${props => props.theme.colors.neutral_1};
      }

      &:not(.ant-tabs-tab-active) {
        background: unset;
        border-color: transparent;
      }
    }
  }
  .ant-tabs-content-holder {
    background-color: ${props => props.theme.colors.neutral_1};
    border: 1px solid ${props => props.theme.colors.neutral_3};
    border-top: unset;
    padding: 24px;
    min-height: calc(100vh - 64px - (16px * 2) - (24px * 2) - 40px);
  }
  .expanded-table-icon,
  .ellipsis-icon {
    display: flex;
    height: 24px;
    width: 24px;
    
    path {
      fill: ${props => props.theme.colors.neutral_7};
      stroke: ${props => props.theme.colors.neutral_7};
    }
  }
  .expanded-table-icon {
    transition: .3s;
    
    &.expanded {
      fill: ${props => props.theme.colors.primary_5};
      stroke: ${props => props.theme.colors.primary_5};
      transform: rotate(180deg);
    }
  }
  
  .vendor-details {
    .vendor-name {
      text-wrap: wrap;
    }
  }
`;

export {
  StyledSubscriptionTabs
}
