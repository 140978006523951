import React from 'react';
import parse from 'html-react-parser';
import supportedCurrencies from '../../i18n/supported-currencies_dict.json';
import {localizationHelpers} from './localization.helpers';
import {objectHelpers} from './object.helpers';
import {textHelpers} from './text.helpers';


export const amountHelpers = {
  getAmountBasedOnSeparator: (value, separatorId, showFractionalPart = true) => {
    const parts = Number(value).toFixed(2).toString().split('.');
    let str, separator;
    const integerPart = parts[0];
    const fractionalPart = parts[1];
    switch(separatorId) {
      case 'SID02':
        str = textHelpers.separateString(integerPart, ',');
        separator = '.';
        break;
      case 'SID03':
        str = textHelpers.separateString(integerPart, ' ');
        separator = '.';
        break;
      case 'SID04':
        str = textHelpers.separateString(integerPart, '.');
        separator = ',';
        break;
      case 'SID01':
      default:
        str = textHelpers.separateString(integerPart, ' ');
        separator = ',';
        break;
    }

    return !showFractionalPart && fractionalPart === "00" ? str : `${str}${separator}${fractionalPart}`;
  },
  getAmountWithCurrencyCode: (value, countryCode = undefined, currencyCode = undefined, showCurrencyCode = true, showFractionalPart = true) => {
    let curCode = 'EUR';
    let separatorId;
    if (currencyCode && !objectHelpers.isNaV(currencyCode)) {
      curCode = currencyCode;
    } else if (countryCode) {
      let country = localizationHelpers.getCountryByCode(countryCode);
      if (country) {
        separatorId = country.separator_id;
        curCode = country.currency_alphabetic_code;
      }
    }
    const cCodeObj = amountHelpers.getCurrencyCode(curCode);
    const cCode = parse(cCodeObj.symbol);
    const priceSymbol = value < 0 ? '-' : '';
    const price = amountHelpers.getAmountBasedOnSeparator(Math.abs(value), separatorId, showFractionalPart);
    if (showCurrencyCode) {
      return cCodeObj.position === 'after' ? <>{priceSymbol}{price}{cCode}</> : <>{priceSymbol}{cCode}{price}</>;
    } else {
      return price;
    }
  },
  getCurrencyCode: (currency) => {
    currency = currency.toUpperCase();
    return objectHelpers.getObjProp(supportedCurrencies, currency, 'EUR');
  },
}
