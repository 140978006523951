import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {StyledTableContainer, StyledTransactionTable, TotalSpend} from './StyledTransactionTable';
import {amountHelpers, dateHelpers, transactionsHelpers} from '../../../../../../utils/helpers';
import {expenseInvoicesStatusConstants} from '../../../../../../constants';

const groupedPropName = 'isYearlyGrouping';

const isGroupedRow = (row) => row[groupedPropName];

const gMV = amountHelpers.getAmountWithCurrencyCode;

const TransactionTable = ({
  data,
  loading,
  onExpenseUpdate,
  onRowClick,
  onInvoiceEdit,
  totalSpend,
  selectedRowIndex,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'transactions']);

  const onAttachmentClick = ({e, transaction}) => {
    e.stopPropagation();
    if (transaction && transaction.expense) {
      const invoiceStatus = transaction.expense.expense_invoice_status;
      if (!(expenseInvoicesStatusConstants.PENDING === invoiceStatus)) return;
      onInvoiceEdit && onInvoiceEdit(transaction);
    }
  }

  const columns = [
    {
      dataIndex: 'created_date',
      title: t('date'),
      render: (date, record) => {
        const isGrouped = isGroupedRow(record);
        return dateHelpers.getDateWithMonth(date, isGrouped ? 'YYYY' : 'DD MMM');
      }
    },
    {
      dataIndex: 'type',
      title: t('card'),
      render: (_, record) => !isGroupedRow(record) && transactionsHelpers.getTransactionType(record)
    },
    {
      dataIndex: 'source',
      title: t('transactions:source'),
    },
    {
      dataIndex: 'amount',
      title: t('amount'),
      render: (_, record) => {
        let {amount} = record;
        if (isGroupedRow(record)) {
          return <span className='total-amount'>{gMV(amount)}</span>;
        } else {
          let className = '';
          const isReturnedTransaction = transactionsHelpers.isReturnedTransaction(record);
          const isIncoming = record.is_incoming || false;
          if (!isIncoming && !isReturnedTransaction) className = `${className} danger-text`;
          if (isReturnedTransaction) className = `${className} returned-price`;
          return amount ? isIncoming ? gMV(amount) : <span className={className}>-{gMV(Math.abs(amount))}</span> : null;
        }
      }
    },
    {
      dataIndex: 'attachment',
      render: (_, transaction) => transactionsHelpers.getTransactionAttachmentIcon({transaction, onClick: onAttachmentClick}),
      width: 32
    }
  ];

  const handleOnRowClick = (e, record, index) => {
    const {id} = record;
    if (isGroupedRow(record)) return;

    onRowClick && onRowClick(id, index);
  };

  const getRowClassName = (record, index) => transactionsHelpers.getRowClassName({
    groupedPropName,
    index,
    record,
    selectedRowIndex
  });

  return (
    <StyledTableContainer {...rest}>
      <TotalSpend>
        {t('total')} {t('spend')}: <span className='price'>{gMV(totalSpend)}</span>
      </TotalSpend>
      <StyledTransactionTable
        columns={columns}
        dataSource={data}
        loading={loading}
        onRow={(record, index) => ({
          onClick: (e) => handleOnRowClick(e, record, index)
        })}
        pagination={false}
        rowClassName={getRowClassName}
        rowKey='created_date'
      />
    </StyledTableContainer>
  );
}

TransactionTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  onExpenseUpdate: PropTypes.func,
  onRowClick: PropTypes.func,
  onInvoiceEdit: PropTypes.func,
  selectedRowIndex: PropTypes.any,
  totalSpend: PropTypes.number
}

TransactionTable.defaultProps = {
  data: [],
  loading: false,
  totalSpend: 0
}

export default TransactionTable;

