import React, {useState} from 'react';
import {Space} from 'antd';
import {useTranslation} from 'react-i18next';
import axios from 'axios';
import {connect} from 'react-redux';
import {UnmountClosed} from 'react-collapse';
import SpinSmall from '../../components/SpinSmall';
import PageContainer from '../../components/PageContainer';
import PageDocumentDetails from '../../components/PageDocumentDetails';
import BalancePageHeader from '../../components/pages/TransactionsPage/BalancePageHeader/BalancePageHeader';
import {
  StyledSupportPageCard,
  StyledSupportPageContainer,
  StyledSupportPageHeaderSpace,
  StyledSupportPageFullWidthSpace,
  StyledSupportPageLinkCard,
  StyledSupportPageLinkSpace,
  StyledSupportPageFormInformationText
} from './StyledSupportPage';
import {ReactComponent as FaqSvgImage} from '../../static/images/pages/support/people-questions.svg';
import {BookIcon, ExternalLinkIcon, FAQIcon} from '../../icons';
import SupportForm from '../../components/pages/SupportPage/SupportForm';
import {objectHelpers} from '../../utils/helpers';

const freshdeskApiKey = process.env.REACT_APP_FRESHDESK_API_KEY;
const freshdeskHostname = process.env.REACT_APP_FRESHDESK_HOST_NAME;
const backofficeHostname = process.env.REACT_APP_BACKOFFICE_LINK;

const SupportPage = ({
  company,
  employee,
}) => {
  const [t] = useTranslation(['support', 'main']);
  const [formProps, setFormProps] = useState({
    loading: false,
    message: null
  });

  const breadcrumbs = [
    {title: t('title')}
  ];

  const links = [
    {
      icon: <FAQIcon />,
      link: 'https://withless.freshdesk.com/support/solutions/folders/103000146799',
      title: 'FAQ'
    },
    {
      icon: <BookIcon />,
      link: 'https://withless.freshdesk.com/support/solutions/folders/103000146800',
      title: t('guides')
    }
  ];

  const getFormattedDescription = (description) => {
    let value = description;
    const {id: companyId} = company;
    const backofficeCompanyUrl = `${backofficeHostname}/companies/${companyId}`;
    if (companyId) {
      value += (
        `<br /><br /><b>Company Details:</b>` +
        `<ul>` +
          `<li>Name: ${company.name}</li>` +
          `<li>ID: ${companyId}</li>` +
          `<li>Company Details Page: <a href="${backofficeCompanyUrl}" target="_blank">${backofficeCompanyUrl}</a></li>` +
        `</ul>`
      );
    }
    return value;
  }

  const handleSubmitForm = (fields, successCallback, errorCallback) => {
    const description = getFormattedDescription(fields.description);

    const data = {
      type: fields.type,
      subject: fields.subject,
      description,
      status: 2,
      priority: 2,
      email: fields.email || employee.email
    }
    setFormProps({...formProps, loading: true});

    axios.post(
      `https://${freshdeskHostname}.freshdesk.com/api/v2/tickets`,
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        },
        auth: {
          username: freshdeskApiKey,
          password: 'X'
        }
      }
    )
      .then((resp) => {
        setFormProps({
          ...formProps,
          loading: false,
          message: {type: 'success', message: t('supportForm.successMessage')}
        });
        successCallback && successCallback(resp);
      })
      .catch((error) => {
        const {data} = error.response;
        const message = data?.description || null;
        let errors = null;
        if (data.hasOwnProperty('errors')) {
          errors = {}
          data.errors.forEach(err => {
            errors[err.field] = err.message
          });
        }

        errorCallback && errorCallback({errors});
        setFormProps({
          ...formProps,
          loading: false,
          message: message && objectHelpers.isEmptyObject(errors) ? {type: 'error', message} : null
        });
      });
  }

  return (
    <PageContainer>
      <PageDocumentDetails title={t('main:pageTitles.support')} />
      <BalancePageHeader breadcrumbs={breadcrumbs} />
      <StyledSupportPageContainer>
        <StyledSupportPageFullWidthSpace
          align='start'
          size='large'
        >
          <Space direction='vertical' size='middle'>
            <StyledSupportPageHeaderSpace
              direction='vertical'
              size='small'
            >
              <h3>{t('howCanWeHepYou')}</h3>
              <p>{t('getTheAnswersYouNeed')}</p>
            </StyledSupportPageHeaderSpace>
            <SpinSmall spinning={formProps.loading}>
              <StyledSupportPageCard>
                <Space
                  direction='vertical'
                  size={0}
                  style={{width: '100%'}}
                >
                  <SupportForm
                    onSubmit={handleSubmitForm}
                  />
                  <UnmountClosed isOpened={formProps.message !== null}>
                    <StyledSupportPageFormInformationText className={formProps.message?.type || ''}>
                      {formProps.message?.message || ''}
                    </StyledSupportPageFormInformationText>
                  </UnmountClosed>
                </Space>
              </StyledSupportPageCard>
            </SpinSmall>
          </Space>
          <StyledSupportPageLinkSpace
            direction='vertical'
            size='large'
          >
            <FaqSvgImage />
            <StyledSupportPageFullWidthSpace size='large'>
              {links.map((link, index) => (
                <StyledSupportPageLinkCard
                  key={index}
                  href={link.link}
                  target='_blank'
                  rel='noreferrer'
                >
                  <StyledSupportPageCard>
                    <Space size='small'>
                      <span className='link-icon'>
                        {link.icon}
                      </span>
                      <span className='link-title'>
                        {link.title}
                      </span>
                    </Space>
                    <span className='link-external-icon'>
                      <ExternalLinkIcon />
                    </span>
                  </StyledSupportPageCard>
                </StyledSupportPageLinkCard>
              ))}
            </StyledSupportPageFullWidthSpace>
          </StyledSupportPageLinkSpace>
        </StyledSupportPageFullWidthSpace>
      </StyledSupportPageContainer>
    </PageContainer>
  );
}

const mapStateToProps = state => {
  const {employee} = state.user;
  const {company} = state;
  return {
    employee,
    company: {
      id: company.id,
      name: company.name
    }
  }
}

export default connect(mapStateToProps, null)(SupportPage);
