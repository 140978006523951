import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Button, Checkbox, Select, Space} from 'antd';
import {
  StyledTableFilters,
  StyledTableFiltersCardSelect,
  StyledTableFiltersDivider,
  StyledTableFiltersSearch,
  StyledTableFiltersSegmented
} from './StyledTableFilters';
import ExportModal from '../ExportModal';
import AuthenticationWindow from '../../CardsPage/AuthenticationWindow/AuthenticationWindow';
import {useIsMount} from '../../../../hooks';
import {expenseActions, transactionActions} from '../../../../state/actions';
import {formHelpers, scaHelpers, systemHelpers} from '../../../../utils/helpers';
import {cardPaymentStatusesConstants, SCAActionsConstants} from '../../../../constants';
import {firebaseEvents} from '../../../../snippets/firebase';

const {logEvent} = systemHelpers;

const SCA_OPERATION_NAME = SCAActionsConstants.TRANSACTIONS_DOWNLOAD_PDF_AND_CSV_ACTION;

const getPaymentStatusValue = (value) => value === 'all' ? [] : [value];

const TableFilters = ({
  enableExport,
  onFilter,
  searchParams,

  cards,
  isAdmin,
  isKnownEmployee,

  downloadTransactions,
  downloadZipInvoices,
}) => {
  const [t] = useTranslation(['main', 'cards']);
  const [exportModalProps, setExportModalProps] = useState({loading: false, open: false});
  const [authWindowProps, setAuthWindowProps] = useState({open: false});
  const [exportDetails, setExportDetails] = useState({range: undefined, format: null});

  const [filter, setFilter] = useState({
    cards: [],
    paymentStatus: 'all',
    search: '',
    types: [],
    users: []
  });
  const isMounted = !useIsMount();

  useEffect(() => {
    if (isKnownEmployee) {
      if (searchParams && searchParams.hasOwnProperty('card')) {
        const {card: selectedCard} = searchParams;
        if (!filter.types.includes('card') && !filter.cards.includes(selectedCard)) {
          setFilter({
            ...filter,
            cards: [...filter.cards, selectedCard],
            types: [...filter.types, 'card']
          });
        }
      } else {
        handleFilter(filter);
      }
    }
  }, [isKnownEmployee]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => isMounted && handleFilter(filter), [filter]); // eslint-disable-line react-hooks/exhaustive-deps

  const cardOptions = useMemo(() => formHelpers.getCardsOptions(cards), [cards]);

  const paymentStatusOptions = [
    {label: t('all'), value: 'all'},
    {label: t('cardPaymentStatuses.declined'), value: cardPaymentStatusesConstants.DECLINED}
  ];

  const handleFilter = (filterData) => {
    const {cards, users, search, paymentStatus, types} = filterData;
    let isCardTransaction = undefined;
    if (types.length === 1) isCardTransaction = types[0] === 'card';

    onFilter && onFilter({
      card_id_in: cards,
      is_card_transaction: isCardTransaction,
      search,
      payment_status: getPaymentStatusValue(paymentStatus),
      user_id_in: users
    });
  }

  const handleOnSearch = (value) => setFilter({...filter, search: value});

  const handleOnSelectCard = (value, options) => {
    setFilter({
      ...filter,
      cards: options.map(o => o.id)
    });
  }

  const handleChangePaymentStatus = (value) => setFilter({...filter, paymentStatus: value});

  const handleExportTransactions = (data) => {
    const {format, date} = data;

    const errorCallback = () => setExportModalProps({...exportModalProps, open: true, loading: false});
    setExportModalProps({...exportModalProps, loading: true});
    if (format === 'zip') {
      const query = {
        year_month: date.format('YYYY-MM'),
      };
      downloadZipInvoices(
        query,
        (data) => {
          window.open(data.url, 'download');
          handleCancelExportModal();
          logEvent(firebaseEvents.TRANSACTIONS_DOWNLOAD_ZIP_INVOICES);
        },
        errorCallback
      )
    } else {
      const timeFormat = 'YYYY-MM-DD';
      const startMonthDate = date.startOf('month').format(timeFormat);
      const endMonthDate = date.endOf('month').format(timeFormat);
      const query = {
        date_gte: startMonthDate,
        date_lte: endMonthDate,
        month: date.format('MM'),
        year: date.format('YYYY'),
        file_format: format
      };
      const downloadLogEvents = {
        'csv': firebaseEvents.TRANSACTIONS_EXPORT_CSV,
        'pdf': firebaseEvents.TRANSACTIONS_EXPORT_PDF
      }
      const downloadLogEvent = downloadLogEvents[format];
      downloadTransactions({
        headers: scaHelpers.getAuthHeaders(SCA_OPERATION_NAME),
        query,
        successCallback: (data) => {
          window.open(data.url, 'download');
          handleCancelExportModal();
        },
        errorCallback: (response) => {
          scaHelpers.SCAResponseCallback({
            response,
            scaCallback: (scaProps) => {
              setAuthWindowProps({...authWindowProps, ...scaProps});
              setExportDetails(data);
              downloadLogEvent && logEvent(downloadLogEvent);
            },
            errorCallback
          });
        }
      });
    }
  }

  const handleOnExportClick = () => setExportModalProps({...exportModalProps, open: true});

  const handleCancelExportModal = () => setExportModalProps({...exportModalProps, open: false, loading: false});

  const handleCloseAuthModal = () => {
    setAuthWindowProps({...authWindowProps, open: false});
    setExportModalProps({...exportModalProps, loading: false});
  }

  const handleOnSuccessAuth = () => {
    handleCloseAuthModal();
    handleExportTransactions(exportDetails);
  }

  return (
    <StyledTableFilters>
      <Space align='center' size='small'>
        <StyledTableFiltersSearch
          allowClear
          onSearch={handleOnSearch}
          placeholder={t('search')}
          size='large'
        />
        <StyledTableFiltersCardSelect
          allowClear
          maxTagCount={1}
          menuItemSelectedIcon={false}
          mode='multiple'
          onChange={handleOnSelectCard}
          placeholder={t('cards:tableFilters.placeholder.cards')}
          showSearch
          size='large'
        >
          {cardOptions.map(({label, value, id}) => (
            <Select.Option key={value} value={value} id={id}>
              <Space align='center' size='small'>
                <Checkbox
                  checked={filter.cards.includes(id)}
                  readable={false}
                />
                {label}
              </Space>
            </Select.Option>
          ))}
        </StyledTableFiltersCardSelect>
        <StyledTableFiltersSegmented
          onChange={handleChangePaymentStatus}
          options={paymentStatusOptions}
          value={filter.paymentStatus}
        />
      </Space>
      {(isAdmin && enableExport) && (
        <>
          <Space size={0}>
            <StyledTableFiltersDivider type='vertical' />
            <Button
              onClick={handleOnExportClick}
              size='large'
              type='primary'
            >
              {t('export')}
            </Button>
          </Space>

          <ExportModal
            {...exportModalProps}
            handleCancel={handleCancelExportModal}
            handleOk={handleExportTransactions}
            title={`${t('export')} ${t('transactions')}`}
          />

          <AuthenticationWindow
            {...authWindowProps}
            handleCancel={handleCloseAuthModal}
            onSuccess={handleOnSuccessAuth}
            operationName={SCA_OPERATION_NAME}
          />
        </>
      )}

    </StyledTableFilters>
  );
}

TableFilters.propTypes = {
  enableExport: PropTypes.bool,
  onFilter: PropTypes.func,
  searchParams: PropTypes.object,
}

TableFilters.defaultProps = {
  enableExport: false
}

const mapStateToProps = state => {
  const {isKnownEmployee, isAdmin} = state.user;
  const {cards} = state.banking;
  return {
    cards,
    isKnownEmployee,
    isAdmin
  }
}

const mapDispatchToProps = {
  downloadTransactions: transactionActions.downloadTransactions,
  downloadZipInvoices: expenseActions.downloadZipInvoices
}

export default connect(mapStateToProps, mapDispatchToProps)(TableFilters);
