export const colorsStyles = {
  // additional colors
  additional_bg_1: '#F3F3F4',
  additional_bg_2: '#F8F8F9',
  additional_apple: '#3AF2AE',
  additional_neon: '#5CFFE9',
  additional_sky: '#32DCDC',

  // functional colors
  functional_danger: '#FF4D4F',
  functional_danger_d: '#FF7875',
  functional_warning: '#FAAD14',
  functional_success: '#52C41A',

  // status colors
  status_purple: '#722ED1',
  status_green: '#25C41A',
  status_blue: '#1890FF',
  status_yellow: '#FFCC00',
  status_grey: '#D9D9D9',

  // neutral colors
  neutral_1: '#FFF',
  neutral_2: '#F3F3F3',
  neutral_3: '#E6E7EA',
  neutral_4: '#DADCDF',
  neutral_5: '#CDD0D5',
  neutral_6: '#B5B8BF',
  neutral_7: '#9CA1AA',
  neutral_8: '#515A6B',
  neutral_9: '#202B40',
  neutral_10: '#07132B',
  neutral_11: '#1f1f1f',

  // primary colors
  primary_1: '#ECF7FB',
  primary_2: '#C6E7F2',
  primary_3: '#B3DFEE',
  primary_4: '#8CD0E6',
  primary_5: '#66C0DD',
  primary_6: '#40B0D5',
  primary_7: '#2A9BC1',
  primary_8: '#1688AE',
  primary_9: '#005F7F',
  primary_10: '#00465D',
}