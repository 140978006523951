import React, {useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Button, Space, Switch, Tooltip} from 'antd';
import {Collapse, UnmountClosed} from 'react-collapse';
import {InfoCircleOutlined} from '@ant-design/icons';
import {
  StyledHeaderDetails,
  StyledHeaderDetailsInfo,
  StyledHeaderDetailsMoreInfo,
  StyledHeaderDetailsMoreInfoSpace,
  StyledHeaderDetailsTag,
  StyledHeaderDetailsOverlappingAppsTooltip
} from './StyledHeaderDetails';
import EditButton from '../../../EditButton';
import Spin from '../../../Spin';
import SubscriptionInfo from '../SubscriptionInfo';
import SubscriptionInfoForm from '../SubscriptionInfoForm';
import {subscriptionActionConstants, subscriptionStatusesConstants} from '../../../../constants';
import {ChevronDownIcon, KeyIcon, TrashIcon} from '../../../../icons';
import {serviceStatsActions, subscriptionActions} from '../../../../state/actions';
import {objectHelpers, subscriptionsHelpers, systemHelpers} from '../../../../utils/helpers';
import {firebaseEvents} from '../../../../snippets/firebase';

const {ACTIVE, AUDITED, DELETED, INACTIVE, CARD_ERROR, PENDING, RESTRICTED, TERMINATED,
  TRIAL, UNMANAGED} = subscriptionStatusesConstants;

const {ACTIVATE_DELETED, ACTIVATE_PAUSED, AUTHORIZE, DELETE, PAUSE} = subscriptionActionConstants;

const detailsMoreInfoSpaceProps = {
  direction: 'vertical',
  size: 4
}

const {logEvent} = systemHelpers;
const {getObjProp: gObjProp} = objectHelpers;


const infoCircleIcon = <InfoCircleOutlined size={13} />;

const HeaderDetails = ({
  employee,
  isAdmin,
  data,
  handleUpdateData,
  onAction,
  getRecommendedServices,
  updateSubscription,
}) => {
  const [t] = useTranslation(['main', 'subscriptions']);
  const [isExpandedDetails, setIsExpandedDetails] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditFormLoading, setIsEditFormLoading] = useState(false);
  const [recommendedServices, setRecommendedServices] = useState({loading: false, data: []});
  const [isLoadingSwitch, setIsLoadingSwitch] = useState(false);

  const trans = (key, options) => t(`subscriptions:${key}`, options);

  const {
    isCanceled,
    isDisabled,
    isInCompleted,
    enableDelete,
    enableEdit,
    enablePause,
  } = useMemo(() => {
    const statusCode = data?.status || undefined;
    const checkStatus = (value) => typeof value === 'object' ? value.includes(statusCode) : statusCode === value;
    const isCanceled = checkStatus([DELETED, TERMINATED]);
    const isInCompleted = checkStatus([AUDITED, RESTRICTED, UNMANAGED]);
    const isAuthorized = checkStatus([ACTIVE, CARD_ERROR, INACTIVE, PENDING, TRIAL]);
    const enableEdit = isAdmin || (gObjProp(employee, 'id') === gObjProp(data?.owner, 'id'));
    return {
      isAuthorized,
      isCanceled: checkStatus([DELETED, TERMINATED]),
      isDisabled: checkStatus(INACTIVE),
      isDeleted: checkStatus(DELETED),
      isInCompleted,
      enableDelete: !isCanceled && (isAuthorized || isInCompleted),
      enableEdit,
      enablePause: checkStatus([ACTIVE, TRIAL]),
    }
  }, [data, employee, isAdmin]);

  const editFormRef = useRef(null);

  const {
    description,
    shortDescription,
    tags
  } = useMemo(() => {
    const service = gObjProp(data, 'service');
    const gServiceProp = (key) => gObjProp(service, key);
    const description = gServiceProp('description') || '-';
    let shortDescription = description.split(' ').slice(0, 9).join(' ');
    if (description !== shortDescription) shortDescription = `${shortDescription}...`;
    return {
      description,
      shortDescription,
      tags: gServiceProp('tags') || []
    }
  }, [data]);

  const {isExistOverlappingApps, overlappingApps} = useMemo(() => {
    const services = recommendedServices.data;
    return {
      isExistOverlappingApps: recommendedServices.loading ? false : Boolean(services.length),
      overlappingApps: services
    }
  }, [recommendedServices]);

  useEffect(() => {
    const serviceName = subscriptionsHelpers.getServiceName(data);

    if (!serviceName) return;

    setRecommendedServices({...recommendedServices, loading: false});

    const successHandle = (data) => {
      setRecommendedServices({
        ...recommendedServices,
        loading: false,
        data
      });
    }
    const errorHandle = () => successHandle([]);

    getRecommendedServices(
      serviceName,
      successHandle,
      errorHandle
    );
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const finishSwitchLoading = () => setIsLoadingSwitch(false);

  const handleAction = (action, successCallback, errorCallback) => onAction && onAction({action, successCallback, errorCallback});

  const handleOnEdit = () => enableEdit && setIsEditMode(true);

  const handleOnCancel = () => {
    setIsEditMode(false);
    setIsEditFormLoading(false);
  }

  const handleOnSave = () => editFormRef.current && editFormRef.current.click();

  const handleSubmit = (fields, successCallback, errorCallback) => {
    setIsEditFormLoading(true);

    updateSubscription({
      id: data.id,
      data: fields,
      successCallback: (resp) => {
        setIsEditFormLoading(false);
        setIsEditMode(false);
        successCallback && successCallback(resp);
        handleUpdateData && handleUpdateData();
        logEvent(firebaseEvents.SUBSCRIPTIONS_SUBSCRIPTION_UPDATE_SERVICE);
      },
      errorCallback: (resp) => {
        setIsEditFormLoading(false);
        errorCallback && errorCallback(resp);
      }
    });
  }

  const handleChangeState = () => {
    const action = enablePause ? PAUSE : isDisabled ? ACTIVATE_PAUSED : ACTIVATE_DELETED;
    setIsLoadingSwitch(true);
    const changeStateLogEvents = {
      [ACTIVATE_DELETED]: firebaseEvents.SUBSCRIPTIONS_SUBSCRIPTION_ACTIVATE,
      [ACTIVATE_PAUSED]: firebaseEvents.SUBSCRIPTIONS_SUBSCRIPTION_ACTIVATE,
      [PAUSE]: firebaseEvents.SUBSCRIPTIONS_SUBSCRIPTION_DISABLE
    };
    const changeStateLogEvent = changeStateLogEvents[action];
    handleAction(
      action,
      () => {
        finishSwitchLoading();
        changeStateLogEvent && logEvent(changeStateLogEvent);
      },
      () => {
        finishSwitchLoading();
      });
  }

  const overTitle = (
    <StyledHeaderDetailsOverlappingAppsTooltip>
      <Space size='small' direction='vertical'>
        <p>
          {trans('tooltips.overlappingAppsAlertTitle')}
        </p>
        <div className='tooltip-list'>
          <div className='tooltip-list-title'>{trans('overlappingApps')}</div>
          <div className='tooltip-list-services'>
            {subscriptionsHelpers.getTooltipServices(overlappingApps)}
          </div>
        </div>
      </Space>
    </StyledHeaderDetailsOverlappingAppsTooltip>
  );

  const getStateSwitch = () => {
    if (enablePause || (isDisabled || isCanceled)) {
      const label = t(`cardStatuses.${enablePause ? 'active' : 'paused'}`);
      return (
        <Space align='center' size='small'>
          <Switch
            checked={enablePause}
            loading={isLoadingSwitch}
            onChange={handleChangeState}
          />
          {label}
        </Space>
      )
    }
    return null;
  }

  const getActionButtons = () => {
    if (isEditMode) {
      return (
        <Space size='middle'>
          <Button
            onClick={handleOnCancel}
            size='middle'
          >
            {t('cancel')}
          </Button>
          <Button
            onClick={handleOnSave}
            type='primary'
            size='middle'
          >
            {t('save')}
          </Button>
        </Space>
      )
    } else {
      const stateSwitch = getStateSwitch();
      return (
        <Space size='middle'>
          {/* Display overlapping apps alert*/}
          {isExistOverlappingApps && (
            <Tooltip
              overlayInnerStyle={{width: 280}}
              placement='bottomLeft'
              title={overTitle}
            >
              <Button
                danger
                ghost
                className='overlapping-alert'
                size='middle'
                type='primary'
              >
                <Space size='small' align='center'>
                  <span>{trans('overlappingApps')}</span>
                  {infoCircleIcon}
                </Space>
              </Button>
            </Tooltip>
          )}

          {stateSwitch}

          {/* Enable "Authorize" if status is AUDITED/RESTRICTED/UNMANAGED */}
          {isInCompleted && (
            <Button
              className='authorize-btn'
              onClick={() => handleAction(AUTHORIZE)}
              type='text'
            >
              <Space align='center' size='small'>
                <KeyIcon />
                {trans('authorize')}
              </Space>
            </Button>
          )}

          {/* Enable "Edit" for admin and subscription owner */}
          {enableEdit && (
            <EditButton
              className='edit-btn'
              onClick={handleOnEdit}
            />
          )}

          {/* Enable "Delete" if subscription is not canceled and AUDITED/RESTRICTED/UNMANAGED */}
          {enableDelete && (
            <Tooltip title={t('delete')}>
              <Button
                className='delete-btn'
                icon={<TrashIcon />}
                onClick={() => handleAction(DELETE)}
                size='small'
                type='text'
              />
            </Tooltip>
          )}
        </Space>
      )
    }
  }

  const actionButtons = getActionButtons();

  return (
    <StyledHeaderDetails>
      <Spin size='small' spinning={isEditFormLoading}>
        <StyledHeaderDetailsInfo>
          {!isEditMode ? (
            <SubscriptionInfo data={data} />
          ) : (
            <SubscriptionInfoForm
              onSubmit={handleSubmit}
              subscription={data}
              ref={editFormRef}
            />
          )}
          {actionButtons}
        </StyledHeaderDetailsInfo>
      </Spin>
      <StyledHeaderDetailsMoreInfo>
        <Space size='middle' align='start'>
          <StyledHeaderDetailsMoreInfoSpace {...detailsMoreInfoSpaceProps}>
            <label>{t('description')}</label>
            {shortDescription !== description ? (
              <>
                <Collapse isOpened={isExpandedDetails}>
                  <p>{description}</p>
                </Collapse>
                <Collapse isOpened={!isExpandedDetails}>
                  <p>{shortDescription}</p>
                </Collapse>
              </>
            ) : (
              <p>{description}</p>
            )}
          </StyledHeaderDetailsMoreInfoSpace>
          <Button type='link' onClick={() => setIsExpandedDetails(!isExpandedDetails)}>
            <Space size='small' align='center'>
              <span>{t('showMoreDetails')}</span>
              <ChevronDownIcon
                className={`expanded-icon ${isExpandedDetails ? 'expanded' : ''}`}
              />
            </Space>
          </Button>
        </Space>

        <UnmountClosed className='expanded-details' isOpened={isExpandedDetails}>
          <StyledHeaderDetailsMoreInfoSpace {...detailsMoreInfoSpaceProps}>
            <label>{t('features')}</label>
            <Space size='small' wrap={true}>
              {tags.length ? tags.map((tag, key) => (
                <StyledHeaderDetailsTag key={key}>
                  {tag}
                </StyledHeaderDetailsTag>
              )) : '-'}
            </Space>
          </StyledHeaderDetailsMoreInfoSpace>
          {isExistOverlappingApps && (
            <StyledHeaderDetailsMoreInfoSpace
              align='center'
              size='small'
            >
              <label>
                {trans('overlappingApps')}
                <Tooltip title={trans('tooltips.overlappingApps')}>
                  {infoCircleIcon}
                </Tooltip>
              </label>
              {subscriptionsHelpers.getServicesAvatarGroup(overlappingApps)}
            </StyledHeaderDetailsMoreInfoSpace>
          )}
        </UnmountClosed>
      </StyledHeaderDetailsMoreInfo>
    </StyledHeaderDetails>
  );
}

HeaderDetails.propTypes = {
  data: PropTypes.object,
  handleUpdateData: PropTypes.func,
  onAction: PropTypes.func,
}

const mapStateToProps = state => {
  const {country, currentCurrency} = state.company;
  const {employee, isAdmin} = state.user;
  return {
    companyCountry: country,
    currentCurrency,
    employee,
    isAdmin
  }
}

const mapDispatchToProps = {
  getRecommendedServices: serviceStatsActions.getRecommendedServices,
  updateSubscription: subscriptionActions.updateSubscription,
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderDetails);
