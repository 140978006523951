import styled from 'styled-components';
import {Space} from 'antd';
import {rgba} from 'polished';
import PageLoading from '../../components/PageLoading';
import Steps from '../../components/Steps';
import {typographyStyles} from '../../utils/styles';

const FOOTER_HEIGHT = '104px';

const StyledUserInvitePage = styled.div`
  .page-form-content-container {
    & > div {
      padding: 48px 0;
      
      & > a {
        margin: 0 48px;
      }
    }
  }
  .image-content-container {
    & > div {
      text-align: center;
      width: 100%;
      
      & > div {
        align-items: center;
        display: flex;
        justify-content: center;
      }
    }
  }
  
  .form-content-container {
    max-height: calc(100vh - (56px + (48px * 2) + 40px + 104px));
    overflow-y: auto;
    padding: 0 48px;
    align-items: unset;
  }
  
  .form-content {
    display: flex;
    flex-direction: column;
    max-width: 610px;
    text-align: left;
    
    .ant-form {
      margin-top: 0;
    }
    
    h2 {
      ${typographyStyles.heading.h1};
      color: ${props => props.theme.colors.neutral_10};
      margin-bottom: 0;
      text-align: left;
    }
    
    p {
      margin-bottom: 8px;
      font-size: 16px;
      color: ${props => props.theme.colors.neutral_10};
      text-align: left;
    }
    
    .ant-select-selection-item {
      display: inline-flex;
    }
    
    a {
      ${typographyStyles.body.regular};
      color: ${props => props.theme.colors.neutral_7};
      text-decoration: underline;
    }
  }
  
  .lr-documents-content {
    flex-direction: column;
    
    form {
      display: flex;
      flex-direction: row;
    }
  }
  
  .image-content-step-1 {
    align-items: center;
    background-color: ${props => rgba(props.theme.colors.additional_neon, 0.1)};
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
`;

const StyledUserInvitePageResultSpace = styled(Space)`
  width: 100%;
  .ant-space-item {
    text-align: center;
    width: 100%;
    max-width: 410px;
    button {
      height: 56px;
      width: 100%;
    }
    h3 {
      ${typographyStyles.heading.h1};
      margin-bottom: 0;
    }
    p {
      color: ${props => props.theme.colors.neutral_7};
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      max-width: ${props => props.variant === 'success' ? '250px' : 'unset'};
      margin: 0 auto 8px auto;
    }
  }
`;

const StyledUserInvitePageLoader = styled(PageLoading)`
  width: 100% !important;
  & img {
    width: 72px;
  }
`;

const StyledUserInvitePageFooter = styled.div`
  border-top: 1px solid ${props => props.theme.colors.neutral_4};
  height: ${FOOTER_HEIGHT};
  padding: 24px 40px;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  
  .ant-btn {
    height: 56px;
    width: 180px;
  }
`;

const StyledUserInvitePageSteps = styled(Steps)`
  display: flex;
  justify-content: space-between !important;
  margin: 0 auto 40px auto;
  width: 100%;
  
  .filled {
    background-color: ${props => props.theme.colors.primary_6};
  }
`;

const StyledUserInvitePageHelpText = styled.div`
  color: ${props => props.theme.colors.functional_danger};
  min-height: 18px;
  padding-top: 24px;
  font-size: 16px;
`;

export {
  StyledUserInvitePage,
  StyledUserInvitePageResultSpace,
  StyledUserInvitePageLoader,
  StyledUserInvitePageFooter,
  StyledUserInvitePageSteps,
  StyledUserInvitePageHelpText
}
