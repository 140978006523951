import styled from 'styled-components';
import {Space} from 'antd';

const StyledTeamUserPage = styled.div`
  padding: 24px;
  min-height: calc(100vh - 40px - 66px);
`;

const StyledTeamUserPageSpace = styled(Space)`
  width: 100%;
`;

export {
  StyledTeamUserPage,
  StyledTeamUserPageSpace
};
