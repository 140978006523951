import {billingRequest, request} from '../services/request';
import {backendEndpoints, billingEndpoints} from '../../api';
import {apiHelpers} from '../../utils/helpers';

const {getUrl} = apiHelpers;

const errorHandlerProps = {disableAlert: true};

const getTransfersList = ({query, successCallback, errorCallback} = {}) => {
  return (dispatch) => {
    request.get({
      dispatch,
      url: backendEndpoints.GET_BILLING_TRANSFERS_LIST,
      query,
      successCallback,
      errorCallback,
      errorHandlerProps
    });
  }
}

const getTransferAttachmentFile = ({id, successCallback, errorCallback} = {}) => {
  return (dispatch) => {
    request.get({
      dispatch,
      url: getUrl(backendEndpoints.GET_BILLING_TRANSFER_ATTACHMENT_FILE, {id}),
      successCallback,
      errorCallback
    });
  }
}

const getRewardsList = ({query, successCallback, errorCallback} = {}) => {
  return (dispatch) => {
    billingRequest.get({
      dispatch,
      query,
      url: billingEndpoints.GET_REWARDS_LIST,
      successCallback,
      errorCallback,
      errorHandlerProps,
    });
  }
}

const getRewardsTotals = ({successCallback, errorCallback} = {}) => {
  return (dispatch) => {
    billingRequest.get({
      dispatch,
      url: billingEndpoints.GET_REWARDS_TOTALS,
      successCallback,
      errorCallback,
      errorHandlerProps,
    });
  }
}

const claimAllRewards = ({successCallback, errorCallback} = {}) => {
  return (dispatch) => {
    billingRequest.post({
      dispatch,
      url: billingEndpoints.POST_CLAIM_ALL_REWARDS,
      successCallback,
      errorCallback
    });
  }
}

export const billingActions = {
  getTransfersList,
  getTransferAttachmentFile,

  getRewardsList,
  getRewardsTotals,
  claimAllRewards,
};
