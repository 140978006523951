import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Button, Result, Space} from 'antd';
import CodeAuth from '../../../CodeAuth';
import {companyActions, userActions} from '../../../../state/actions';
import {KEYLESS_APP_URL, SCASessionTypesConstants} from '../../../../constants';
import {
  StyledKeylessEnrollModalResultContainer,
  StyledKeylessEnrollModal,
  StyledKeylessEnrollModalContent,
  StyledKeylessEnrollModalLink,
  StyledKeylessEnrollModalLinkSpace
} from './StyledKeylessEnrollModal';
import {StyledSmsAuthModalContainer} from '../SmsAuthModal/StyledSmsAuthModal';
import KeylessQRCode from '../KeylessQRCode';
import keylessLogoImg from '../../../../static/images/keyless-auth/keyless-logo.png';
import appleStoreLogoImg from '../../../../static/images/keyless-auth/apple-store-logo.png';
import googlePlayLogoImg from '../../../../static/images/keyless-auth/google-play-logo.png';
import {ReactComponent as AuthInitialSvgImage} from '../../../../static/images/keyless-auth/auth-start.svg';
import {ReactComponent as ErrorSvgImage} from '../../../../static/images/error.svg';
import {ReactComponent as SuccessSvgImage} from '../../../../static/images/success.svg';
import {elementHelpers} from '../../../../utils/helpers';

let interval = null;
const defaultVerificationDetail = {sessionId: null, errors: null};
const {getVisibleClassName} = elementHelpers;

const steps = {
  INITIAL: 'initial',
  AUTHENTICATOR_DETAILS: 'authenticator_details',
  CONFIRM_AUTHENTICATION: 'confirm_authentication',
  SCAN_QR_CODE: 'scan_qr_code',
  AUTH_RESULT: 'auth_result'
}

const {INITIAL, AUTHENTICATOR_DETAILS, CONFIRM_AUTHENTICATION, SCAN_QR_CODE, AUTH_RESULT} = steps;

const KeylessEnrollModal = ({
  open,
  onCancel,
  onSendCode,
  onFinish,
  phoneNumber,
  initialSeconds,
  startPhoneVerification,
  endPhoneVerification
}) => {
  const [t] = useTranslation(['main', 'cards']);
  const [seconds, setSeconds] = useState(initialSeconds);
  const [verificationDetails, setVerificationDetails] = useState(defaultVerificationDetail);
  const [isDisabled, setIsDisabled] = useState(false);
  const [canStartCount, setCanStartCount] = useState(false);
  const [authResult, setAuthResult] = useState({completed: false, status: 'success'});
  const [step, setStep] = useState(INITIAL);
  const [smsSessionKey, setSmsSessionKey] = useState(null);

  const isConfirmAuthStep = useMemo(() => step === CONFIRM_AUTHENTICATION, [step]);

  const authResultStatus = useMemo(() => authResult.status, [authResult]);

  const trans = (key) => t(`cards:modal.keylessEnroll.${key}`);

  const twoFATrans = (key) => t(`cards:modal.2fa.${key}`);

  const finishLoading = () => setIsDisabled(false);

  const handleStartVerification = () => {
    !isConfirmAuthStep && setStep(CONFIRM_AUTHENTICATION);
    setIsDisabled(true);
    setCanStartCount(false);
    startPhoneVerification({
      data: {
        sca_session_type: SCASessionTypesConstants.FIVE_MINUTES_SESSION
      },
      successCallback: (data) => {
        setSeconds(initialSeconds);
        finishLoading();
        setVerificationDetails({...verificationDetails, sessionId: data.session_id, errors: null});
        setCanStartCount(true);
      },
      errorCallback: finishLoading
    });
  }

  const afterClose = () => {
    setSeconds(initialSeconds);
    setVerificationDetails(defaultVerificationDetail);
    setStep(INITIAL);
    smsSessionKey && setSmsSessionKey(null);
    authResult.completed && setAuthResult({...authResult, completed: false});
  }

  const startTimer = () => {
    interval = setInterval(() => {
      seconds > 0 ? setSeconds(seconds - 1) : clearInterval(interval);
    }, 1000);
  };

  useEffect(() => {
    if (seconds > 0 && open && isConfirmAuthStep) {
      startTimer();
      return () => interval && clearInterval(interval);
    }
  }, [seconds, open, isConfirmAuthStep]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSuccessValidate = (data) => {
    setIsDisabled(true);
    endPhoneVerification(
      {code: data.code, session_id: verificationDetails.sessionId},
      (data) => {
        setSmsSessionKey(data.session_key);
        setStep(SCAN_QR_CODE);
        finishLoading();
      },
      (errors) => {
        setVerificationDetails({...verificationDetails, errors});
        finishLoading();
      }
    );
  }

  const handleGetAuthenticator = () => setStep(AUTHENTICATOR_DETAILS);

  const handleTryAuthAgain = () => setStep(SCAN_QR_CODE);

  const onCodeKeyChange = () => verificationDetails.errors && setVerificationDetails({...verificationDetails, errors: null});

  const handleFinishQRCode = (status) => {
    setAuthResult({completed: true, status});
    setStep(AUTH_RESULT);
    if (status === 'success') {
      setTimeout(() => onFinish && onFinish(), [2000]);
    }
  }

  const getFooter = () => {
    const variants = {
      [INITIAL]: [
        <Button
          key='send-code'
          onClick={onSendCode}
          size='large'
        >
          {trans('initial.sendCode')}
        </Button>,
        <Button
          key='get-authenticator'
          onClick={handleGetAuthenticator}
          type='primary'
          size='large'
        >
          {trans('initial.getAuthenticator')}
        </Button>
      ],
      [AUTHENTICATOR_DETAILS]: [
        <Button
          key='done-auth-details'
          onClick={handleStartVerification}
          type='primary'
          size='large'
        >
          {t('done')}
        </Button>
      ]
    }
    if (step === AUTH_RESULT && authResult.completed && authResult.status === 'error') {
      return [
        <Button
          key='cancel'
          onClick={onCancel}
          size='large'
        >
          {t('cancel')}
        </Button>,
        <Button
          key='try-again'
          onClick={handleTryAuthAgain}
          type='primary'
          size='large'
        >
          {t('tryAgain')}
        </Button>
      ];
    }
    return variants[step] || null;
  }

  const footer = getFooter();

  return (
    <StyledKeylessEnrollModal
      afterClose={afterClose}
      className={isDisabled && 'disabled'}
      footer={footer}
      onCancel={onCancel}
      open={open}
      title={isConfirmAuthStep ? twoFATrans('enterCode') : ''}
      zIndex={1001}
      width={isConfirmAuthStep ? 504 : 448}
    >
      {/* INITIAL step */}
      <StyledKeylessEnrollModalContent className={getVisibleClassName('', step === INITIAL)}>
        <AuthInitialSvgImage />
        <h3>{trans('initial.title')}</h3>
        <p>{trans('initial.description')}</p>
      </StyledKeylessEnrollModalContent>

      {/* AUTHENTICATOR_DETAILS step */}
      <StyledKeylessEnrollModalContent className={getVisibleClassName('', step === AUTHENTICATOR_DETAILS)}>
        <img src={keylessLogoImg} alt='keyless app logo' />
        <h3>{trans('authenticatorDetails.title')}</h3>
        <p>{trans('authenticatorDetails.description1')}<br/>{trans('authenticatorDetails.description2')}</p>
        <StyledKeylessEnrollModalLinkSpace size='middle' direction='vertical'>
          <StyledKeylessEnrollModalLink href={KEYLESS_APP_URL.GOOGLE_PLAY} target='_blank' rel='noreferrer'>
            <Button>
              <Space size='small' align='center'>
                <img className='logo' src={googlePlayLogoImg} alt='google play'/> Google Play
              </Space>
            </Button>
          </StyledKeylessEnrollModalLink>
          <StyledKeylessEnrollModalLink href={KEYLESS_APP_URL.APPLE_STORE} target='_blank' rel='noreferrer'>
            <Button>
              <Space size='small'>
                <img className='logo' src={appleStoreLogoImg} alt='apple store'/> App Store
              </Space>
            </Button>
          </StyledKeylessEnrollModalLink>
        </StyledKeylessEnrollModalLinkSpace>
      </StyledKeylessEnrollModalContent>

      {/* CONFIRM_AUTHENTICATION step */}
      <StyledSmsAuthModalContainer className={getVisibleClassName('', isConfirmAuthStep)}>
        <h3>{twoFATrans('enterCode')}</h3>
        <p className='text-center'>
          {twoFATrans('pleaseTypeCode')}
          <br />
          <b>{phoneNumber}</b>
        </p>
        {open && (
          <CodeAuth
            autofocus={true}
            numberCount={6}
            onSuccess={handleSuccessValidate}
            onResend={handleStartVerification}
            canStartCount={canStartCount}
            additionalErrors={verificationDetails.errors}
            onKeyChange={onCodeKeyChange}
          />
        )}
      </StyledSmsAuthModalContainer>

      {/* SCAN_QR_CODE step */}
      <StyledKeylessEnrollModalContent className={getVisibleClassName('', step === SCAN_QR_CODE)}>
        {step === SCAN_QR_CODE && (
          <KeylessQRCode
            onFinish={handleFinishQRCode}
            smsSessionKey={smsSessionKey}
          >
            <h3>{trans('scanQRCode.title')}</h3>
            <p>{trans('scanQRCode.description')}</p>
          </KeylessQRCode>
        )}
      </StyledKeylessEnrollModalContent>

      <StyledKeylessEnrollModalResultContainer className={getVisibleClassName('', step === AUTH_RESULT)}>
        <Result
          icon={authResultStatus === 'success' ? <SuccessSvgImage /> : <ErrorSvgImage />}
          status={authResultStatus}
          subTitle={trans(`authResult.${authResultStatus}.description`)}
          title={trans(`authResult.${authResultStatus}.title`)}
        />
      </StyledKeylessEnrollModalResultContainer>
    </StyledKeylessEnrollModal>
  );
}

KeylessEnrollModal.propTypes = {
  initialSeconds: PropTypes.number,
  onCancel: PropTypes.func.isRequired,
  onSendCode: PropTypes.func,
  onFinish: PropTypes.func,
  open: PropTypes.bool.isRequired
}

KeylessEnrollModal.defaultProps = {
  initialSeconds: 59
}

const mapStateToProps = state => {
  const {phone_number: phoneNumber} = state.user.user;
  return {
    phoneNumber
  }
}

const mapDispatchToProps = {
  startPhoneVerification: userActions.startPhoneVerification,
  endPhoneVerification: userActions.endPhoneVerification,
  getCompanyInformation: companyActions.getCompanyInformation
};

export default connect(mapStateToProps, mapDispatchToProps)(KeylessEnrollModal);
