import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {AutoComplete, Avatar, Button, Input, Space} from 'antd';
import {CloseOutlined, InfoCircleOutlined} from '@ant-design/icons';
import './SubscriptionSelect.css';
import {
  StyledSubscriptionSelect,
  StyledSubscriptionSelectDetailsSpace,
  StyledSubscriptionSelectDropdownHint
} from './StyledSubscriptionSelect';
import useDebounce from '../../../../hooks/useDebounce';
import {objectHelpers, subscriptionsHelpers, textHelpers} from '../../../../utils/helpers';


const getServices = (services, optionPropName) => services.map(s => ({name: s.name || s.domain, value: s[optionPropName] || s.name || s.domain, logo: s.logo_url}));

const SubscriptionSelect = ({
  autocompleteProps,
  delay,
  enableCustomServiceDetails,
  inputProps,
  minSearchSymbolsLength,
  onChange,
  onSearch,
  optionPropName,
  services,
  value,
  ...rest
}) => {
  const [fieldValue, setFieldValue] = useState(value);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [counterChange, setCounterChange] = useState(0);
  const [searchedValue, setSearchedValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [t] = useTranslation('subscriptions');

  useDebounce(
    () => {
      if (counterChange > 0 && onSearch && (searchedValue === '' || searchedValue.length >= minSearchSymbolsLength)) onSearch(searchedValue);
      if (counterChange === 0) setCounterChange(1);
    },
    delay,
    [searchedValue]
  );

  useEffect(() => {
    value !== undefined && setFieldValue(value);
  }, [value]);

  useEffect(() => {
    const options = getServices(subscriptionsHelpers.getServicesOptionsList(services, searchedValue), optionPropName);
    setServiceOptions(options);
  }, [optionPropName, services]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!enableCustomServiceDetails && fieldValue) setInputValue(fieldValue);
  }, [fieldValue, enableCustomServiceDetails]);

  const handleUpdateValue = (value = '') => {
    const option = serviceOptions.find(s => s.value === value);
    setFieldValue(value);
    onChange && onChange(value, option);
    setServiceOptions([]);
  }

  const getServiceDetails = () => {
    if (fieldValue !== '') {
      const serviceObj = services.find(s => [s.url, s.name].includes(fieldValue));
      let category, logo;
      let name = fieldValue;
      if (serviceObj) {
        const gObjProp = (key) => objectHelpers.getObjProp(serviceObj, key);
        category = gObjProp('category');
        logo = gObjProp('logo_url');
        name = gObjProp('name') || gObjProp('url');
      }

      return (
        <StyledSubscriptionSelectDetailsSpace>
          <Space size={12}>
            <Avatar src={logo} size={80}>
              {textHelpers.getInitials(name)}
            </Avatar>
            <Space direction='vertical' size={0}>
              <h3 className='name'>{name}</h3>
              {category && <p className='category'>{category}</p>}
            </Space>
          </Space>
          <Button
            icon={<CloseOutlined />}
            onClick={() => handleUpdateValue()}
            type='text'
            shape='circle'
          />
        </StyledSubscriptionSelectDetailsSpace>
      )
    }
  }

  const options = serviceOptions.map(s => ({
    label: (
      <Space>
        <Avatar src={s.logo}>
          {textHelpers.getInitials(s.name)}
        </Avatar>
        <span>{s.name}</span>
      </Space>
    ),
    value: s.value
  }));

  const onInputChange = (e) => {
    const {value} = e.target;
    setSearchedValue(value);
    setInputValue(value);
  }

  return (
    <StyledSubscriptionSelect {...rest}>
      {fieldValue === '' || !enableCustomServiceDetails ? (
        <AutoComplete
          {...autocompleteProps}
          dropdownRender={(menu) => (
            <>
              <StyledSubscriptionSelectDropdownHint>
                <InfoCircleOutlined size={14} />
                <p>{t('subscriptionSelectHint')}</p>
              </StyledSubscriptionSelectDropdownHint>
              {menu}
            </>
          )}
          onSelect={handleUpdateValue}
          options={options}
          popupClassName='subscription-select-popup'
          value={inputValue}
        >
          <Input
            {...inputProps}
            onChange={onInputChange}
          />
        </AutoComplete>
      ) : getServiceDetails()}
    </StyledSubscriptionSelect>
  );
}

SubscriptionSelect.propTypes = {
  delay: PropTypes.number,
  enableCustomServiceDetails: PropTypes.bool,
  minSearchSymbolsLength: PropTypes.number,
  onSearch: PropTypes.func,
  optionPropName: PropTypes.oneOf(['domain', 'name']),
  services: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      logo: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string
    })
  ),
  value: PropTypes.string,
}

SubscriptionSelect.defaultProps = {
  delay: 1000,
  enableCustomServiceDetails: true,
  minSearchSymbolsLength: 3,
  optionPropName: 'name',
  services: []
}

export default SubscriptionSelect;

