import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import StatusLine from '../StatusLine';
import {subscriptionsHelpers} from '../../../../utils/helpers';
import StyledSubscriptionStatusMark from './StyledSubscriptionStatusMark';

const SubscriptionStatusMark = ({
  statusCode,
  ...rest
}) => {
  const [t] = useTranslation('main');
  const status = subscriptionsHelpers.getSubscriptionStatus({statusCode});

  return (
    <StyledSubscriptionStatusMark {...rest}>
      <StatusLine color={status.color} /> {t(status.key)}
    </StyledSubscriptionStatusMark>
  );
}

SubscriptionStatusMark.propTypes = {
  statusCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}

export default SubscriptionStatusMark;
