import styled from 'styled-components';
import Toolbar from "../../Toolbar";

const StyledPageHeader = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.additional_bg_2};
  border-bottom: 1px solid ${props => props.theme.colors.neutral_4};
  display: flex;
  justify-content: space-between;
  min-height: 62px;
  padding: 0 24px;
  transition: .3s ease-in-out;

  &:hover {
    background-color: ${props => props.theme.colors.neutral_1};
  }
`;

const StyledHeaderContent = styled.div`
  align-items: center;
  display: flex;
`;

const StyledHeaderButtons = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
`;

const StyledHeaderButton = styled.div`
  align-items: center;
  display: flex;

  &:not(:last-of-type) {
    margin-right: 10px;
  }
`;

const StyledHeaderToolbar = styled(Toolbar)`
  border-left: 1px solid ${props => props.theme.colors.neutral_3};
  height: 65px;
  margin-left: 16px;
  padding-left: 16px;
`;


export {
  StyledPageHeader,
  StyledHeaderContent,
  StyledHeaderButtons,
  StyledHeaderButton,
  StyledHeaderToolbar
}
