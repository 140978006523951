import styled from 'styled-components';
import {Space} from 'antd';
import {bordersStyles, typographyStyles} from '../../../../utils/styles';


const getDotColor = (props) => {
  const {variant} = props;
  const {colors} = props.theme;
  const actionColors = {
    'free': colors.functional_success,
    'paid': colors.functional_danger,
    'discovered': colors.neutral_7,
  }
  return actionColors[variant] || 'unset';
}

const StyledSubscriptionsCounter = styled(Space)`
  ${bordersStyles.default};
  background-color: ${props => props.theme.colors.neutral_1};
  padding: 12px 8px 12px 16px;
  width: 100%;
  
  & > .ant-space-item {
    width: 100%;
  }
  
  .ant-space-item {
    display: flex;
  }
`;

const StyledSubscriptionsCounterItem = styled.div`
  ${typographyStyles.body.regular};
  
  align-items: center;
  color: ${props => props.theme.colors.neutral_10};
  display: flex;
  justify-content: space-between;
  width: 100%;
  
  .count {
    ${typographyStyles.body.medium};
  }
`;

const StyledSubscriptionsCounterItemDot = styled.span`
  display: inline-block;
  height: 100%;
  position: relative;
  width: 6px;

  &:before {
    background-color: ${props => getDotColor(props)};
    border-radius: 50%;
    content: ' ';
    display: inline-block;
    height: 6px;
    width: 6px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}`;

export {
  StyledSubscriptionsCounter,
  StyledSubscriptionsCounterItem,
  StyledSubscriptionsCounterItemDot
}