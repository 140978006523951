import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import BalancePageHeader from '../../components/pages/TransactionsPage/BalancePageHeader/BalancePageHeader';
import TeamTabs from '../../components/pages/TeamPage/TeamTabs';
import PageContainer from '../../components/PageContainer';
import PageDocumentDetails from '../../components/PageDocumentDetails';
import {StyledTeamPageContainer} from './StyledTeamPage';
import SpinSmall from '../../components/SpinSmall';
import useSearchParams from '../../hooks/useSearchParams';
import {teamActions} from '../../state/actions';
import {objectHelpers} from '../../utils/helpers';

const gObjProp = objectHelpers.getObjProp;

const TeamPage = ({
  getEmployeesTotal
}) => {
  const [t] = useTranslation(['teams', 'main']);
  const {params} = useSearchParams();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [total, setTotal] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const addUser = params.get('add-user');
    if (addUser === '1') setOpenAddModal(true);
  }, [params]);

  useEffect(() => {
    handleUpdateTotal();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const finishLoading = () => loading && setLoading(false);

  const handleUpdateTotal = () => {
    getEmployeesTotal(
      (data) => {
        setTotal({
          active: gObjProp(data, 'active_qty', 0),
          all: gObjProp(data, 'total_qty', 0),
          disabled: gObjProp(data, 'disabled_qty', 0),
          external: gObjProp(data, 'external_qty', 0),
        });
        finishLoading();
      },
      finishLoading
    );
  }

  return (
    <PageContainer>
      <PageDocumentDetails title={t('main:pageTitles.team')} />
      <BalancePageHeader breadcrumbs={[{title: t('title')}]} />
      <StyledTeamPageContainer>
        <SpinSmall spinning={total === null && loading}>
          <TeamTabs
            handleUpdateTotal={handleUpdateTotal}
            openInviteModal={openAddModal}
            total={total}
            totalLoading={loading}
          />
        </SpinSmall>
      </StyledTeamPageContainer>
    </PageContainer>
  );
}

const mapDispatchToProps = {
  getEmployeesTotal: teamActions.getEmployeesTotal
}

export default connect(null, mapDispatchToProps)(TeamPage);
