import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Collapse} from 'react-collapse';
import {Button, Form, Select} from 'antd';
import {StyledChangeMethodModal, StyledSegmented, StyledCardDetailsSpace} from './StyledChangeMethodModal';
import {StyledPaymentDetailsInput} from '../PaymentDetails/StyledPaymentDetails';
import SpinSmall from '../../../../../SpinSmall';
import {EuroIcon} from '../../../../../../icons';
import {
  subscriptionFormFields,
  subscriptionFormValues,
  subscriptionPaymentTypesConstants,
} from '../../../../../../constants';
import {CardLimitPeriodTypeOptions, formHelpers, objectHelpers} from '../../../../../../utils/helpers';

const {CARD, FREE, NOT_APPLICABLE, WIRE} = subscriptionPaymentTypesConstants;

const {budgetLimitFieldName, cardLimitFieldName, cardLimitPeriodFieldName} = subscriptionFormFields;
const {defaultBudgetLimitSubscription, defaultCardLimitPeriod} = subscriptionFormValues;

const {Item} = Form;

const gObjProp = objectHelpers.getObjProp;

const ChangeMethodModal = ({
  defaultPaymentType,
  employeeEmail,
  employees,
  excludePaymentTypes,
  handleCancel,
  handleOk,
  loading,
  open,
  subscription,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'subscriptions']);
  const [paymentType, setPaymentType] = useState('');
  const [form] = Form.useForm();
  const [initialFormValues, setInitialFormValues] = useState({
    [budgetLimitFieldName]: defaultBudgetLimitSubscription,
    [cardLimitFieldName]: defaultBudgetLimitSubscription,
    [cardLimitPeriodFieldName]: defaultCardLimitPeriod
  });
  const [cardLimitPeriodOptions, ] = useState(CardLimitPeriodTypeOptions());
  const [ownerOptions, setOwnerOptions] = useState([]);

  const {isCardPaymentType, isOtherPaymentType} = useMemo(() => ({
    isCardPaymentType: paymentType === CARD,
    isOtherPaymentType: [NOT_APPLICABLE, WIRE].includes(paymentType)
  }),[paymentType]);

  const trans = (key) => t(`subscriptions:modal.changePaymentMethod.${key}`);

  const paymentOptions = useMemo(() => {
    return [
      {label: t('subscriptionPaymentTypes.0'), value: FREE},
      {label: `${defaultPaymentType === CARD ? `${t('new')} ` : ''} ${t('subscriptionPaymentTypes.1')}`, value: CARD},
      {label: t('subscriptionPaymentTypes.3'), value: WIRE},
      {label: t('subscriptionPaymentTypes.2'), value: NOT_APPLICABLE},
    ].filter(o => !excludePaymentTypes.includes(o.value));
  }, [excludePaymentTypes, defaultPaymentType, t]);

  useEffect(() => open && setPaymentType(defaultPaymentType), [open, defaultPaymentType]);

  useEffect(() => {
    const options = formHelpers.getEmployeeOptions({employees, employeeEmail, t});
    setOwnerOptions(options);
  }, [employees]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const cardOwner = gObjProp(subscription, 'card_owner');
    const fieldValues = {
      ...initialFormValues,
      [budgetLimitFieldName]: gObjProp(subscription, budgetLimitFieldName) || defaultBudgetLimitSubscription,
      [cardLimitFieldName]: gObjProp(subscription, cardLimitFieldName) || defaultBudgetLimitSubscription,
      [cardLimitPeriodFieldName]: gObjProp(subscription, cardLimitPeriodFieldName) || defaultCardLimitPeriod,
      card_owner: cardOwner ? gObjProp(cardOwner, 'email') : ''
    };
    setInitialFormValues(fieldValues);
    form.setFieldsValue(fieldValues);
  }, [subscription]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (fields) => {
    let data = {
      payment_type: paymentType
    }
    if (isCardPaymentType) {
      data = {
        ...data,
        card_owner: fields.card_owner,
        [cardLimitFieldName]: fields[cardLimitFieldName],
        [cardLimitPeriodFieldName]: fields[cardLimitPeriodFieldName]
      }
    } else if (isOtherPaymentType) {
      data = {
        ...data,
        [budgetLimitFieldName]: fields[budgetLimitFieldName]
      }
    }
    handleOk && handleOk(data);
  }

  const disabled = (defaultPaymentType === paymentType && defaultPaymentType !== CARD) || loading;

  const footer = [
    <Button
      key='submit'
      disabled={disabled}
      onClick={() => form.submit()}
      size='large'
      type='primary'
    >
      {t('confirm')}
    </Button>
  ];

  const requiredErrorMessage = t('validation.fieldIsRequired');

  return (
    <StyledChangeMethodModal
      forceRender
      footer={footer}
      open={open}
      onCancel={handleCancel}
      title={trans('title')}
      width={472}
      {...rest}
    >
      <SpinSmall spinning={loading}>
        <StyledSegmented
          block={true}
          onChange={setPaymentType}
          options={paymentOptions}
          size='large'
          value={paymentType}
        />
        <Form
          className='pt-20'
          initialValues={initialFormValues}
          form={form}
          layout='vertical'
          onFinish={handleSubmit}
          requiredMark={false}
        >
          <Collapse isOpened={isCardPaymentType}>
            <Item
              label={`${t('card')} ${t('Owner')}`}
              name='card_owner'
              required
            >
              <Select
                options={ownerOptions}
                size='large'
              />
            </Item>
            <StyledCardDetailsSpace size='middle'>
              <Item
                label={t('subscriptions:cardLimit')}
                name={cardLimitFieldName}
                required
              >
                <StyledPaymentDetailsInput
                  addonBefore={<EuroIcon />}
                  min={1}
                  size='large'
                  type='number'
                />
              </Item>
              <Item
                label=' '
                name={cardLimitPeriodFieldName}
                rules={[{required: true, message: requiredErrorMessage}]}
              >
                <Select
                  options={cardLimitPeriodOptions}
                  size='large'
                />
              </Item>
            </StyledCardDetailsSpace>
          </Collapse>
          <Collapse isOpened={isOtherPaymentType}>
            <Item
              label={t('amount')}
              name={budgetLimitFieldName}
              required
            >
              <StyledPaymentDetailsInput
                addonBefore={<EuroIcon />}
                min={1}
                size='large'
                type='number'
              />
            </Item>
          </Collapse>
        </Form>
      </SpinSmall>
    </StyledChangeMethodModal>
  );
}

ChangeMethodModal.propTypes = {
  defaultPaymentType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  excludePaymentTypes: PropTypes.arrayOf(PropTypes.number),
  subscription: PropTypes.shape({
    card_owner: PropTypes.shape({
      email: PropTypes.string
    })
  }),
  handleCancel: PropTypes.func,
  handleOk: PropTypes.func,
  loading: PropTypes.bool,
  open: PropTypes.bool,
}

ChangeMethodModal.defaultProps = {
  excludePaymentTypes: [],
  loading: false,
  open: false
}

const mapStateToProps = state => {
  const {email: employeeEmail} = state.user.employee;
  const {employees} = state.company;
  return {
    employeeEmail,
    employees
  }
}

export default connect(mapStateToProps, null)(ChangeMethodModal);
