import {Avatar, Space} from 'antd';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {userRolesConstants} from '../../constants';
import {cardsHelpers} from './cards.helpers';
import {textHelpers} from './text.helpers';

const {ADMIN, MANAGER, SUPPORT, USER} = userRolesConstants;

const userRoles = {
  [ADMIN]: 'userRoles.admin',
  [MANAGER]: 'userRoles.manager',
  [USER]: 'userRoles.user',
  [SUPPORT]: 'userRoles.support',
}

export const OptionsList = (optionsArray, activeValue, emptyLabel) => {
  const [t] = useTranslation('main');
  let options = Object.entries(optionsArray);
  if (emptyLabel) {
    options = [
      [null, emptyLabel],
      ...options
    ];
  }
  return options.map(option => {
    let value = option[0];
    let intValue = parseInt(value);
    if (value === `${intValue}`) value = intValue;
    const label = option[1];
    return {
      isActive: activeValue === value,
      value,
      label: t(label)
    }
  });
}

export const UserRoleOptions = (excludeSupport = true) => {
  const [t] = useTranslation('main');
  const roles = Object.entries(userRoles);
  let excludedRoles = [MANAGER];
  if (excludeSupport) excludedRoles.push(SUPPORT);
  return roles
    .filter(r => !excludedRoles.includes(parseInt(r[0])))
    .map(role => {
    const value = role[0];
    return {
      value,
      label: t(role[1])
    }
  });
};

export const formHelpers = {
  getEmployeeOptions: ({employees, employeeEmail, t}) => {
    return employees.map(employee => {
      const {email, logo, full_name: name} = employee;
      return {
        value: email,
        label: (
          <Space className='d-flex-child'>
            <Avatar src={logo || undefined}>
              {textHelpers.getInitials(name)}
            </Avatar>
            {`${name}${employeeEmail === email ? ` (${t('main:you')})` : ''}`}
          </Space>
        ),
        optionLabel: name
      }
    })
  },
  getFormServerErrorFields: (errors) => {
    let fields = [];
    if (errors.hasOwnProperty('errors')) {
      const formErrors = errors.errors;
      fields = Object.keys(formErrors).map(key => ({
        name: key,
        errors: [formErrors[key]]
      }));
    }
    return fields;
  },
  getFormLogoErrorField: (errors, logoFieldName) => {
    let field = {};
    let errorMessage = errors?.message;
    if (errorMessage === 'Can not detect the mime type') {
      field = {name: logoFieldName, errors: [errorMessage]};
    }
    return field;
  },
  getUpdatedFormValues: ({initialValues, submittedValues, excludedFields = []}) => {
    let values = {};
    Object.keys(submittedValues).forEach(key => {
      if (excludedFields.includes(key)) return;
      const submittedValue = submittedValues[key];
      const initialValue = initialValues[key];
      const isEqual = typeof submittedValue === 'object'
        ? JSON.stringify(submittedValue) === JSON.stringify(initialValue)
        : submittedValue === initialValue;
      if (!isEqual) values = {...values, [key]: submittedValues[key]};
    });
    return values;
  },
  getTagOptions: (tags) => tags.map(t => ({label: t.tag_name, value: t.tag_name})),
  getCardsOptions: (cards, symbolsLength) => cards
    .filter(card => card.masked_pan)
    .map(card => ({label: cardsHelpers.hideCardNumber(card.masked_pan, symbolsLength), value: card.masked_pan, id: card.id})),
}
