export const locationHelpers = {
  getUrlFilename: (url, fileNameMaxLength = 18) => {
    let filename = url.replace(/#.*$/, '').replace(/\?.*$/, '').split('/');
    filename = filename[filename.length - 1];
    if (filename.length > fileNameMaxLength) {
      const splitFileName = filename.split('.');
      filename = `${splitFileName[0].slice(0, fileNameMaxLength)}...${splitFileName[1]}`;
    }
    return filename;
  },
  isValidUrl: (url) => {
    const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
    return regex.test(url);
  },
  getLocationHash: (location) => {
    let {hash} = location;
    if (hash) {
      hash = hash.replace('#', '');
    }
    return hash;
  },
  getHash: (location) => {
    let hash = null;
    if (location) {
      hash = location.hash.replace('#', '').split('?')[0];
    }
    return hash;
  },
  getSearchParams: (params = {}) => {
    let value;
    let query = {};
    Object.keys(params).forEach(key => {
      value = params[key];
      if (!['', null, undefined].includes(value)) {
        query = { ...query, [key]: value };
      }
    });
    return query;
  },
  getSearchParamsFromLocation: (location) => {
    const {hash, search} = location;
    let query = search;
    if (hash) {
      const spittedUrl = hash.split('?');
      query = spittedUrl.length > 1 ? spittedUrl[1] : '';
    }
    return Object.fromEntries(new URLSearchParams(query));
  }
}
