import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'antd';
import {useTranslation} from 'react-i18next';
import CodeInputField from '../../../CodeInputField';
import {StyledCodeAuthForm, StyledButtonsSpace} from './StyledCodeAuthForm';
import {dateHelpers} from '../../../../utils/helpers';

let interval = null;

const CodeAuthForm = ({
  autofocus,
  disabled,
  initialSeconds,
  onBack,
  onResend,
  onSuccess,
  errors,
  showResend,
  ...rest
}) => {
  const [seconds, setSeconds] = useState(initialSeconds);

  const [t] = useTranslation('main');
  const [formErrors, setFormErrors] = useState(undefined);
  const ref = React.useRef(null);

  useEffect(() => errors && setFormErrors(errors), [errors]);

  const startTimer = () => {
    interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);
  };

  useEffect(() => {
    startTimer();
    return () => {
      if (interval) clearInterval(interval);
    }
  }, [seconds]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSuccess = (data) => {
    if (formErrors !== undefined) setFormErrors(undefined);
    onSuccess && onSuccess(data);
  }

  const handleReset = () => {
    const resetBtn = ref.current.querySelector('.reset');
    resetBtn && resetBtn.click();
  }

  const handleResend = () => {
    onResend && onResend(
      () => {
        handleReset();
        setSeconds(initialSeconds);
        startTimer();
      }
    );
  }

  const isDisabledResend = disabled || seconds > 0;

  return (
    <StyledCodeAuthForm
      {...rest}
      ref={ref}
    >
      <CodeInputField
        autofocus={autofocus}
        className={disabled && 'disabled'}
        disabled={disabled}
        errors={formErrors}
        onSuccess={handleSuccess}
        showResend={false}
        showReset={true}
      />
      <StyledButtonsSpace
        direction='vertical'
        size='large'
      >
        <Button
          className='large-btn'
          disabled={disabled}
          onClick={() => !disabled && handleReset()}
          size='large'
          type='primary'
        >
          {t('enter')} {t('pin')}
        </Button>
        <Button
          className='large-btn'
          disabled={isDisabledResend}
          onClick={() => !isDisabledResend && handleResend()}
          size='large'
          type='default'
        >
          {t('resendCode')} ({dateHelpers.getSecondsTime(seconds)})
        </Button>
        <Button
          disabled={disabled}
          onClick={() => !disabled && onBack && onBack()}
          size='large'
          type='text'
        >
          {t('back')}
        </Button>
      </StyledButtonsSpace>
    </StyledCodeAuthForm>
  );
}

CodeAuthForm.propTypes = {
  autofocus: PropTypes.bool,
  disabled: PropTypes.bool,
  initialSeconds: PropTypes.number,
  onBack: PropTypes.func,
  onResend: PropTypes.func,
  onSuccess: PropTypes.func,
  showResend: PropTypes.bool,
  canStartCount: PropTypes.bool,
  errors: PropTypes.object,
}

CodeAuthForm.defaultProps = {
  autofocus: false,
  canStartCount: true,
  disabled: false,
  initialSeconds: 179,
  showResend: true,
}

export default CodeAuthForm;
