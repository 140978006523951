import styled from 'styled-components';
import {Space} from 'antd';
import Card from '../../components/Card';
import {typographyStyles} from '../../utils/styles';

const StyledSupportPageContainer = styled.div`
  padding: 24px;
`;

const StyledSupportPageHeaderSpace = styled(Space)`
  h3 {
    ${typographyStyles.heading.h3};
    
    color: ${props => props.theme.colors.neutral_10};
    margin: 0;
  }
  
  p {
    ${typographyStyles.heading.h5_1};

    color: ${props => props.theme.colors.neutral_7};
    margin: 0;
  }
`;

const StyledSupportPageFullWidthSpace = styled(Space)`
  width: 100%;
  & > .ant-space-item {
    width: 50%;
  }
`;

const StyledSupportPageLinkSpace = styled(Space)`
  width: 100%;
  & > .ant-space-item {
    width: 100%;
    &:nth-of-type(1) {
      text-align: center;
    }
  }
`;


const StyledSupportPageCard = styled(Card)`
  transition: .2s ease;
  form {
    margin-top: 8px;
    width: 100%;
  }
`;

const StyledSupportPageLinkCard = styled.a`
  
  &:hover {
    ${StyledSupportPageCard} {
      border-color: ${props => props.theme.colors.primary_5};
    }
    
    .link-external-icon {
      svg {
        path {
          fill: ${props => props.theme.colors.primary_5};
        }
      }
    }
  }
  
  .link-title {
    ${typographyStyles.body.medium};
    color: ${props => props.theme.colors.neutral_10};
  }
  
  .link-external-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    svg {
      width: 16px;
      height: 16px;
      path {
        transition: .3s ease;
        fill: ${props => props.theme.colors.primary_6};
      }
    }
  }
  
  .link-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    svg {
      height: 32px;
      width: 32px;
    }
  }
  
  .ant-card-body {
    align-items: center;
    display: flex;
    justify-content: space-between;
    
    & > .ant-space {
      .ant-space-item {
        display: flex;
        align-items: center;
      }
    }
    
    &:after,
    &:before {
      content: none;
    }
  }
`;

const StyledSupportPageFormInformationText = styled.div`
  ${typographyStyles.body.regular};
  color: ${props => props.theme.colors.functional_danger};
  margin-top: 8px;
  &.error {
    color: ${props => props.theme.colors.functional_danger};
  }
  &.success {
    color: ${props => props.theme.colors.functional_success};
  }
`;

export {
  StyledSupportPageCard,
  StyledSupportPageContainer,
  StyledSupportPageHeaderSpace,
  StyledSupportPageFullWidthSpace,
  StyledSupportPageLinkCard,
  StyledSupportPageLinkSpace,
  StyledSupportPageFormInformationText
};
