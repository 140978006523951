import React from 'react';
import PropTypes from 'prop-types';
import {Avatar, Space} from 'antd';
import {StyledVendorDetails, StyledVendorDetailsLink} from './StyledVendorDetails';
import {ExternalLinkIcon} from '../../../../icons';
import {objectHelpers, subscriptionsHelpers, textHelpers} from '../../../../utils/helpers';

const VendorDetails = ({
  category,
  customUrl,
  data,
  onLinkClick,
  ...rest
}) => {
  const reason = objectHelpers.getObjProp(data, 'reason');

  const getServiceProp = (key) => objectHelpers.getObjProp(data?.service, key);

  const serviceUrl = getServiceProp('url');

  const vendorDetails = {
    label: subscriptionsHelpers.getSubscriptionName(data),
    src: getServiceProp('logo') || getServiceProp('logo_url'),
    url: `https://${serviceUrl}`,
  }

  const isEnabledUrl = customUrl || serviceUrl;

  const detailsHref = customUrl ? customUrl : onLinkClick ? '#' : vendorDetails.url;

  const onClick = (e) => {
    onLinkClick && onLinkClick(e);
    e.stopPropagation()
  }

  return (
    <StyledVendorDetails {...rest}>
      <Avatar src={vendorDetails.src}>
        {textHelpers.getInitials(vendorDetails.label)}
      </Avatar>
      <div className='vendor-details'>
        <Space align='center' size={6}>
          <div className='vendor-name'>
            {vendorDetails.label}
          </div>
          {isEnabledUrl && (
            <StyledVendorDetailsLink
              href={detailsHref}
              onClick={onClick}
              rel='noreferrer'
              target={onLinkClick || customUrl ? '' : '_blank'}
            >
              <ExternalLinkIcon />
            </StyledVendorDetailsLink>
          )}
        </Space>
        {category && (
          <div className='vendor-category'>
            {reason || getServiceProp('category')}
          </div>
        )}
      </div>
    </StyledVendorDetails>
  );
}

VendorDetails.propTypes = {
  category: PropTypes.bool,
  data: PropTypes.shape({
    reason: PropTypes.string,
    service: PropTypes.shape({
      logo: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string
    })
  }),
  onLinkClick: PropTypes.func
}

VendorDetails.defaultProps = {
  category: true
}

export default VendorDetails;
