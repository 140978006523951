import styled from 'styled-components';
import {Tag} from 'antd';
import Card from '../../../../Card';
import {typographyStyles} from '../../../../../utils/styles';

const StyledTagsManagement = styled(Card)`
  .ant-card-body {
    & > p {
      color: ${props => props.theme.colors.neutral_8};
    }
  }
`;

const StyledTag = styled(Tag)`
  ${typographyStyles.body.regular};
  line-height: 20px;
  font-family: 'Outfit', sans-serif;
  margin-top: 8px;
  padding: 6px 8px;

  &.disabled {
    cursor: unset;
    opacity: 0.4;
    pointer-events: none;
  }
`;

export {StyledTagsManagement, StyledTag};
