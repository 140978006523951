import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Button} from 'antd';
import {connect} from 'react-redux';
import dayjs from 'dayjs';
import {StyledKYCModal, StyledKYCModalMenu, StyledKYCModalContent, StyledKYCModalFooter} from './StyledKYCModal';
import MenuSteps from '../components/KYCModal/MenuSteps';
import CompanyCreation from '../components/KYCModal/steps/CompanyCreation';
import LegalPerson from '../components/KYCModal/steps/LegalPerson';
import LegalRepresentative from '../components/KYCModal/steps/LegalRepresentative';
import ActivateAccount from '../components/KYCModal/steps/ActivateAccount';
import YourInformation from '../components/KYCModal/steps/YourInformation';
import {bankingActions, mainActions, transactionActions} from '../../../state/actions';
import {KYCModalModeTypes, KYCCompanyStageConstants, bankingUserTypesConstants} from '../../../constants';
import {kycHelpers, objectHelpers} from '../../../utils/helpers';

const {REVIEW} = KYCCompanyStageConstants;
const {COMPANY, KYC} = KYCModalModeTypes;

const activateAccountStepKey = 'activate-account';

const getMenuSteps = ({t, enabledKyc, errorsKeys = [], pendingKeys = []}) => {
  const stepMenuTitle = (key) => t(`menu.${key}.title`);

  const stepMenuDescription = (key, number = '') => t(`menu.${key}.description${number}`);

  let steps = [
    {
      key: 'company',
      title: stepMenuTitle('company'),
      description: enabledKyc && <>{stepMenuDescription('company', 1)}<br/>{stepMenuDescription('company', 2)}</>
    }
  ];
  if (enabledKyc) {
    steps = [
      ...steps,
      {
        key: 'lr',
        title: stepMenuTitle('legalRepresentative'),
        description: stepMenuDescription('legalRepresentative')
      },
      {
        key: 'your-information',
        title: stepMenuTitle('yourInformation')
      },
      {
        key: activateAccountStepKey,
        title: stepMenuTitle('activateAccount')
      },
      // {
      //   key: 'sso-connection',
      //   title: stepMenuTitle('ssoConnection')
      // },
      // {
      //   key: 'subscriptions',
      //   title: stepMenuTitle('subscriptions')
      // },
    ];
  }
  steps = steps.map(step => ({...step, error: errorsKeys.includes(step.key)}));
  steps = steps.map(step => ({...step, pending: pendingKeys.includes(step.key)}));
  return steps;
}

const checkReviewError = (companyState) => {
  let error = false;
  if (companyState && !objectHelpers.isEmptyObject(companyState)) {
    error = companyState.stage === REVIEW && companyState.state === 'review_has_errors';
  }
  return error;
}

const checkReviewPending = (companyState) => {
  let pending = false;
  if (companyState && !objectHelpers.isEmptyObject(companyState)) {
    pending = companyState.stage === REVIEW && companyState.state === 'review_pending';
  }
  return pending;
}


const KYCModal = ({
  dispatch,
  closeKycWindow,
  getCompanyState,
  getCardUsers,
  getUserWalletsMinimal,
  setKYCCompanyState,
  storeLegalPersonDetails,
  storeWireDetails,

  companyState,
  closeIcon,
  employee,
  mode,
  open,

  onCancel,
  ...rest
}) => {
  const [trans] = useTranslation(['main', 'quickStart']);
  const [menuStep, setMenuStep] = useState(1);
  const [step, setStep] = useState(1);
  const [enabledKyc, setEnabledKyc] = useState(false);
  const [prepopulatedEmployeeFormFields, setPrepopulatedEmployeeFormFields] = useState({});

  const kycError = useMemo(() => checkReviewError(companyState), [companyState]);
  const kycPending = useMemo(() => checkReviewPending(companyState), [companyState]);

  const updateCurrentStep = ({steps, value, updateMenuStep = false}) => {
    const currentStep = steps[value] || 1;
    const currentEnabledKyc = currentStep > 1;
    let currentMenuStep = 1;

    if (currentStep !== step) setStep(currentStep);
    if (currentEnabledKyc !== enabledKyc) setEnabledKyc(currentEnabledKyc);

    if (updateMenuStep && currentStep > 1) currentMenuStep = currentStep - 1;
    if (currentMenuStep !== menuStep) setMenuStep(currentMenuStep);
  }

  useEffect(() => {
    let steps;
    if (mode !== COMPANY && companyState && !objectHelpers.isEmptyObject(companyState)) {
      const {stage} = companyState;
      steps = {
        [KYCCompanyStageConstants.LEGAL_PERSON]: 2,
        [KYCCompanyStageConstants.LEGAL_REPRESENTATIVE]: 3,
        [KYCCompanyStageConstants.EMPLOYEE]: 4,
        [KYCCompanyStageConstants.ACCOUNT]: 5,
        [KYCCompanyStageConstants.REVIEW]: 5
      };
      updateCurrentStep({steps, value: stage, updateMenuStep: true});
      if (stage === KYCCompanyStageConstants.ACCOUNT) {
        getCardUsers(
          {user_type: bankingUserTypesConstants.LEGAL_PERSON},
          (users) => {
            const legalPersonUser = users[0];
            users.length > 0 && storeLegalPersonDetails(users[0]);
            getUserWalletsMinimal({
              id: legalPersonUser.id,
              successCallback: (wallets) => {
                if (wallets.length > 0) {
                  const wallet = wallets[0];
                  storeWireDetails(wallet, true);
                } else {
                  storeWireDetails(undefined);
                }
              },
            })
          }
        );
      }
    }
  }, [companyState, mode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (mode !== KYC) {
      const steps = {
        [COMPANY]: 1,
        [KYC]: 2
      };
      updateCurrentStep({steps, value: mode});
    }
  }, [mode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (open && mode === COMPANY) {
      enabledKyc && setEnabledKyc(false);
      step !== 1 && setStep(1);
      menuStep !== 1 && setMenuStep(1);
    }
  }, [mode, open]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const defaultEmployeeFormValues = kycHelpers.getEmployeeDefaultFormValues(employee);
    setPrepopulatedEmployeeFormFields(defaultEmployeeFormValues);
  }, [employee]); // eslint-disable-line react-hooks/exhaustive-deps

  const t = (key) => trans(`quickStart:kyc.${key}`);

  const handleCancel = (...rest) => {
    closeKycWindow();
    onCancel && onCancel(...rest);
  }

  const increaseStep = () => setStep(step + 1);

  const increaseMenuStep = () => setMenuStep(menuStep + 1);

  const menuSteps = useMemo(() => {
    const errorsKeys = [];
    const pendingKeys = [];
    if (kycError) errorsKeys.push(activateAccountStepKey);
    if (kycPending) pendingKeys.push(activateAccountStepKey);
    return getMenuSteps({t, enabledKyc, errorsKeys, pendingKeys});
  }, [enabledKyc, kycError, kycPending]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCompanyCreationFinish = ({enabledKyc}) => {
    setEnabledKyc(enabledKyc);
    enabledKyc ? increaseStep() : handleCancel();
  }

  const finishStep = (trigger) => {
    increaseStep();
    increaseMenuStep();
    setKYCCompanyState(trigger);
  }

  const updatePrepopulatedEmployeeFormFields = (user) => {
    const fields = ['birthday', 'birth_country', 'firstname', 'email', 'lastname', 'nationality', 'phone',
      'place_of_birth', 'tax_residence', 'tax_number', 'title'];
    let values = {};
    fields.forEach(fieldName => {
      let fieldValue = user[fieldName];
      if (fieldName === 'birthday') fieldValue = dayjs(fieldValue);
      values = {...values, [fieldName]: fieldValue};
    });
    setPrepopulatedEmployeeFormFields(values);
  }

  const handleLegalPersonalFinish = () => finishStep('set_lp_completed');

  const handleLegalRepresentativeFinish = (user) => {
    finishStep('set_employee_null');
    if (user) updatePrepopulatedEmployeeFormFields(user);
  }

  const handleYourInformationFinish = () => {
    increaseStep();
    increaseMenuStep();
  }

  const getStepContent = () => {
    const screens = {
      1: (
        <CompanyCreation
          onFinish={handleCompanyCreationFinish}
        />
      ),
      2: (
        <LegalPerson
          onFinish={handleLegalPersonalFinish}
        />
      ),
      3: (
        <LegalRepresentative
          onFinish={handleLegalRepresentativeFinish}
        />
      ),
      4: (
        <YourInformation
          prepopulatedFormFields={prepopulatedEmployeeFormFields}
          onFinish={handleYourInformationFinish}
        />
      ),
      5: (
        <ActivateAccount
          warning={kycPending && t('errors.reviewPending')}
          error={kycError && t('errors.reviewError')}
          onFinish={handleCancel}
        />
      )
    }
    return screens[step] || null;
  }

  const stepContent = getStepContent();

  return (
    <StyledKYCModal
      closeIcon={closeIcon}
      onCancel={handleCancel}
      footer={false}
      open={open}
      {...rest}
    >
      <StyledKYCModalMenu>
        <StyledKYCModalContent>
          <MenuSteps
            steps={menuSteps}
            activeNum={menuStep}
          />
        </StyledKYCModalContent>
        <StyledKYCModalFooter>
          <Button
            onClick={handleCancel}
            size='large'
          >
            {t('continueLater')}
          </Button>
        </StyledKYCModalFooter>
      </StyledKYCModalMenu>
      {stepContent}
    </StyledKYCModal>
  );
}

KYCModal.propTypes = {
  closeIcon: PropTypes.bool,
  onCancel: PropTypes.func,
  open: PropTypes.bool
}

KYCModal.defaultProps = {
  closeIcon: true,
  open: false
}

const mapStateToProps = state => {
  const {closeIcon, mode, open} = state.main.kycWindowProps;
  const {companyState} = state.banking;
  const {employee} = state.user;

  return {
    closeIcon,
    companyState,
    employee,
    mode,
    open,
  }
}

const mapDispatchToProps = {
  closeKycWindow: mainActions.closeKycWindow,
  getCompanyState: bankingActions.getCompanyState,
  getCardUsers: bankingActions.getCardsUsers,
  getUserWalletsMinimal: bankingActions.getUserWalletsMinimal,
  setKYCCompanyState: bankingActions.setKYCCompanyState,
  storeLegalPersonDetails: bankingActions.storeLegalPersonDetails,
  storeWireDetails: transactionActions.storeWireDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(KYCModal);

