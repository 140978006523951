import { useState, useEffect } from 'react';
import {useSelector} from 'react-redux';
import {objectHelpers} from '../utils/helpers';

export default function useIsEmployeeLoaded () {
  const currentUser = useSelector(state => state.user.employee);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!objectHelpers.isEmptyObject(currentUser)) {
      setIsLoaded(true);
    } else {
      setIsLoaded(false);
    }
  }, [currentUser]);

  return isLoaded;
}

