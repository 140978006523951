import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {StyledEmailVerificationPage} from './StyledEmailVerificationPage';
import {authActions} from '../../state/actions';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import {systemHelpers} from '../../utils/helpers';

const EmailVerificationPage = ({setEmailVerificationToken, isAuth, checkedAuth}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {token} = useParams();
  const [isInvitation, setIsInvitation] = useState(false);
  const { height, width } = useWindowDimensions();
  const [trans] = useTranslation('settings');
  const t = (key, options) => trans(`pages.emailVerification.${key}`, options);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    let {search} = location;
    search = new URLSearchParams(search);
    if (token) {
      let isInvitation = search.get('reason') === 'invitation';
      setIsInvitation(isInvitation);
    }
  }, [token, location]);

  const appLink = systemHelpers.getMobileAppPath(`email/verification/${token}`);

  useEffect(() => {
    if (checkedAuth && token) {
      if (width < 767) {
        setShowContent(true);
        window.location.replace(appLink);
      } else {
        const searchParams = new URLSearchParams(location.search);
        const verificationToken = {
          ...Object.fromEntries(searchParams),
          token
        }
        setEmailVerificationToken(verificationToken, isInvitation);
        navigate('/');
      }
    }
  }, [isAuth, checkedAuth, setEmailVerificationToken, token, isInvitation, width, height, location]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledEmailVerificationPage>
      {showContent && (
        <>
          <h3>{t('pageTitle')}</h3>
          <a href={appLink}>{t('openApp')}</a>
        </>
      )}
    </StyledEmailVerificationPage>
  );
}

const mapStateToProps = state => {
  const {verifyErrors} = state.auth;
  const {isAuth, checkedAuth} = state.user;

  return {
    codeAuthErrors: verifyErrors,
    isAuth,
    checkedAuth
  }
}

const mapDispatchToProps = {
  setEmailVerificationToken: authActions.setEmailVerificationToken
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerificationPage);
