import {request} from '../services/request';
import {subscriptionConstants} from '../constants';
import {subscriptionActionConstants, subscriptionViewTypesConstants} from '../../constants';
import {backendEndpoints} from '../../api';
import {apiHelpers, objectHelpers} from '../../utils/helpers';

const {getUrl} = apiHelpers;
const errorHandlerProps = {disableAlert: true};
const hideKeylessErrorHandlerProps = {hideKeylessError: true};

const getSubscriptionActionUrl = (action) => {
  return {
    [subscriptionActionConstants.ACTIVATE_DELETED]: backendEndpoints.POST_SUBSCRIPTION_ACTIVATE_DELETED,
    [subscriptionActionConstants.ACTIVATE_PAUSED]: backendEndpoints.POST_SUBSCRIPTION_ACTIVATE_PAUSED,
    [subscriptionActionConstants.AUTHORIZE]: backendEndpoints.POST_SUBSCRIPTION_SUBMIT,
    [subscriptionActionConstants.CARD_RETRY]: backendEndpoints.POST_SUBSCRIPTION_CARD_RETRY,
    [subscriptionActionConstants.DELETE]: backendEndpoints.POST_SUBSCRIPTION_DELETE,
    [subscriptionActionConstants.DELETE]: backendEndpoints.POST_SUBSCRIPTION_DELETE,
    [subscriptionActionConstants.IGNORE]: backendEndpoints.POST_SUBSCRIPTION_IGNORE,
    [subscriptionActionConstants.PAUSE]: backendEndpoints.POST_SUBSCRIPTION_PAUSE,
    [subscriptionActionConstants.RESTRICT]: backendEndpoints.POST_SUBSCRIPTION_RESTRICT,
  }[action] || undefined;
}

const getSubscriptionsList = (query = null, success, error) => {
  return (dispatch, getState) => {
    const {subscriptions: prevSubscriptions} = getState().subscription;
    const nextPage = objectHelpers.getObjProp(query, 'nextPage', false);
    request.get({
      dispatch,
      url: backendEndpoints.GET_SUBSCRIPTIONS,
      query,
      successCallback: (data) => {
        const {subscriptions: curSubscriptions} = data;
        const subscriptions = nextPage ? [...prevSubscriptions.subscriptions, ...curSubscriptions] : curSubscriptions;
        dispatch({
          type: subscriptionConstants.GET_SUBSCRIPTION_LIST,
          data: {
            subscriptions,
            pagination: data.pagination
          }
        });
        success && success(data);
      },
      errorCallback: (data) => error && error(data),
      errorHandlerProps
    });
  }
}

const getDiscoveredSubscriptions = (query = null, successCallback, errorCallback) => {
  return (dispatch) => {
    query = {
      ...query,
      view: subscriptionViewTypesConstants.NEED_REVIEW
    }
    request.get({
      dispatch,
      url: backendEndpoints.GET_SUBSCRIPTIONS,
      query,
      successCallback,
      errorCallback,
      errorHandlerProps
    });
  }
}

const getSubscriptionsViewsStatuses = ({successCallback, errorCallback} = {}) => {
  return (dispatch) => {
    request.get({
      dispatch,
      url: backendEndpoints.GET_SUBSCRIPTIONS_VIEWS_STATUSES,
      successCallback: (data) => {
        dispatch({
          type: subscriptionConstants.GET_SUBSCRIPTION_VIEWS_STATUSES,
          data: data.views_statuses || null
        });
        successCallback && successCallback(data);
      },
      errorCallback,
      errorHandlerProps
    });
  }
}

const getSubscriptionsTotal = (success, error) => {
  return (dispatch) => {
    request.get({
      dispatch,
      url: backendEndpoints.GET_SUBSCRIPTIONS_TOTAL,
      successCallback: (data) => success && success(data),
      errorCallback: (data) => error && error(data),
      errorHandlerProps
    });
  }
}

const getSubscription = (id, success, error) => {
  return (dispatch) => {
    request.get({
      dispatch,
      url: getUrl(backendEndpoints.GET_SUBSCRIPTION, {id}),
      successCallback: (data) => {
        dispatch({
          type: subscriptionConstants.GET_SUBSCRIPTION_DETAILS,
          data
        });
        success && success(data);
      },
      errorCallback: (data) => error && error(data),
      errorHandlerProps
    });
  }
}

const getSubscriptionEmails = (id, success, error) => {
  return (dispatch) => {
    request.get({
      dispatch,
      url: getUrl(backendEndpoints.GET_SUBSCRIPTION_EMAILS, {id}),
      successCallback: (data) => success && success(data),
      errorCallback: (data) => error && error(data),
      errorHandlerProps
    });
  }
}

const getSubscriptionAttachment = (id, success, error) => {
  return (dispatch) => {
    request.get({
      dispatch,
      url: getUrl(backendEndpoints.GET_SUBSCRIPTION_ATTACHMENT, {id}),
      successCallback: (data) => success && success(data),
      errorCallback: (err) => error && error(err),
      errorHandlerProps
    });
  }
}

const getSubscriptionLogs = (id, query, success, error) => {
  return (dispatch) => {
    request.get({
      dispatch,
      url: getUrl(backendEndpoints.GET_SUBSCRIPTION_LOGS, {id}),
      query,
      successCallback: (data) => success && success(data),
      errorCallback: (err) => error && error(err),
      errorHandlerProps
    });
  }
}

const createSubscription = ({headers, data, successCallback, errorCallback}) => {
  return (dispatch) => {
    request.post({
      dispatch,
      headers,
      url: backendEndpoints.POST_SUBSCRIPTION_CREATE,
      data,
      successCallback,
      errorCallback,
      errorHandlerProps
    });
  }
}

const bulkCreateSubscriptions = ({data, headers, successCallback, errorCallback}) => {
  return (dispatch) => {
    request.post({
      dispatch,
      headers,
      url: backendEndpoints.POST_SUBSCRIPTION_BULK_CREATE,
      data,
      successCallback,
      errorCallback,
      errorHandlerProps: hideKeylessErrorHandlerProps
    });
  }
}

const updateSubscription = ({headers, id, data, successCallback, errorCallback}) => {
  return (dispatch) => {
    request.put({
      dispatch,
      headers,
      url: getUrl(backendEndpoints.PUT_SUBSCRIPTION_UPDATE, {id}),
      data,
      successCallback: (data) => {
        dispatch({
          type: subscriptionConstants.UPDATE_SUBSCRIPTION,
          data
        });
        successCallback && successCallback(data);
      },
      errorCallback,
      errorHandlerProps: hideKeylessErrorHandlerProps
    });
  }
}

const deleteSubscription = ({headers, id, data, successCallback, errorCallback}) => {
  return (dispatch) => {
    request.delete({
      dispatch,
      data,
      headers,
      url: getUrl(backendEndpoints.DELETE_SUBSCRIPTION, {id}),
      successCallback,
      errorCallback,
      errorHandlerProps: hideKeylessErrorHandlerProps
    });
  }
}

const subscriptionQuickAction = ({action, id, data, headers, successCallback, errorCallback}) => {
  return (dispatch) => {
    const urlMask = getSubscriptionActionUrl(action);
    if (urlMask === undefined) return ;
    request.post({
      dispatch,
      data,
      headers,
      url: getUrl(urlMask, {id}),
      successCallback,
      errorCallback,
      errorHandlerProps: hideKeylessErrorHandlerProps
    });
  }
}

const clearSubscription = () => {
  return (dispatch) => {
    dispatch({
      type: subscriptionConstants.CLEAR_SUBSCRIPTION,
    });
  }
}

const setCardHold = ({hold, message}) => {
  return (dispatch) => {
    dispatch({
      type: subscriptionConstants.SET_SUBSCRIPTION_CARD_HOLD,
      data: {hold, message}
    });
  }
}

const storeSubscriptions = (data) => {
  return (dispatch) => {
    dispatch({
      type: subscriptionConstants.STORE_SUBSCRIPTIONS,
      data
    });
  }
}

export const subscriptionActions = {
  getSubscriptionsList,
  getSubscriptionsTotal,
  getSubscription,
  getSubscriptionAttachment,
  getSubscriptionEmails,
  getSubscriptionLogs,
  getSubscriptionsViewsStatuses,
  createSubscription,
  updateSubscription,
  deleteSubscription,
  bulkCreateSubscriptions,
  subscriptionQuickAction,
  setCardHold,
  getDiscoveredSubscriptions,

  clearSubscription,
  storeSubscriptions
};
