import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Select, Space} from 'antd';
import {StyledCountriesSelect} from './StyledCountriesSelect';
import {localizationHelpers} from '../../../utils/helpers';

const AntdCountriesSelect = ({
  countryPropName,
  onChange,
  options,
  value,
  ...rest
}) => {
  const [elementValue, setElementValue] = useState(undefined);
  const selectOptions = useMemo(() => localizationHelpers.getCountryOptions(countryPropName), [countryPropName]);

  useEffect(() => setElementValue(value), [value]);

  const handleOnChange = (value) => onChange && onChange(value);

  return (
    <StyledCountriesSelect
      {...rest}
      onChange={handleOnChange}
      filterOption={(input, option) =>
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      value={elementValue}
    >
      {(options || selectOptions).map(({image, label, value}, key) => (
        <Select.Option
          key={key}
          label={label}
          value={value}
        >
          <Space>
            {image}
            {label}
          </Space>
        </Select.Option>
      ))}
    </StyledCountriesSelect>
  );
}

AntdCountriesSelect.propTypes = {
  countryPropName: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

AntdCountriesSelect.defaultProps = {
  countryPropName: 'ISO3166-1-Alpha-2'
}

export default AntdCountriesSelect;
