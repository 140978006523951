export const tabHelpers = {
  handleOnPaginate: ({view, activePage, tableData, setTableData, loadDataFunc}) => {
    const viewTableData = tableData[view];
    const {page: currentPage, loadedPages} = viewTableData;
    if (activePage > currentPage && loadedPages < activePage) {
      let query = {};
      if (viewTableData.pagination && viewTableData.pagination.cursor) query = {cursor: viewTableData.pagination.cursor};
      loadDataFunc({view, query});
    } else {
      setTableData({
        ...tableData,
        [view]: {
          ...viewTableData,
          page: activePage
        }
      });
    }
  },
  handleOnFilter: ({view, filterQuery, loadDataFunc, queries = undefined, setQueries = undefined}) => {
    loadDataFunc({
      initial: true,
      view,
      query: filterQuery
    });
    if (queries && setQueries) {
      const viewQueries = queries[view];
      setQueries({
        ...queries,
        [view]: {
          ...viewQueries,
          ...filterQuery
        }
      });
    }
  },
  handleOnTableChange: ({view, sorter, extra, loadDataFunc}) => {
    const {action} = extra;
    if (action === 'sort') {
      const {order, field} = sorter;
      loadDataFunc({
        initial: true,
        view,
        query: {
          order_by: order ? `${order === 'descend' ? '-' : ''}${field}` : undefined
        }
      })
    }
  },
  getLabel: ({key, total, t}) => {
    let value = 0;
    if (total && total.hasOwnProperty(key)) value = total[key];
    return `${t(`viewOptions.${key}`)} (${value})`;
  },
  getUpdatedTableData: ({tableData, response, initial, rowsKey = 'results'}) => {
    const pagination = response?.pagination || null;
    const data = response[rowsKey] || [];
    let {loadedPages, page} = tableData;
    page = initial ? 1 : page + 1;

    return {
      ...tableData,
      loaded: true,
      loadedPages: initial ? page : page > loadedPages ? page : loadedPages,
      pagination,
      page,
      rows: initial ? data : [...tableData.rows, ...data]
    }
  },
  handleStoreTableData: ({tableData, setTableData, view, response, initial, rowsKey = 'results'}) => {
    const viewTableData = tableData[view];
    let updatedViewTableDate = tabHelpers.getUpdatedTableData({
      tableData: viewTableData,
      response,
      initial,
      rowsKey
    })
    let updatedTableData = {
      ...tableData,
      [view]: updatedViewTableDate
    }

    setTableData(updatedTableData);
    return updatedTableData;
  },
  handleStoreStoredTableData: ({tableData, setTableData, view, response, rowsKey, perPagePagination}) => {
    const pagination = response?.pagination || null;
    const data = response[rowsKey] || [];
    const viewTableData = tableData[view];
    let {page} = viewTableData;
    let updatedTableData;

    updatedTableData = {
      ...tableData,
      [view]: {
        loaded: true,
        loadedPages: page,
        pagination,
        page,
        rows: page === 1 ? data : [...tableData[view].rows.slice(0, (page - 1) * perPagePagination), ...data]
      }
    }

    setTableData(updatedTableData);
    return updatedTableData;
  },
  getViewQuery: ({initial, query, queries, view}) => {
    let viewQuery = {...(queries[view] || {}), ...query};
    if (initial) viewQuery = {...viewQuery, cursor: undefined};
    return viewQuery;
  },
  getTableOrder: (view, queries) => {
    let tableOrder;
    const query = queries && queries.hasOwnProperty(view) ? queries[view] : undefined;
    let orderBy = query?.order_by || undefined;
    if (orderBy) {
      const desc = orderBy.charAt(0) === '-';
      if (desc) orderBy = orderBy.replace(/^./, '');
      tableOrder = {
        field: orderBy,
        order: desc ? 'descend' : 'ascend'
      }
    }
    return tableOrder;
  },
  getTablePagination: ({handleOnPaginate, tableData, total, totalKey, view, perPagePagination = 25} = {}) => {
    const getTableDataValue = (view, key, defaultValue) => tableData.hasOwnProperty(view) ? tableData[view][key] : defaultValue;
    const data = getTableDataValue(view, 'rows', []);
    const paginationCurrentPage = getTableDataValue(view, 'page', 1);
    const enabledPaginationCursor = Boolean(getTableDataValue(view, 'pagination', null)?.cursor);
    let paginationTotal = total && total.hasOwnProperty(totalKey) ? total[totalKey] : undefined;
    if (!enabledPaginationCursor) paginationTotal = data.length;
    return {
      current: paginationCurrentPage,
      disabled: data.length === 0,
      onChange: (page) => handleOnPaginate(view, page),
      pageSize: perPagePagination,
      showSizeChanger: false,
      total: paginationTotal
    }
  }
}
