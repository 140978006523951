import {useEffect, useState} from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import {backendWebsocketActionsConstants} from '../../constants';
import {systemHelpers} from '../../utils/helpers';

const {OPEN} = ReadyState;

const {AUTH} = backendWebsocketActionsConstants;

const socketUrl = systemHelpers.getWebsocketUrl('backend');

const defaultOptions = systemHelpers.getWebsocketOptions();

const WebSocket = ({onMessage, options}) => {
  const {
    getWebSocket,
    lastJsonMessage,
    readyState,
    sendJsonMessage
  } = useWebSocket(socketUrl, {...defaultOptions, ...options});
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    if (readyState === OPEN) {
      const {companyId, token} = systemHelpers.getAuthDetails();
      sendJsonMessage({
        'action': AUTH,
        'Authorization': `Bearer ${token}`,
        'CompanyId': companyId
      });
    }
  }, [readyState]); // eslint-disable-line react-hooks/exhaustive-deps

  // close websocket after unmount
  useEffect(() => () => {
    getWebSocket().close()
  },[]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // mark that user is authorized
    if (!authorized && lastJsonMessage && typeof lastJsonMessage === 'object') {
      const isAuthAction = lastJsonMessage.action;
      if (isAuthAction) setAuthorized(lastJsonMessage.state);
    }
    if (authorized && onMessage) onMessage(lastJsonMessage)
  }, [lastJsonMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
}

export default WebSocket;
