import styled from 'styled-components';
import {Segmented, Space} from 'antd';
import {bordersStyles} from '../../../../utils/styles';

const StyledSpending = styled(Space)`
  ${bordersStyles.default};
  
  background-color: ${props => props.theme.colors.neutral_1};
  padding: 24px;
  position: relative;
  width: 100%;
  
  & > .ant-space-item {
    width: 100%;
  }
`;

const StyledSpendingHeader = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const StyledSpendingSegmented = styled(Segmented)`
  background-color: ${props => props.theme.colors.neutral_1};
  border: 1px solid ${props => props.theme.colors.neutral_5};
  min-width: 154px;
  padding: 8px;
  
  .ant-segmented-thumb {
    background-color: ${props => props.theme.colors.neutral_4};
  }
  
  .ant-segmented-item {
    color: ${props => props.theme.colors.neutral_8};
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    transition: .2s ease-in-out;
    
    &:not(:last-of-type) {
      margin-right: 16px;
    }
    
    &.ant-segmented-item-selected {
      font-weight: 600;
      &.ant-segmented-item-disabled {
        background-color: ${props => props.theme.colors.neutral_4};
        color: ${props => props.theme.colors.neutral_10};
      }
      &:not(.ant-segmented-item-disabled) {
        background-color: ${props => props.theme.colors.primary_7};
        color: ${props => props.theme.colors.neutral_1};
      }
    }
    
    &:not(.ant-segmented-item-selected):not(.ant-segmented-item-disabled) {
      &:hover {
        background-color: ${props => props.theme.colors.primary_7};
        color: ${props => props.theme.colors.neutral_1};
      }
    }
    
    .ant-segmented-item-label {
      line-height: 24px;
      min-height: 24px;
      min-width: 60px;
      padding: 0 8px;
    }
  }
`;


export {
  StyledSpending,
  StyledSpendingHeader,
  StyledSpendingSegmented
}