import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {
  StyledClaimModal,
  StyledClaimModalButton,
  StyledClaimModalSpace
} from './StyledClaimModal';
import inviteImg from '../../../../../static/images/pages/settings/claim-invited.png';
import {amountHelpers} from '../../../../../utils/helpers';

const ClaimModal = ({
  onOk,
  open,
  value,
  variant,
  ...rest
}) => {
  const [trans] = useTranslation('settings');

  const t = (key, options) => trans(`modals.claimModal.${key}`, options);

  const claimValue = amountHelpers.getAmountWithCurrencyCode(value, undefined, undefined, true, false);

  const textContent = variant === 'success' ? (
    <>
      <h1>{t('success.title')}</h1>
      <p>
        {t('success.description1')} <b>{claimValue}</b> {t('success.description2')} 🌟<br/>
        {t('success.description3')} 🚀
      </p>
    </>
  ) : (
    <>
      <h1>{t('error.title')}</h1>
      <p>
        {t('error.description')}
      </p>
    </>
  );

  return (
    <StyledClaimModal
      centered={true}
      closeIcon={false}
      header={null}
      footer={null}
      open={open}
      width={408}
      {...rest}
    >
      <StyledClaimModalSpace
        direction='vertical'
        size='large'
      >
        <img src={inviteImg} alt='congrats' />
        <StyledClaimModalSpace
          direction='vertical'
          size='middle'
        >
          {textContent}
          <StyledClaimModalButton
            onClick={onOk}
            type='primary'
            size='large'
          >
            {t(`${variant}.button`)}
          </StyledClaimModalButton>
        </StyledClaimModalSpace>
      </StyledClaimModalSpace>
    </StyledClaimModal>
  );
}

ClaimModal.propTypes = {
  variant: PropTypes.oneOf(['error', 'success']),
  value: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  onOk: PropTypes.func
}

ClaimModal.defaultProps = {
  variant: 'success'
}

export default ClaimModal;
