import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import useWebSocket, {ReadyState} from 'react-use-websocket';
import {useTranslation} from 'react-i18next';
import {UnmountClosed} from 'react-collapse';
import {Button, Result} from 'antd';
import {
  StyledKeylessAuthModal,
  StyledKeylessAuthModalSpace
} from './StyledKeylessAuthModal';
import {StyledKeylessEnrollModalResultContainer} from '../KeylessEnrollModal/StyledKeylessEnrollModal';
import {bankingWebsocketActionsConstants, errorCodesConstants} from '../../../../constants';
import {ReactComponent as ErrorSvgImage} from '../../../../static/images/error.svg';
import {ReactComponent as ConfirmationSvgImage} from '../../../../static/images/keyless-auth/phone-confirmation.svg';
import {elementHelpers, objectHelpers, scaHelpers, systemHelpers} from '../../../../utils/helpers';

const {getVisibleClassName} = elementHelpers;


const {
  AUTH,
  KEYLESS_AUTHENTICATE,
  KEYLESS_AUTHENTICATE_SUCCESS,
  KEYLESS_AUTHENTICATE_FAILED
} = bankingWebsocketActionsConstants;

const socketUrl = systemHelpers.getWebsocketUrl('authentication');

const defaultSocketOptions = systemHelpers.getWebsocketOptions();

let interval = null;
const initialSeconds = 8;

const KeylessAuthModal = ({
  open,
  handleCancel,
  onButtonClick,
  onSuccess,
  operationName,
  title,
  ...rest
}) => {
  const [authorized, setAuthorized] = useState(false);
  const [trans] = useTranslation('cards');
  const [error, setError] = useState({exist: false, code: undefined});
  const [seconds, setSeconds] = useState(initialSeconds);

  const {
    getWebSocket,
    lastJsonMessage,
    readyState,
    sendJsonMessage
  } = useWebSocket(socketUrl, defaultSocketOptions, open);
  const t = key => trans(`modal.keylessConfirmationRequest.${key}`);

  // close websocket after unmount
  useEffect(() => () => {
    const websocket = getWebSocket();
    websocket && websocket.close();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (open && objectHelpers.isNaV(socketUrl)) setError({exist: true, code: errorCodesConstants.WEBSOCKET});
  }, [open]);

  useEffect(() => {
    // mark that user is authorized
    if (lastJsonMessage && typeof lastJsonMessage === 'object') {
      const isAuthAction = lastJsonMessage.action === AUTH;
      if (isAuthAction) {
        const authorized = typeof lastJsonMessage.message !== 'object'
        setAuthorized(authorized);
        if (authorized) {
          const {companyId, token} = systemHelpers.getAuthDetails();
          sendJsonMessage({
            'action': KEYLESS_AUTHENTICATE,
            'access_token': token,
            'company_id': companyId,
            'message': {
              'sca_operation_type': scaHelpers.getOperationType(operationName)
            }
          });
        }
      }
    }
    if (authorized) {
      if (lastJsonMessage && typeof lastJsonMessage === 'object') {
        let action = lastJsonMessage.action;
        let message = lastJsonMessage.message;
        const actions = {
          [KEYLESS_AUTHENTICATE_SUCCESS]: () => handleFinish('success', message),
          [KEYLESS_AUTHENTICATE_FAILED]: () => {
            setError({exist: true, code: undefined});
            handleFinish('error', message);
          }
        }
        if (action === undefined && message === 'Internal server error') {
          setError({exist: true, code: authorized ? errorCodesConstants.SERVER : errorCodesConstants.WEBSOCKET});
        } else if (actions.hasOwnProperty(action)) {
          actions[action]();
        }
      }
    }
  }, [lastJsonMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      const {companyId, token} = systemHelpers.getAuthDetails();
      sendJsonMessage({
        'action': AUTH,
        'access_token': token,
        'company_id': companyId,
      });
    }
  }, [readyState]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (seconds > 0 && open && authorized) {
      startTimer();
      return () => interval && clearInterval(interval);
    }
  }, [seconds, authorized, open]); // eslint-disable-line react-hooks/exhaustive-deps

  const startTimer = () => {
    interval = setInterval(() => {
      seconds > 0 ? setSeconds(seconds - 1) : clearInterval(interval);
    }, 1000);
  };

  const handleFinish = (status, message) => onSuccess && onSuccess(status, message);

  const afterClose = () => {
    setError({exist: false, code: undefined});
    seconds === 0 && setSeconds(initialSeconds);
  }

  const errorSubTitle = (
    <>
      {t(`errorResult.description`)}
      {error.code && (
        <>
          <br/>Error code: {error.code}.
        </>
      )}
    </>
  );

  return (
    <StyledKeylessAuthModal
      afterClose={afterClose}
      footer={null}
      onCancel={handleCancel}
      open={open}
      title={title || t('modalTitle')}
      zIndex={1001}
      width={448}
      {...rest}
    >
      <StyledKeylessAuthModalSpace
        className={getVisibleClassName('', !error.exist)}
        size='large'
        direction='vertical'
      >
        <ConfirmationSvgImage />
        <StyledKeylessAuthModalSpace
          size='middle'
          direction='vertical'
        >
          <StyledKeylessAuthModalSpace
            size='small'
            direction='vertical'
          >
            <h3>{t('title')}</h3>
            <p>
              {
                authorized
                  ? t(`description.${seconds === 0 ? '8secondsAfterSending' : 'afterSending'}`)
                  : t('description.beforeSending')
              }
            </p>
          </StyledKeylessAuthModalSpace>
          <UnmountClosed isOpened={seconds === 0 && authorized}>
            <Button
              onClick={onButtonClick}
              size='large'
              type='default'
            >
              {t('buttonText')}
            </Button>
          </UnmountClosed>
        </StyledKeylessAuthModalSpace>
      </StyledKeylessAuthModalSpace>
      <StyledKeylessEnrollModalResultContainer className={getVisibleClassName('result-container', error.exist)}>
        <Result
          icon={<ErrorSvgImage />}
          status='error'
          subTitle={errorSubTitle}
          title={t(`errorResult.title`)}
        />
      </StyledKeylessEnrollModalResultContainer>
    </StyledKeylessAuthModal>
  );
}

KeylessAuthModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func,
  onSuccess: PropTypes.func,
  operationName: PropTypes.string,
  title: PropTypes.string
}

export default KeylessAuthModal;
