import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import moment from 'moment';
import Card from '../../../../Card';
import ComponentLoading from '../../../../ComponentLoading';
import BarChart from '../../../../Charts/BarChart';
import PieChart from '../../../../Charts/PieChart';
import UsersTable from '../../tabComponents/UsageReport/UsersTable';
import {StyledUsageReportTab, StyledCharts, StyledEmptyState} from './StyledUsageReportTab';
import {serviceStatsActions} from '../../../../../state/actions';
import routes from '../../../../../routes/routes.json';
import emptyStateImage from '../../../../../static/images/pages/subscriptions/empty-usage-report.png';
import {dateHelpers, objectHelpers} from '../../../../../utils/helpers';

const gObjProp = objectHelpers.getObjProp;

const UsageReportTab = ({
  isActive,
  isAdmin,
  getServiceUsers,
  getServiceUsageReport,
  employees,
  service
}) => {
  const [t] = useTranslation(['subscriptions', 'main']);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [usageReport, setUsageReport] = useState({isLoaded: false, report: null});

  const trans = (key) => t(`main:${key}`);

  const finishUsageReportLoading = (report) => {
    setUsageReport({isLoaded: true, report});
    setLoading(false);
  }

  const {averageLoginsChartData, isEnabledLoginsChartData} = useMemo(() => {
    const logins = usageReport?.report?.monthly_logins || [];
    const averageLoginsChartData = logins
      .map((l) => {
        const [year, month] = l.month.split('.');
        const date = moment().set({month: parseInt(month) - 1, year});
        return ({date: dateHelpers.getDate(date, 'MMM'), value: l.login_count})
      })
      .reverse();
    const isEnabledLoginsChartData = averageLoginsChartData.map(i => i.value).some(i => i > 0)
    return {
      averageLoginsChartData,
      isEnabledLoginsChartData
    }
  }, [usageReport]);

  useEffect(() => {
    if (isActive && !usageReport.isLoaded && service) {
      getUsageReport();
      getUsers();
    } else {
      finishUsageReportLoading(null);
    }
  }, [isActive]); // eslint-disable-line react-hooks/exhaustive-deps

  const getUsageReport = () => {
    getServiceUsageReport(
      service,
      (report) => finishUsageReportLoading(report),
      () => finishUsageReportLoading(null)
    );
  }

  const getUsers = (query = {}) => {
    getServiceUsers(
      service,
      query,
      (users) => {
        users = users.map(user => {
          const {email} = user;
          const employee = employees.find(e => e.email === email);
          return {
            email,
            full_name: employee?.full_name || undefined,
            last_login: user.last_login,
            logo: employee?.logo || undefined,
            usage: user.usage
          }
        })
        setUsers(users);
      }
    );
  }

  const handleChangeUsersOrder = (sortBy, order) => getUsers({sort_by: sortBy, sort_order: order});

  const {report} = usageReport;

  const getBarChartUsers = () => {
    const users = report ? gObjProp(report, 'users', null) : null;
    return users ? [
      {name: trans('active'), value: users.active, color: '#52C41A'},
      {name: trans('inactive'), value: users.inactive, color: '#DBDBDB'},
    ] : null;
  }

  const tEmptyState = (key) => t(`usageReportEmptyState.${key}`);

  const barChartUsersData = getBarChartUsers();

  const totalAverageLoginsCount = gObjProp(report, 'average_monthly_logins', 0);

  return (
    <StyledUsageReportTab>
      {loading ? (
        <ComponentLoading />
      ) : (
        <>
          {barChartUsersData === null ? (
            <StyledEmptyState>
              <img alt='empty' src={emptyStateImage} />
              <h3>{tEmptyState('title')}</h3>
              {isAdmin && (
                <p>
                  {tEmptyState('description1')}
                  <br/>
                  {tEmptyState('description2')} <Link
                  to={routes.settings}>{tEmptyState('linkTitle')}</Link> {tEmptyState('description3')}
                </p>
              )}
            </StyledEmptyState>
          ) : (
            <>
              <StyledCharts>
                <Card
                  extra={<span className='fw-500 f-16'>Av. {totalAverageLoginsCount}</span>}
                  title={t('totUsageMonth')}
                >
                  {isEnabledLoginsChartData && (
                    <BarChart
                      data={averageLoginsChartData}
                    />
                  )}
                </Card>

                <Card title={trans('users')}>
                  <PieChart
                    cornerRadius={8}
                    data={barChartUsersData}
                    chartHeight={230}
                    isEnableLegend={true}
                    isEnableTotal={true}
                    totalTitle={`${trans('total')} ${trans('users')}`}
                  />
                </Card>
              </StyledCharts>

              <UsersTable
                data={users}
                onOrderChange={(name, order) => users.length > 0 && handleChangeUsersOrder(name, order)}
              />
            </>
          )}

        </>
      )}
    </StyledUsageReportTab>
  );
}

UsageReportTab.propTypes = {
  isActive: PropTypes.bool,
  service: PropTypes.string.isRequired
}

UsageReportTab.defaultProps = {
  isActive: false
}

const mapStateToProps = state => {
  const {employees} = state.company;
  const {isAdmin} = state.user;
  return {
    employees,
    isAdmin
  }
}

const mapDispatchToProps = {
  getServiceUsers: serviceStatsActions.getServiceUsers,
  getServiceUsageReport: serviceStatsActions.getServiceUsageReport
}


export default connect(mapStateToProps, mapDispatchToProps)(UsageReportTab);
