import React from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {Input, Form, Button} from 'antd';
import {StyledCreatePinForm} from './StyledCreatePinForm';
import {formHelpers} from '../../../../utils/helpers';

const {Item} = Form;

const defaultFormValues = {
  password: '',
}

const CreatePinForm = ({
  buttonText,
  disabled,
  loading,
  onSubmit,
  requiredPasswordLength
}) => {
  const [form] = Form.useForm();
  const [t] = useTranslation(['main', 'auth']);

  const tValid = (key, props) => t(`auth:validation.${key}`, props);

  const handleSubmitForm = () => form.submit();

  const handleKeyUp = (e) => e.key.toLowerCase() === 'enter' && handleSubmitForm();

  const onPasswordKeyPress = (e) => !/[0-9]/.test(e.key) && e.preventDefault();

  const incorrectPassLengthMessage = tValid('incorrectPinFormat', {count: requiredPasswordLength});

  const handleOnSubmit = (fields) => {
    let formFields = [];
    const {password} = fields;
    if (password.length < requiredPasswordLength) formFields.push({name: 'password', errors: [incorrectPassLengthMessage]});
    form.setFields(formFields);

    if (formFields.length) return;

    onSubmit && onSubmit(
      fields,
      null,
      (err) => {
        if (err.hasOwnProperty('message')) {
          const fields = [
            {name: 'password', errors: [err.message]},
          ];
          form.setFields(fields);
        } else if (err.hasOwnProperty('errors')) {
          const fields = formHelpers.getFormServerErrorFields(err);
          form.setFields(fields);
        }
      }
    );
  }

  const inputProps = {
    disabled,
    maxLength: 6,
    onKeyPress: onPasswordKeyPress,
    size: 'large'
  }

  const pinLabel = t('pin');

  return (
    <StyledCreatePinForm
      initialValues={defaultFormValues}
      form={form}
      layout='vertical'
      onKeyUp={handleKeyUp}
      onFinish={handleOnSubmit}
    >
      <Item
        name='password'
        rules={[
          {required: true, message: incorrectPassLengthMessage}
        ]}
        required
      >
        <Input.Password
          {...inputProps}
          placeholder={pinLabel}
        />
      </Item>
      <Item>
        <Button
          disabled={loading ? false : disabled}
          loading={loading}
          onClick={handleSubmitForm}
          size='large'
          type='primary'
        >
          {buttonText}
        </Button>
      </Item>
    </StyledCreatePinForm>
  );
}

CreatePinForm.propTypes = {
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  requiredPasswordLength: PropTypes.number
};

CreatePinForm.defaultProps = {
  disabled: false,
  loading: false,
  requiredPasswordLength: 6
}

export default CreatePinForm;
